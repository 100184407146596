import { Col, Form, Input, Row, Select, Switch } from 'antd';
import React, { useState } from 'react';
import { MinusCircleOutlined } from '@ant-design/icons';
import { formItemLayout } from '../../components/forms/form-layouts.component';
import FormImageSelectorComponent from '../../components/forms/form-image-selector.component';
import { connect } from 'react-redux';
import 'react-quill/dist/quill.snow.css';
import ReactQuill, { Quill } from 'react-quill';
import { linkType } from '../../helpers/quill';
import FormImageSelectorNoInputComponent from '../../components/forms/form-image-selector-no-input.component';
import { LoopbackFile } from '../../types';

const { Option } = Select;

Quill.register(linkType);

interface Props {
  index: number;
  field: any;
  formRef: any;
  language: any;
  fields: any[];
  remove?: any;
  rootField: any;
}

const ColumnLayoutColumnModule = (props: Props) => {
  const [showImageModal, setShowImageModal] = useState(false);
  const [fileType, setFileType] = useState('page');
  const [activeSection, setActiveSection] = useState(0);
  const [fileList, setFileList] = useState<LoopbackFile[]>([]);

  const quill: any = React.createRef<ReactQuill>();

  const createToolbar = (ind: number) => {
    return {
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
          ['link', 'image', 'video'],
          ['clean']
        ],
        handlers: {
          image: () => {
            setShowImageModal(true);
            setActiveSection(ind);
          }
        }
      },
      clipboard: {
        matchVisual: false
      }
    };
  };

  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 22, offset: 0 },
      sm: { span: 16, offset: 8 }
    }
  };

  const subFormItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 19 }
    }
  };

  const doAddImage = (filePath: string, index: number) => {

    if (quill.current) {
      const _quill = quill.current.getEditor();
      const range = _quill.getSelection(true);
      _quill.insertEmbed(range.index, 'image', filePath);
    }
  };

  return (
    <Form.Item
      {...(props.index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
      label={props.index === 0 ? 'Columns' : ''}
      required={false}
      key={props.field.key}
    >
      <Row className={'form-sub-section'}>
        <Col xs={22}>
          <Row>
            <Col xs={24}>
              <Form.Item
                {...subFormItemLayout}
                label={'Width'}
                name={[props.field.name, 'column', 'width']}
                rules={[{ required: true, message: 'select a width' }]}
              >
                <Select placeholder={'Select width'}>
                  <Option value={16}>16.7%</Option>
                  <Option value={20}>20%</Option>
                  <Option value={25}>25%</Option>
                  <Option value={33}>33%</Option>
                  <Option value={50}>50%</Option>
                  <Option value={66}>66%</Option>
                  <Option value={75}>75%</Option>
                  <Option value={100}>100%</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                {...subFormItemLayout}
                label={'Background color'}
                name={[props.field.name, 'column', 'background', 'color']}
                rules={[{ required: false }]}
              >
                <Input placeholder='Hex color' />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                {...subFormItemLayout}
                label={'Background image'}
                name={[props.field.name, 'column', 'background', 'image']}
                validateTrigger={['onChange', 'onBlur']}
                rules={[{ required: false }]}
              >
                <FormImageSelectorComponent
                  showAddon={true}
                  preloadedFileList={fileList}
                  callback={(fileList: LoopbackFile[]) => setFileList(fileList)}
                  formRef={props.formRef}
                  rootKey={'sections'}
                  subKeys={[
                    props.rootField.name,
                    'settings',
                    'columns',
                    props.field.name,
                    'column',
                    'background'
                  ]}
                  formKey={'image'}
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                {...subFormItemLayout}
                label={'Content'}
                name={[props.field.name, 'column', 'content', props.language]}
                rules={[{ required: false }]}
              >
                <ReactQuill
                  ref={quill}
                  theme='snow'
                  modules={createToolbar(props.index)}
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                {...subFormItemLayout}
                valuePropName='checked'
                label={'Open links in new tab?'}
                name={[props.field.name, 'column', 'open_links_new_tab']}
                rules={[{ required: false }]}
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                {...subFormItemLayout}
                label='Button text'
                name={[props.field.name, 'column', 'button', 'text', props.language]}
                rules={[{ required: false }]}
              >
                <Input placeholder='My button' />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                {...subFormItemLayout}
                label={'Button type'}
                name={[props.field.name, 'column', 'button', 'type']}
                rules={[{ required: false }]}
              >
                <Select placeholder={'select value'} onChange={(e) => setFileType(e)}>
                  <Option value='file'>File</Option>
                  <Option value='page'>Page</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                {...subFormItemLayout}
                label='Button url'
                name={[props.field.name, 'column', 'button', 'url']}
                rules={[{ required: false }]}
              >
                {fileType === 'file' ? (
                  <Input />
                ) : (
                  <Input addonBefore={process.env.REACT_APP_WEB_URL} />
                )}
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                {...subFormItemLayout}
                valuePropName='checked'
                label={'Position last on mobile?'}
                name={[props.field.name, 'column', 'switch_order_on_mobile']}
                rules={[{ required: false }]}
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col xs={2}>
          {props.fields.length > 1 ? (
            <MinusCircleOutlined
              className='dynamic-delete-button'
              style={{ margin: '0 8px' }}
              onClick={() => {
                props.remove(props.field.name);
              }}
            />
          ) : null}
        </Col>
      </Row>

      <FormImageSelectorNoInputComponent
        showModal={showImageModal}
        hideModal={() => setShowImageModal(false)}
        onImageSelected={(file: LoopbackFile) => {
          const url = file.apiPath;
          doAddImage(process.env.REACT_APP_API_URL + url, activeSection);
          setShowImageModal(false);
        }}
      />
    </Form.Item>
  );
};

export default connect()(ColumnLayoutColumnModule);
