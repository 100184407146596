import { Form, Input } from 'antd';
import React from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { linkType } from '../../helpers/quill';

Quill.register(linkType);

export const ParagraphModule = (field: {
  name: string;
  formRef: any;
  language: string;
  isSaving: boolean;
}) => {
  let toolbar: any = {
    toolbar: [
      [{ header: [1, 2, 3, 4, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false
    }
  };

  return (
    <>
      <Form.Item
        label='Title'
        name={[field.name, 'settings', 'title', field.language]}
        rules={[{ required: false }]}
      >
        <Input placeholder='Title of this block' />
      </Form.Item>
      <Form.Item
        label='Sub-title'
        name={[field.name, 'settings', 'subtitle', field.language]}
        rules={[{ required: false }]}
      >
        <Input placeholder='Sub-Title of this block' />
      </Form.Item>
      <Form.Item
        label='Content'
        name={[field.name, 'settings', 'description', field.language]}
        rules={[{ required: true, message: 'Please enter the description for this block' }]}
      >
        <ReactQuill modules={toolbar} theme='snow' />
      </Form.Item>
      <Form.Item
        label='Background color'
        name={[field.name, 'settings', 'background_color']}
        rules={[{ required: false }]}
      >
        <Input placeholder='Hex color i.e. #EEE4E1' />
      </Form.Item>
      <Form.Item
        label='Text color'
        name={[field.name, 'settings', 'text_color']}
        rules={[{ required: false }]}
      >
        <Input placeholder='Hex color i.e. #EEE4E1' />
      </Form.Item>
      <Form.Item
        label='Button text'
        name={[field.name, 'settings', 'button', 'text', field.language]}
        rules={[{ required: false }]}
      >
        <Input placeholder='Button text' />
      </Form.Item>
      <Form.Item
        label='Button url'
        name={[field.name, 'settings', 'button', 'url']}
        rules={[{ required: false }]}
      >
        <Input addonBefore={process.env.REACT_APP_WEB_URL} />
      </Form.Item>
    </>
  );
};
