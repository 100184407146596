import React, { useEffect } from 'react';
import { Form, Input, Select } from 'antd';
import FormHeaderComponent from '../components/forms/form-header.component';
import { formItemLayout } from '../components/forms/form-layouts.component';
import { Admin } from '../types';
import { AuthState } from '../redux/states/user';
import { connect } from 'react-redux';
import { setFormData } from '../helpers/form.helper';
import { FormInstance } from 'antd/es/form';
import { settings } from '../../settings';

const { Option } = Select;

interface Props {
  auth: AuthState;
  type: string;
  visible: boolean;
  isSaving: boolean;
  object?: Admin | null;
  formRef: React.RefObject<FormInstance>;
}

const AdminForm = (props: Props) => {
  useEffect(() => {
    setFormData(props, props.object, props.formRef);
  }, [props.object]);

  const admin = props.object;

  return (
    <>
      <FormHeaderComponent
        type={props.type}
        newTitle={admin ? '' : 'Add a new Admin'}
        editTitle={admin ? `Edit ${admin.first_name}'s details` : ''}
      />

      <Form
        {...formItemLayout}
        ref={props.formRef}
        initialValues={{ roles: ['admin'] }}
        name='basic'
      >
        <Form.Item
          label='First name'
          name='first_name'
          rules={[{ required: true, message: "Please enter the admin's first name" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Last name'
          name='last_name'
          rules={[{ required: true, message: "Please enter the admin's last name" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Email'
          name='email'
          extra={
            props.object === null &&
            'Once you click save, the admin will receive an email containing a password at this email address. The admin can change his/her password after logging in.'
          }
          rules={[{ required: true, message: "Please enter the admin's email" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name='roles'
          label='Roles'
          rules={[
            { required: true, message: 'Please select the roles for this admin', type: 'array' }
          ]}
        >
          <Select mode='multiple' placeholder='Select roles'>
            <Option disabled value='admin'>
              Basic Admin (required)
            </Option>
            <Option value='dashboard_analytics'>Dashboard Analytics</Option>
            <Option value='admins'>Admins</Option>
            {settings.blog.enable && <Option value='blog_articles'>Blog Articles</Option>}
            <Option value='faqs'>FAQs</Option>
            {settings.products.colors && <Option value='colors'>Colors</Option>}
            <Option value='sizes'>Sizes</Option>
            <Option value='customers'>Customers</Option>
            <Option value='product_types'>Product Type</Option>
            {settings.ingredients.enable && <Option value='medicines'>Medicines</Option>}
            {settings.ingredients.enable && <Option value='ingredients'>Ingredients</Option>}
            {settings.productThemes.enable && (
              <Option value='product_themes'>Product Themes</Option>
            )}
            <Option value='product_categories'>Product Categories</Option>
            {settings.events.enable && <Option value='events'>Events</Option>}
            {settings.courses.enable && <Option value='courses'>Courses</Option>}
            {settings.eventEnquiries && <Option value='event_enquiries'>Event Enquiries</Option>}
            {settings.enableGiftCartForVouchers && <Option value='gift_cards'>Gift Cards</Option>}
            {settings.products.styles && <Option value='product_styles'>Product Styles</Option>}
            {settings.products.occasions && (
              <Option value='product_occasions'>Product Occasions</Option>
            )}
            {settings.lookbook.enable && <Option value='lookbooks'>Lookbooks</Option>}
            {settings.embroidery && <Option value='software'>Software</Option>}
            <Option value='pages'>Pages</Option>
            <Option value='countries'>Countries</Option>
            <Option value='vouchers'>Vouchers</Option>
            <Option value='products'>Products</Option>
            <Option value='translations'>Translations</Option>
            <Option value='currencies'>Currencies</Option>
            {settings.embroidery && (
              <Option value='embroidery_positions'>Embroidery Positions</Option>
            )}
            <Option value='orders'>Orders</Option>
            {settings.qrCodes && <Option value='qr_codes'>QR-Codes</Option>}
            {settings.purchases && <Option value='purchases'>Purchases</Option>}
            <Option value='settings'>Settings</Option>
          </Select>
        </Form.Item>
      </Form>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(AdminForm);
