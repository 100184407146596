import React, { Component } from 'react';
import { Button, Col, DatePicker, Form, Input, Row, Select, Space, Switch, Tabs } from 'antd';
import FormHeaderComponent from '../components/forms/form-header.component';
import { formItemLayout } from '../components/forms/form-layouts.component';
import { LoopbackFile, Setting } from '../types';
import { AuthState } from '../redux/states/user';
import { connect } from 'react-redux';
import { setFormData, updateFormData } from '../helpers/form.helper';
import { FormInstance } from 'antd/es/form';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DeleteOutlined,
  PlusOutlined
} from '@ant-design/icons';
import FormImageSelectorComponent from '../components/forms/form-image-selector.component';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { settings } from '../../settings';
import moment from 'moment';

const { Option } = Select;
const { TabPane } = Tabs;

interface Props {
  auth: AuthState;
  type: string;
  visible: boolean;
  isSaving: boolean;
  object?: Setting | null;
  formRef: React.RefObject<FormInstance>;
}

interface State {
  confirmLoading: boolean;
  type: string | undefined;
  fileList: LoopbackFile[]
}

class SettingsForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      confirmLoading: false,
      type: this.props.object?.type,
      fileList: []
    };
  }

  componentDidMount(): void {
    if (this.props.object && this.props.object.coming_soon_date) {
      this.props.object.coming_soon_date = moment(this.props.object.coming_soon_date);
    }
    setFormData(this.props, this.props.object, this.props.formRef);
  }

  componentWillUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any) {
    if (nextProps.object) {
      if (nextProps.object.coming_soon_date) {
        nextProps.object.coming_soon_date = moment(nextProps.object.coming_soon_date);
      }
      if (this.state.type !== nextProps.object.type) {
        this.setState({ type: nextProps.object.type });
      }
    }
    updateFormData(this, nextProps);
  }

  getWebsiteForm = (language: string) => {
    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16, offset: 8 }
      }
    };
    const subformItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19, offset: 5 }
      }
    };

    const subFormItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 }
      }
    };

    return (
      <>
        <Form.Item
          name='type'
          label='Type'
          rules={[{ required: true, message: 'Please select the type of settings' }]}
        >
          <Select
            disabled={this.props.object !== null}
            placeholder='Select settings type'
            onChange={(e) => e && this.setState({ type: e.toString() })}
          >
            <Option value='website'>Website</Option>
            <Option value='mail'>Mail</Option>
          </Select>
        </Form.Item>
        <Form.Item
          extra={
            'If enabled this will show only the coming soon page. All other pages will be disabled'
          }
          label='Coming soon?'
          name={'coming_soon'}
          valuePropName='checked'
          rules={[{ required: false, type: 'boolean' }]}
        >
          <Switch />
        </Form.Item>
        <Form.Item label='Coming soon date' name='coming_soon_date' rules={[{ required: false }]}>
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>

        <h3>Navbar</h3>
        <hr />
        <Form.Item
          name={['navbar_settings', 'type']}
          label='Type'
          rules={[{ required: true, message: 'Please select the type of navbar' }]}
        >
          <Select placeholder='Select type'>
            <Option value='default'>Default</Option>
            <Option value='transparent'>Transparent</Option>
          </Select>
        </Form.Item>
        <Form.List name={['navbar_settings', 'bottom_bar', 'links']}>
          {(fields, { add, remove, move }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                    label={index === 0 ? 'Links' : ''}
                    required={false}
                    key={field.key}
                  >
                    <Row className={'form-sub-section'}>
                      <Col xs={22}>
                        <Row>
                          <Col xs={24}>
                            <Space>
                              <Button
                                disabled={index === fields.length - 1}
                                onClick={() => move(index, index + 1)}
                                icon={<ArrowDownOutlined />}
                              />
                              <Button
                                disabled={index === 0}
                                onClick={() => move(index, index - 1)}
                                icon={<ArrowUpOutlined />}
                              />
                            </Space>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={24}>
                            <Form.Item
                              {...subFormItemLayout}
                              label={'Name'}
                              name={[field.name, 'name', language]}
                              rules={[{ required: true, message: 'Please enter a name' }]}
                            >
                              <Input placeholder='enter a title' />
                            </Form.Item>
                          </Col>
                          <Col xs={24}>
                            <Form.Item
                              {...subFormItemLayout}
                              label={'Url'}
                              name={[field.name, 'url']}
                              rules={[
                                { required: true, message: 'Please enter a url for the link' }
                              ]}
                            >
                              <Input
                                addonBefore={process.env.REACT_APP_WEB_URL}
                                placeholder={'/contact'}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24}>
                            <Form.Item
                              extra={
                                'If enabled this will add a solid background color to the link. Use only once.'
                              }
                              {...subFormItemLayout}
                              label='Highlight?'
                              name={[field.name, 'highlight']}
                              valuePropName='checked'
                              rules={[{ required: false, type: 'boolean' }]}
                            >
                              <Switch />
                            </Form.Item>
                          </Col>
                          <Col xs={24}>
                            <Form.List name={[field.name, 'links']}>
                              {(_fields, { add, remove }) => {
                                return (
                                  <div>
                                    {_fields.map((_field, index) => (
                                      <Form.Item
                                        {...(index === 0
                                          ? subFormItemLayout
                                          : subformItemLayoutWithOutLabel)}
                                        label={index === 0 ? 'Sublinks' : ''}
                                        required={false}
                                        key={_field.key}
                                      >
                                        <Row className={'form-sub-sub-section'}>
                                          <Col xs={22}>
                                            <Row>
                                              <Col xs={24}>
                                                <Form.Item
                                                  {...subFormItemLayout}
                                                  label={'Name'}
                                                  name={[_field.name, 'name', language]}
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: 'Please enter a name'
                                                    }
                                                  ]}
                                                >
                                                  <Input placeholder='enter a title' />
                                                </Form.Item>
                                              </Col>
                                              <Col xs={24}>
                                                <Form.Item
                                                  {...subFormItemLayout}
                                                  label={'Url'}
                                                  name={[_field.name, 'url']}
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: 'Please enter a url for the link'
                                                    }
                                                  ]}
                                                >
                                                  <Input
                                                    addonBefore={process.env.REACT_APP_WEB_URL}
                                                    placeholder={'/women'}
                                                  />
                                                </Form.Item>
                                              </Col>
                                            </Row>
                                          </Col>
                                          <Col xs={2}>
                                            <DeleteOutlined
                                              className='dynamic-delete-button'
                                              style={{ margin: '0 8px' }}
                                              onClick={() => {
                                                remove(_field.name);
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                      </Form.Item>
                                    ))}
                                    <Row>
                                      <Col xs={24} sm={{ span: 16, offset: 8 }}>
                                        <Form.Item>
                                          <Button
                                            block={window.innerWidth < 768}
                                            type='primary'
                                            onClick={() => {
                                              add();
                                            }}
                                            style={
                                              window.innerWidth < 768
                                                ? { width: '100%' }
                                                : { width: '60%' }
                                            }
                                          >
                                            <PlusOutlined /> Add Sub-Link
                                          </Button>
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                  </div>
                                );
                              }}
                            </Form.List>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={2}>
                        <DeleteOutlined
                          className='dynamic-delete-button'
                          style={{ margin: '0 8px' }}
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                ))}
                <Row>
                  <Col xs={24} sm={{ span: 16, offset: 8 }}>
                    <Form.Item>
                      <Button
                        block={window.innerWidth < 768}
                        type='primary'
                        onClick={() => {
                          add();
                        }}
                        style={window.innerWidth < 768 ? { width: '100%' } : { width: '60%' }}
                      >
                        <PlusOutlined /> Add Link
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            );
          }}
        </Form.List>
        {settings.products.enable && (
          <>
            <h3>Webshop navigation bar</h3>
            <hr />
            <Form.List name={['navbar_settings', 'category_bar', 'links']}>
              {(fields, { add, remove, move }) => {
                return (
                  <div>
                    {fields.map((field, index) => (
                      <Form.Item
                        {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                        label={index === 0 ? 'Product categories' : ''}
                        required={false}
                        key={field.key}
                      >
                        <Row className={'form-sub-section'}>
                          <Col xs={22}>
                            <Row>
                              <Col xs={24}>
                                <Space>
                                  <Button
                                    disabled={index === fields.length - 1}
                                    onClick={() => move(index, index + 1)}
                                    icon={<ArrowDownOutlined />}
                                  />
                                  <Button
                                    disabled={index === 0}
                                    onClick={() => move(index, index - 1)}
                                    icon={<ArrowUpOutlined />}
                                  />
                                </Space>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={24}>
                                <Form.Item
                                  {...subFormItemLayout}
                                  label={'Name'}
                                  name={[field.name, 'name', language]}
                                  rules={[{ required: true, message: 'Please enter a name' }]}
                                >
                                  <Input placeholder='enter a title' />
                                </Form.Item>
                              </Col>
                              <Col xs={24}>
                                <Form.Item
                                  {...subFormItemLayout}
                                  label={'Url'}
                                  name={[field.name, 'url']}
                                  rules={[
                                    { required: true, message: 'Please enter a url for the link' }
                                  ]}
                                >
                                  <Input
                                    addonBefore={process.env.REACT_APP_WEB_URL}
                                    placeholder={'/women'}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={2}>
                            <DeleteOutlined
                              className='dynamic-delete-button'
                              style={{ margin: '0 8px' }}
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                          </Col>
                        </Row>
                      </Form.Item>
                    ))}
                    <Row>
                      <Col xs={24} sm={{ span: 16, offset: 8 }}>
                        <Form.Item>
                          <Button
                            block={window.innerWidth < 768}
                            type='primary'
                            onClick={() => {
                              add();
                            }}
                            style={window.innerWidth < 768 ? { width: '100%' } : { width: '60%' }}
                          >
                            <PlusOutlined /> Add Link
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                );
              }}
            </Form.List>
          </>
        )}
        <h3>Footer</h3>
        <hr />
        {settings.footer.type !== 'basic' && (
          <>
            <Form.List name={['footer_settings', 'relevant_links', 'links']}>
              {(fields, { add, remove, move }) => {
                return (
                  <div>
                    {fields.map((field, index) => (
                      <Form.Item
                        {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                        label={index === 0 ? 'Relevant Links' : ''}
                        required={false}
                        key={field.key}
                      >
                        <Row className={'form-sub-section'}>
                          <Col xs={22}>
                            <Row>
                              <Col xs={24}>
                                <Space>
                                  <Button
                                    disabled={index === fields.length - 1}
                                    onClick={() => move(index, index + 1)}
                                    icon={<ArrowDownOutlined />}
                                  />
                                  <Button
                                    disabled={index === 0}
                                    onClick={() => move(index, index - 1)}
                                    icon={<ArrowUpOutlined />}
                                  />
                                </Space>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={24}>
                                <Form.Item
                                  {...subFormItemLayout}
                                  label={'Name'}
                                  name={[field.name, 'name', language]}
                                  rules={[{ required: true, message: 'Please enter a name' }]}
                                >
                                  <Input placeholder='enter a title' />
                                </Form.Item>
                              </Col>
                              <Col xs={24}>
                                <Form.Item
                                  {...subFormItemLayout}
                                  label={'Url'}
                                  name={[field.name, 'url']}
                                  rules={[
                                    { required: true, message: 'Please enter the url of the link' }
                                  ]}
                                >
                                  <Input
                                    addonBefore={process.env.REACT_APP_WEB_URL}
                                    placeholder={'/contact'}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={2}>
                            <DeleteOutlined
                              className='dynamic-delete-button'
                              style={{ margin: '0 8px' }}
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                          </Col>
                        </Row>
                      </Form.Item>
                    ))}
                    <Row>
                      <Col xs={24} sm={{ span: 16, offset: 8 }}>
                        <Form.Item>
                          <Button
                            block={window.innerWidth < 768}
                            type='primary'
                            onClick={() => {
                              add();
                            }}
                            style={window.innerWidth < 768 ? { width: '100%' } : { width: '60%' }}
                          >
                            <PlusOutlined /> Add Link
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                );
              }}
            </Form.List>
          </>
        )}
        {settings.footer.customerServiceLinks && (
          <Form.List name={['footer_settings', 'customer_service_links', 'links']}>
            {(fields, { add, remove, move }) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <Form.Item
                      {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                      label={index === 0 ? 'Customer Service Links' : ''}
                      required={false}
                      key={field.key}
                    >
                      <Row className={'form-sub-section'}>
                        <Col xs={22}>
                          <Row>
                            <Row>
                              <Col xs={24}>
                                <Space>
                                  <Button
                                    disabled={index === fields.length - 1}
                                    onClick={() => move(index, index + 1)}
                                    icon={<ArrowDownOutlined />}
                                  />
                                  <Button
                                    disabled={index === 0}
                                    onClick={() => move(index, index - 1)}
                                    icon={<ArrowUpOutlined />}
                                  />
                                </Space>
                              </Col>
                            </Row>
                            <Col xs={24}>
                              <Form.Item
                                {...subFormItemLayout}
                                label={'Name'}
                                name={[field.name, 'name', language]}
                                rules={[{ required: true, message: 'Please enter a name' }]}
                              >
                                <Input placeholder='enter a title' />
                              </Form.Item>
                            </Col>
                            <Col xs={24}>
                              <Form.Item
                                {...subFormItemLayout}
                                label={'Url'}
                                name={[field.name, 'url']}
                                rules={[
                                  { required: true, message: 'Please enter the url of the link' }
                                ]}
                              >
                                <Input
                                  addonBefore={process.env.REACT_APP_WEB_URL}
                                  placeholder={'/contact'}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={2}>
                          <DeleteOutlined
                            className='dynamic-delete-button'
                            style={{ margin: '0 8px' }}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        </Col>
                      </Row>
                    </Form.Item>
                  ))}
                  <Row>
                    <Col xs={24} sm={{ span: 16, offset: 8 }}>
                      <Form.Item>
                        <Button
                          block={window.innerWidth < 768}
                          type='primary'
                          onClick={() => {
                            add();
                          }}
                          style={window.innerWidth < 768 ? { width: '100%' } : { width: '60%' }}
                        >
                          <PlusOutlined /> Add Link
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              );
            }}
          </Form.List>
        )}

        <h3>Social Media</h3>
        <hr />
        <Form.Item
          label='Instagram link'
          name={['footer_settings', 'social_media_links', 'instagram']}
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Twitter link'
          name={['footer_settings', 'social_media_links', 'twitter']}
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Facebook link'
          name={['footer_settings', 'social_media_links', 'facebook']}
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Youtube link'
          name={['footer_settings', 'social_media_links', 'youtube']}
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Pinterest link'
          name={['footer_settings', 'social_media_links', 'pinterest']}
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>
          <Form.Item
              label='Linked In'
              name={['footer_settings', 'social_media_links', 'linked_in']}
              rules={[{ required: false }]}
          >
              <Input />
          </Form.Item>
        <Form.Item
          label='WhatsApp message'
          extra={
            'When a customer clicks on the email share button, this message will be inserted in the text message'
          }
          name={['footer_settings', 'social_media_links', 'whatsapp']}
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Email message'
          extra={
            'When a customer clicks on the email share button, this message will be inserted in the email'
          }
          name={['footer_settings', 'social_media_links', 'email']}
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>

        <h3>Contact information</h3>
        <hr />
        <Form.Item
          label='Webshop mail address'
          name='email'
          rules={[{ required: true, message: 'Please input the email of the webshop' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label='Phone number' name='phone' rules={[{ required: false }]}>
          <Input />
        </Form.Item>

        <Form.List name='addresses'>
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map((field, index) => (
                  <Row className={'form-section'} key={field.key}>
                    <DeleteOutlined
                      className={'dynamic-delete-button'}
                      onClick={() => {
                        remove(field.name);
                      }}
                    />
                    <Col xs={{ span: 24 }}>
                      <Form.Item
                        label={'Title'}
                        name={[field.name, 'title']}
                        rules={[{ required: true }]}
                      >
                        <Input placeholder={'Administrative address'} />
                      </Form.Item>
                      <Form.Item
                        label={'Address'}
                        name={[field.name, 'address']}
                        rules={[
                          {
                            required: false
                          }
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label={'Postal code'}
                        name={[field.name, 'postal_code']}
                        rules={[{ required: false }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label={'City'}
                        name={[field.name, 'city']}
                        rules={[{ required: false }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label={'Country'}
                        name={[field.name, 'country']}
                        rules={[{ required: false }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label={'Phone'}
                        name={[field.name, 'phone']}
                        rules={[{ required: false }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label={'Email'}
                        name={[field.name, 'email']}
                        rules={[{ required: true, message: 'Please fill an email address' }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label={'Business registration label'}
                        name={[field.name, 'business_registration', 'label']}
                        rules={[{ required: false }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label={'Business registration number value'}
                        name={[field.name, 'business_registration', 'value']}
                        rules={[{ required: false }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label={'Tax label'}
                        name={[field.name, 'tax_details', 'label']}
                        rules={[{ required: false }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label={'Tax number value'}
                        name={[field.name, 'tax_details', 'value']}
                        rules={[{ required: false }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                ))}

                <Form.Item wrapperCol={{ xs: { span: 24 }, sm: { span: 16, offset: 8 } }}>
                  <Button
                    type='dashed'
                    onClick={() => {
                      add();
                    }}
                    style={{ width: '100%' }}
                  >
                    <PlusOutlined /> Add address
                  </Button>
                </Form.Item>
              </>
            );
          }}
        </Form.List>

        <h3>Thank you page</h3>
        <hr />

        <Form.List name={['thank_you', 'next_steps']}>
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map((field, index) => (
                  <Row className={'form-section'} key={field.key}>
                    <DeleteOutlined
                      className={'dynamic-delete-button'}
                      onClick={() => {
                        remove(field.name);
                      }}
                    />
                    <Col xs={{ span: 24 }}>
                      <Form.Item
                        label={'Title'}
                        name={[field.name, 'title', language]}
                        rules={[{ required: true }]}
                      >
                        <Input placeholder={'Enter a title'} />
                      </Form.Item>
                      <Form.Item
                        label={'Description'}
                        name={[field.name, 'description', language]}
                        rules={[{ required: true }]}
                      >
                        <ReactQuill theme='snow' />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        label={'Image'}
                        name={[field.name, 'image']}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'Please select an image'
                          }
                        ]}
                      >
                        <FormImageSelectorComponent
                          showAddon={true}
                          preloadedFileList={this.state.fileList}
                          callback={(fileList: LoopbackFile[]) => this.setState({fileList})}
                          formRef={this.props.formRef}
                          rootKey={'thank_you'}
                          subKeys={['next_steps', field.name]}
                          formKey={'image'}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                ))}

                <Form.Item wrapperCol={{ xs: { span: 24 }, sm: { span: 16, offset: 8 } }}>
                  <Button
                    type='dashed'
                    onClick={() => {
                      add();
                    }}
                    style={{ width: '100%' }}
                  >
                    <PlusOutlined /> Add step
                  </Button>
                </Form.Item>
              </>
            );
          }}
        </Form.List>
        <Form.Item
          label='Follow Us Image'
          name={['thank_you', 'follow_us', 'image']}
          rules={[{ required: false }]}
        >
          <FormImageSelectorComponent
            showAddon={true}
            preloadedFileList={this.state.fileList}
            callback={(fileList: LoopbackFile[]) => this.setState({fileList})}
            formRef={this.props.formRef}
            rootKey={'thank_you'}
            subKeys={['follow_us']}
            formKey={'image'}
          />
        </Form.Item>
        <Form.Item
          label='Follow us Title'
          name={['thank_you', 'follow_us', 'title', language]}
          rules={[{ required: true, message: 'Please enter a title' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Follow us Description'
          name={['thank_you', 'follow_us', 'description', language]}
          rules={[{ required: true, message: 'Please enter a description' }]}
        >
          <ReactQuill theme='snow' />
        </Form.Item>
      </>
    );
  };

  getMailForm = (language: string) => {
    return (
      <>
        <Form.Item
          name='type'
          label='Type'
          rules={[{ required: true, message: 'Please select the type of settings' }]}
        >
          <Select
            disabled={this.props.object !== null}
            placeholder='Select settings type'
            onChange={(e) => e && this.setState({ type: e.toString() })}
          >
            <Option value='website'>Website</Option>
            <Option value='mail'>Mail</Option>
          </Select>
        </Form.Item>

          {settings.settings.mail.templates.includes('order_confirmation') &&
              <>
              <h3>Order Confirmation (User)</h3>
              <hr />

              <Form.Item
              label='Subject'
              name={['mail_settings', 'order_confirmation_user', 'subject', language]}
              rules={[{required: true, message: 'Please enter a subject'}]}
              >
              <Input />
              </Form.Item>
              <Form.Item
              label='Preview Text'
              name={['mail_settings', 'order_confirmation_user', 'preview', language]}
              rules={[{required: true, message: 'Please enter a preview text'}]}
              >
              <Input.TextArea />
              </Form.Item>

              <h3>Order Confirmation - (Admin)</h3>
              <hr />

              <Form.Item
              label='Subject'
              name={['mail_settings', 'order_confirmation_admin', 'subject', language]}
              rules={[{required: true, message: 'Please enter a subject'}]}
              >
              <Input />
              </Form.Item>
              <Form.Item
              label='Preview Text'
              name={['mail_settings', 'order_confirmation_admin', 'preview', language]}
              rules={[{required: true, message: 'Please enter a preview text'}]}
              >
              <Input.TextArea />
              </Form.Item>
              </>
          }

          {settings.settings.mail.templates.includes('order_reschedule') &&
              <>
                  <h3>Reschedule Booking - Verification Code</h3>
                  <hr/>

                  <Form.Item
                      label='Subject'
                      name={['mail_settings', 'verification_code', 'subject', language]}
                      rules={[{required: true, message: 'Please enter a subject'}]}
                  >
                      <Input/>
                  </Form.Item>
                  <Form.Item
                      label='Preview Text'
                      name={['mail_settings', 'verification_code', 'preview', language]}
                      rules={[{required: true, message: 'Please enter a preview text'}]}
                  >
                      <Input.TextArea/>
                  </Form.Item>
              </>
          }

          {settings.settings.mail.templates.includes('contact_user') &&
              <>
                  <h3>Contact Form (User)</h3>
                  <hr/>

                  <Form.Item
                      label='Subject'
                      name={['mail_settings', 'contact_form_user', 'subject', language]}
                      rules={[{required: true, message: 'Please enter a subject'}]}
                  >
                      <Input/>
                  </Form.Item>
                  <Form.Item
                      label='Preview Text'
                      name={['mail_settings', 'contact_form_user', 'preview', language]}
                      rules={[{required: true, message: 'Please enter a preview text'}]}
                  >
                      <Input.TextArea/>
                  </Form.Item>
              </>
          }

          {settings.settings.mail.templates.includes('contact_admin') &&
              <>
                  <h3>Contact Form (Admin)</h3>
                  <hr/>

                  <Form.Item
                      label='Subject'
                      name={['mail_settings', 'contact_form_admin', 'subject', language]}
                      rules={[{required: true, message: 'Please enter a subject'}]}
                  >
                      <Input/>
                  </Form.Item>
                  <Form.Item
                      label='Preview Text'
                      name={['mail_settings', 'contact_form_admin', 'preview', language]}
                      rules={[{required: true, message: 'Please enter a preview text'}]}
                  >
                      <Input.TextArea/>
                  </Form.Item>
              </>
          }

          {settings.settings.mail.templates.includes('thank_you') &&
              <>
                  <h3>Thank You for Participating</h3>
                  <hr/>
                  <Form.Item
                      label='Subject'
                      name={['mail_settings', 'event_thank_you', 'subject', language]}
                      rules={[{required: true, message: 'Please enter a subject'}]}
                  >
                      <Input/>
                  </Form.Item>
                  <Form.Item
                      label='Preview Text'
                      name={['mail_settings', 'event_thank_you', 'preview', language]}
                      rules={[{required: true, message: 'Please enter a preview text'}]}
                  >
                      <Input.TextArea/>
                  </Form.Item>

                  <h3>Private Event - Enquiry Form (Customer)</h3>
                  <hr/>
                  <Form.Item
                      label='Subject'
                      name={['mail_settings', 'event_enquiry_form_customer', 'subject', language]}
                      rules={[{required: true, message: 'Please enter a subject'}]}
                  >
                      <Input/>
                  </Form.Item>
                  <Form.Item
                      label='Preview Text'
                      name={['mail_settings', 'event_enquiry_form_customer', 'preview', language]}
                      rules={[{required: true, message: 'Please enter a preview text'}]}
                  >
                      <Input.TextArea/>
                  </Form.Item>

                  <h3>Private Event - Enquiry Form (Admin)</h3>
                  <hr/>
                  <Form.Item
                      label='Subject'
                      name={['mail_settings', 'event_enquiry_form_admin', 'subject', language]}
                      rules={[{required: true, message: 'Please enter a subject'}]}
                  >
                      <Input/>
                  </Form.Item>
                  <Form.Item
                      label='Preview Text'
                      name={['mail_settings', 'event_enquiry_form_admin', 'preview', language]}
                      rules={[{required: true, message: 'Please enter a preview text'}]}
                  >
                      <Input.TextArea/>
                  </Form.Item>

                  <h3>Waiting List Subscription Received - (Customer)</h3>
                  <hr/>
                  <Form.Item
                      label='Subject'
                      name={['mail_settings', 'event_waiting_list_customer', 'subject', language]}
                      rules={[{required: true, message: 'Please enter a subject'}]}
                  >
                      <Input/>
                  </Form.Item>
                  <Form.Item
                      label='Preview Text'
                      name={['mail_settings', 'event_waiting_list_customer', 'preview', language]}
                      rules={[{required: true, message: 'Please enter a preview text'}]}
                  >
                      <Input.TextArea/>
                  </Form.Item>

                  <h3>Waiting List Subscription Received - (Admin)</h3>
                  <hr/>
                  <Form.Item
                      label='Subject'
                      name={['mail_settings', 'event_waiting_list_admin', 'subject', language]}
                      rules={[{required: true, message: 'Please enter a subject'}]}
                  >
                      <Input/>
                  </Form.Item>
                  <Form.Item
                      label='Preview Text'
                      name={['mail_settings', 'event_waiting_list_admin', 'preview', language]}
                      rules={[{required: true, message: 'Please enter a preview text'}]}
                  >
                      <Input.TextArea/>
                  </Form.Item>

                  <h3>New Admin</h3>
                  <hr/>
                  <Form.Item
                      label='Subject'
                      name={['mail_settings', 'new_admin', 'subject', language]}
                      rules={[{required: true, message: 'Please enter a subject'}]}
                  >
                      <Input/>
                  </Form.Item>
                  <Form.Item
                      label='Preview Text'
                      name={['mail_settings', 'new_admin', 'preview', language]}
                      rules={[{required: true, message: 'Please enter a preview text'}]}
                  >
                      <Input.TextArea/>
                  </Form.Item>

                  <h3>New User</h3>
                  <hr/>
                  <Form.Item
                      label='Subject'
                      name={['mail_settings', 'new_user', 'subject', language]}
                      rules={[{required: true, message: 'Please enter a subject'}]}
                  >
                      <Input/>
                  </Form.Item>
                  <Form.Item
                      label='Preview Text'
                      name={['mail_settings', 'new_user', 'preview', language]}
                      rules={[{required: true, message: 'Please enter a preview text'}]}
                  >
                      <Input.TextArea/>
                  </Form.Item>

                  <h3>Account Verification</h3>
                  <hr/>
                  <Form.Item
                      label='Subject'
                      name={['mail_settings', 'account_verification', 'subject', language]}
                      rules={[{required: true, message: 'Please enter a subject'}]}
                  >
                      <Input/>
                  </Form.Item>
                  <Form.Item
                      label='Preview Text'
                      name={['mail_settings', 'account_verification', 'preview', language]}
                      rules={[{required: true, message: 'Please enter a preview text'}]}
                  >
                      <Input.TextArea/>
                  </Form.Item>

                  <h3>Password Reset</h3>
                  <hr/>
                  <Form.Item
                      label='Subject'
                      name={['mail_settings', 'password_reset', 'subject', language]}
                      rules={[{required: true, message: 'Please enter a subject'}]}
                  >
                      <Input/>
                  </Form.Item>
                  <Form.Item
                      label='Preview Text'
                      name={['mail_settings', 'password_reset', 'preview', language]}
                      rules={[{required: true, message: 'Please enter a preview text'}]}
                  >
                      <Input.TextArea/>
                  </Form.Item>

                  <h3>Shopping Cart Reminder</h3>
                  <hr/>
                  <Form.Item
                      label='Subject'
                      name={['mail_settings', 'shopping_cart_reminder', 'subject', language]}
                      rules={[{required: true, message: 'Please enter a subject'}]}
                  >
                      <Input/>
                  </Form.Item>
                  <Form.Item
                      label='Preview Text'
                      name={['mail_settings', 'shopping_cart_reminder', 'preview', language]}
                      rules={[{required: true, message: 'Please enter a preview text'}]}
                  >
                      <Input.TextArea/>
                  </Form.Item>

                  {settings.products.enable && (
                      <>
                          <h3>Shipping Confirmation</h3>
                          <hr/>
                          <Form.Item
                              label='Subject'
                              name={['mail_settings', 'shopping_cart_reminder', 'subject', language]}
                              rules={[{required: true, message: 'Please enter a subject'}]}
                          >
                              <Input/>
                          </Form.Item>
                          <Form.Item
                              label='Preview Text'
                              name={['mail_settings', 'shopping_cart_reminder', 'preview', language]}
                              rules={[{required: true, message: 'Please enter a preview text'}]}
                          >
                              <Input.TextArea/>
                          </Form.Item>
                      </>
                  )}

                  {settings.enableGiftCartForVouchers && (
                      <>
                          <h3>Gift Card Vouchers</h3>
                          <hr/>
                          <Form.Item
                              label='Subject'
                              name={['mail_settings', 'gift_card_vouchers', 'subject', language]}
                              rules={[{required: true, message: 'Please enter a subject'}]}
                          >
                              <Input/>
                          </Form.Item>
                          <Form.Item
                              label='Preview Text'
                              name={['mail_settings', 'gift_card_vouchers', 'preview', language]}
                              rules={[{required: true, message: 'Please enter a preview text'}]}
                          >
                              <Input.TextArea/>
                          </Form.Item>
                      </>
                  )}

              </>
          }
      </>
    );
  };

  render() {
    const color = this.props.object;
    const languages = settings.languages;

    return (
      <>
        <FormHeaderComponent
          type={this.props.type}
          newTitle={color ? '' : 'Add a new Setting'}
          editTitle={color ? `Edit Settings` : ''}
        />

        <Form {...formItemLayout} ref={this.props.formRef} name='basic'>
          <Tabs defaultActiveKey={languages[0].code.toLowerCase()}>
            {languages.map((language, index) => {
              return (
                <TabPane forceRender={true} tab={language.name} key={language.code.toLowerCase()}>
                  {this.state.type === 'website' &&
                    this.getWebsiteForm(language.code.toLowerCase())}
                  {this.state.type === 'mail' && this.getMailForm(language.code.toLowerCase())}
                </TabPane>
              );
            })}
          </Tabs>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(SettingsForm);
