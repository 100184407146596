import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, InputNumber, Row, Select, Tabs } from 'antd';
import FormHeaderComponent from '../components/forms/form-header.component';
import { Event, LoopbackFile } from '../types';
import { AuthState } from '../redux/states/user';
import { connect } from 'react-redux';
import { setFormData } from '../helpers/form.helper';
import { FormInstance } from 'antd/es/form';
import ReactQuill from 'react-quill';
import { slugify, updateSlug } from '../helpers/slugify.helper';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import FormImageSelectorComponent from '../components/forms/form-image-selector.component';
import { formItemLayout } from '../components/forms/form-layouts.component';
import 'react-quill/dist/quill.snow.css';
import { settings } from '../../settings';

const { TabPane } = Tabs;

interface Props {
  auth: AuthState;
  type: string;
  visible: boolean;
  isSaving: boolean;
  object?: Event | null;
  formRef: React.RefObject<FormInstance>;
  dataIsSet: boolean;
}

const toolbar = {
  toolbar: [
    [{ header: [1, 2, 3, 4, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
    ['link'],
    ['clean']
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false
  }
};

const LookbooksForm = (props: Props) => {
  const [slugIsValidating, setIsSlugValidating] = useState(false);
  const [slugExists, setSlugExists] = useState(false);
  const [slugError, setSlugError] = useState(false);
  const [fileList, setFileList] = useState<LoopbackFile[]>([]);

  useEffect(() => {
    if (props.object) {
      if (props.object.pictures === null) {
        props.object.pictures = [];
      }
    }
    setFormData(props, props.object, props.formRef);
  }, [props.object]);

  const verifySlug = (e: any) => {
    if (!props.object) {
      let value = slugify(e.target.value.toLowerCase());

      if (value.charAt(0) !== '/') {
        value = '/' + value;
      }

      if (props.formRef && props.formRef.current) {
        props.formRef.current.setFieldsValue({ slug: value });
      }

      setIsSlugValidating(true);

      updateSlug(value, props.formRef, props.object)
        .then((res) => {
          setSlugExists(res.slugExists);
          setIsSlugValidating(false);
        })
        .catch((err) => {
          console.error(err);
          setIsSlugValidating(false);
          setSlugError(true);
        });
    }
  };

  const subFormItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 19 }
    }
  };

  const getForm = (lang: string) => {
    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 16, offset: 8 },
        sm: { span: 16, offset: 8 }
      }
    };

    return (
      <>
        <Form.Item
          label='Year'
          name={'year'}
          rules={[{ required: true, message: 'Please enter a year' }]}
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          label='Name'
          name={['name', lang]}
          rules={[{ required: true, message: 'Please enter a name' }]}
        >
          <Input onChange={lang === settings.languages[0].code ? verifySlug : undefined} />
        </Form.Item>
        <Form.Item
          label={'Category'}
          name={'category'}
          rules={[{ required: true, message: 'Please select a category' }]}
        >
          <Select placeholder='Please select a category'>
            <Select.Option value='campaigns'>Campaigns</Select.Option>
            <Select.Option value='classics'>Classics</Select.Option>
            <Select.Option value='fashion_shows'>Fashion Shows</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label='Url'
          name='slug'
          hasFeedback={slugIsValidating}
          validateStatus={
            slugIsValidating ? 'validating' : slugExists || slugError ? 'error' : undefined
          }
          help={slugError ? 'Unable to check if this url is already in use' : undefined}
          rules={[{ required: true, message: 'Please enter a url' }]}
        >
          <Input
            addonBefore={process.env.REACT_APP_WEB_URL + settings.lookbook.apiUrl}
            onChange={lang === settings.languages[0].code ? verifySlug : undefined}
          />
        </Form.Item>
        <Form.Item
          extra={'This text will be shown in the Google Search results'}
          label='SEO Description'
          name={['seo_description', lang]}
          rules={[{ required: true }]}
        >
          <ReactQuill theme='snow' modules={toolbar} />
        </Form.Item>

        <Form.Item
          label='Description'
          extra={'This text will be shown on the web-page of this category itself'}
          name={['description', lang]}
          rules={[{ required: false }]}
        >
          <ReactQuill theme='snow' modules={toolbar} />
        </Form.Item>

        <Form.Item
          label='Main image'
          name='image'
          rules={[{ required: true, message: 'Please select an image' }]}
        >
          <FormImageSelectorComponent
            showAddon={true}
            formRef={props.formRef}
            rootKey={'image'}
            subKeys={[]}
            formKey={'image'}
          />
        </Form.Item>

        <Form.List name='pictures'>
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                    label={index === 0 ? 'Pictures' : ''}
                    required={false}
                    key={field.key}
                  >
                    <Row>
                      <Col xs={22}>
                        <Form.Item
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: 'Please select an image'
                            }
                          ]}
                          noStyle
                        >
                          <FormImageSelectorComponent
                            showAddon={true}
                            preloadedFileList={fileList}
                            callback={(fileList: LoopbackFile[]) => setFileList(fileList)}
                            formRef={props.formRef}
                            rootKey={'pictures'}
                            subKeys={[]}
                            formKey={field.name.toString()}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={2}>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className='dynamic-delete-button'
                            style={{ margin: '0 8px' }}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        ) : null}
                      </Col>
                    </Row>
                  </Form.Item>
                ))}
                <Row>
                  {fields.length === 0 && (
                    <Col className={'placeholder-label'} xs={24} sm={8}>
                      <span>Pictures :</span>
                    </Col>
                  )}

                  <Col xs={{ span: 16, offset: fields.length === 0 ? 0 : 8 }}>
                    <Form.Item>
                      <Button
                        type='primary'
                        onClick={() => {
                          add();
                        }}
                        style={{ width: '60%' }}
                      >
                        <PlusOutlined /> Add Picture
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            );
          }}
        </Form.List>
      </>
    );
  };

  const event = props.object;
  const languages = settings.languages;

  return (
    <>
      <FormHeaderComponent
        type={props.type}
        newTitle={event ? '' : 'Add a new lookbook'}
        editTitle={event ? `Edit ${event.name[languages[0].code]}` : ''}
      />

      <Form {...formItemLayout} ref={props.formRef} name='basic'>
        <Tabs defaultActiveKey={languages[0].code.toLowerCase()}>
          {languages.map((language, index) => {
            return (
              <TabPane forceRender={true} tab={language.name} key={language.code.toLowerCase()}>
                {getForm(language.code.toLowerCase())}
              </TabPane>
            );
          })}
        </Tabs>
      </Form>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(LookbooksForm);
