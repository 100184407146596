import React, { Component } from 'react';
import { Col, Form, Input, Row, Select, Switch, Table } from 'antd';
import FormHeaderComponent from '../components/forms/form-header.component';
import { formItemLayout } from '../components/forms/form-layouts.component';
import { CartItem, Order, Product, Size } from '../types';
import { AuthState } from '../redux/states/user';
import { connect } from 'react-redux';
import { setFormData, updateFormData } from '../helpers/form.helper';
import { FormInstance } from 'antd/es/form';
import { settings } from '../../settings';

const { Option } = Select;

interface Props {
  auth: AuthState;
  type: string;
  visible: boolean;
  isSaving: boolean;
  object?: Order | null;
  formRef: React.RefObject<FormInstance>;
}

interface State {
  confirmLoading: boolean;
  different_delivery_address: boolean;
}

class OrderForm extends Component<Props, State> {
  formRef = React.createRef<FormInstance>();

  constructor(props: Props) {
    super(props);

    this.state = {
      confirmLoading: false,
      different_delivery_address: this.props.object
        ? this.props.object.customer.different_delivery_address
        : false
    };
  }

  componentDidMount(): void {
    setFormData(this.props, this.props.object, this.props.formRef);
  }

  componentWillUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any) {
    updateFormData(this, nextProps);
  }

  switchDifferentDeliveryAddress = (e: boolean) => {
    this.setState({ different_delivery_address: e });
  };

  formatPrice = (price: number, currency: string) => {
    return `${currency} ${price.toFixed(2).replace('.', ',')}`;
  };

  render() {
    const order = this.props.object;
    let productColumns: any = [
      {
        title: 'Product',
        dataIndex: '',
        key: 'product',
        render: (x: CartItem) => {
          if (x.product) {
            return `${x.product.name[settings.languages[0].code]} - ${
              x.product.subtitle[settings.languages[0].code]
            }`;
          } else if (x.giftCard) {
            return x.giftCard.name[settings.languages[0].code];
          } else if (x.event) {
            return x.event.name[settings.languages[0].code];
          }
        }
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity'
      },
      {
        title: 'Size',
        dataIndex: 'size',
        key: 'size',
        render: (x: { name: string }) => (x ? x.name : '')
      }
    ];

    if (settings.embroidery) {
      productColumns = productColumns.concat([
        {
          title: 'Custom Text',
          dataIndex: 'custom_text',
          key: 'custom_text'
        },
        {
          title: 'Thread Color',
          dataIndex: 'thread_color',
          key: 'thread_color'
        }
      ]);
    }

    productColumns = productColumns.concat([
      {
        title: 'Price (excl. Tax)',
        dataIndex: '',
        key: 'price',
        render: (x: CartItem) => {
          const obj = this.props.object;
          if (obj) {
            if (x.product) {
              return (
                <span>{this.formatPrice(x.product.price * obj.currency_rate, obj.currency)}</span>
              );
            } else if (x.giftCard) {
              return (
                <span>{this.formatPrice(x.giftCard.price * obj.currency_rate, obj.currency)}</span>
              );
            } else if (x.event) {
              return (
                <span>{this.formatPrice(x.event.price * obj.currency_rate, obj.currency)}</span>
              );
            }
          }
        }
      }
    ]);

    return (
      <>
        <FormHeaderComponent
          type={this.props.type}
          newTitle={order ? '' : 'Add a new order'}
          editTitle={order ? `Edit order #${order.order_number}` : ''}
        />

        <Form {...formItemLayout} ref={this.props.formRef} name='basic'>
          <h3>General information</h3>
          <hr />

          <Form.Item
            label={'First name'}
            name={['customer', 'first_name']}
            rules={[{ required: true, message: 'Please input a first name' }]}
          >
            <Input placeholder={'First name'} />
          </Form.Item>

          <Form.Item
            label={'Last name'}
            name={['customer', 'last_name']}
            rules={[{ required: true, message: 'Please input a last name' }]}
          >
            <Input placeholder={'Last name'} />
          </Form.Item>

          <Form.Item
            label={'Street name'}
            name={['customer', 'street_name']}
            rules={[{ required: true, message: 'Please input a street name' }]}
          >
            <Input placeholder={'Street name'} />
          </Form.Item>

          <Form.Item
            label={'Doornumber'}
            name={['customer', 'door_number']}
            rules={[{ required: true, message: 'Please input a door number' }]}
          >
            <Input placeholder={'Doornumber'} />
          </Form.Item>

          <Form.Item
            label={'Postalcode'}
            name={['customer', 'postal_code']}
            rules={[{ required: true, message: 'Please input a postal code' }]}
          >
            <Input placeholder={'Postalcode'} />
          </Form.Item>

          <Form.Item
            label={'City'}
            name={['customer', 'city']}
            rules={[{ required: true, message: 'Please input a city' }]}
          >
            <Input placeholder={'City'} />
          </Form.Item>

          <Form.Item
            label={'Country'}
            name={['customer', 'country']}
            rules={[{ required: true, message: 'Please select a country' }]}
          >
            <Select placeholder={'Country'}>
              <Option value={'uae'}>United Arab Emirates</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={'Email'}
            name={['customer', 'email']}
            rules={[
              {
                type: 'email',
                message: 'The input is not a valid E-mail!'
              },
              {
                required: true,
                message: 'Please input an E-mail!'
              }
            ]}
          >
            <Input placeholder={'Email'} />
          </Form.Item>

          <Form.Item
            label={'Phone'}
            name={['customer', 'phone']}
            rules={[{ required: true, message: 'Please input a phone' }]}
          >
            <Input placeholder={'Phone'} />
          </Form.Item>

          <Form.Item label={'Company'} name={['customer', 'company']} rules={[{ required: false }]}>
            <Input placeholder={'Company'} />
          </Form.Item>

          <Form.Item
            valuePropName='checked'
            label='Different delivery address?'
            name={['customer', 'different_delivery_address']}
            rules={[{ required: false, type: 'boolean' }]}
          >
            <Switch onChange={(e) => this.switchDifferentDeliveryAddress(e)} />
          </Form.Item>

          {this.state.different_delivery_address && (
            <>
              <h3>Delivery information</h3>
              <hr />

              <Form.Item
                label={'First Name'}
                name={['customer', 'delivery_address', 'first_name']}
                rules={[{ required: true, message: 'Please input your first name' }]}
              >
                <Input placeholder={'First name'} />
              </Form.Item>

              <Form.Item
                label={'Last name'}
                name={['customer', 'delivery_address', 'last_name']}
                rules={[{ required: true, message: 'Please input your last name' }]}
              >
                <Input placeholder={'Last name'} />
              </Form.Item>

              <Form.Item
                label={'Street name'}
                name={['customer', 'delivery_address', 'street_name']}
                rules={[{ required: true, message: 'Please input your street name' }]}
              >
                <Input placeholder={'Street name'} />
              </Form.Item>

              <Form.Item
                label={'Door number'}
                name={['customer', 'delivery_address', 'door_number']}
                rules={[{ required: true, message: 'Please input your doornumber' }]}
              >
                <Input placeholder={'Doornumber'} />
              </Form.Item>

              <Form.Item
                label={'Postal code'}
                name={['customer', 'delivery_address', 'postal_code']}
                rules={[{ required: true, message: 'Please input your first name' }]}
              >
                <Input placeholder={'Postal code'} />
              </Form.Item>

              <Form.Item
                label={'City'}
                name={['customer', 'delivery_address', 'city']}
                rules={[{ required: true, message: 'Please input your city' }]}
              >
                <Input placeholder={'City'} />
              </Form.Item>

              <Form.Item
                label={'Country'}
                name={['customer', 'delivery_address', 'country']}
                rules={[{ required: true, message: 'Please input your country' }]}
              >
                <Select placeholder={'Country'}>
                  <Option value={'uae'}>United Arab Emirates</Option>
                </Select>
              </Form.Item>

              <Form.Item
                label={'Email'}
                name={['customer', 'delivery_address', 'email']}
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!'
                  },
                  {
                    required: true,
                    message: 'Please input your E-mail!'
                  }
                ]}
              >
                <Input placeholder={'Email'} />
              </Form.Item>

              <Form.Item
                label={'Phone'}
                name={['customer', 'delivery_address', 'phone']}
                rules={[{ required: true, message: 'Please input your phone number!' }]}
              >
                <Input placeholder={'Phone'} />
              </Form.Item>

              <Form.Item
                label={'Company'}
                name={['customer', 'delivery_address', 'company']}
                rules={[{ required: false }]}
              >
                <Input placeholder={'Company'} />
              </Form.Item>
            </>
          )}

          {order && (
            <>
              <h3>Product Information</h3>
              <hr />
              <Row>
                <Col xs={24}>
                  <Table
                    columns={productColumns}
                    dataSource={[
                      ...order.products,
                      ...JSON.parse(order.events),
                      ...order.gift_cards
                    ]}
                    pagination={false}
                    style={{ marginBottom: 40 }}
                  />
                </Col>
              </Row>
            </>
          )}

          {order && (
            <>
              <h3>Financial information</h3>
              <hr />
              <Row>
                <Col xs={24} md={{ span: 16, offset: 8 }}>
                  <Row>
                    <Col xs={12}>
                      <div>
                        <strong>Currency:</strong> {order.currency}
                      </div>
                      <div>
                        <strong>Currency Rate:</strong> {order.currency_rate}
                      </div>
                      <div>
                        <strong>Tax rate:</strong> {order.tax_rate}%
                      </div>
                      <div>
                        <strong>Payment method:</strong> {order.payment_method}
                      </div>
                      <div>
                        <strong>Payment issuer:</strong>{' '}
                        {order.payment_issuer ? order.payment_issuer : '-'}
                      </div>
                      <div>
                        <strong>Payment status:</strong> {order.payment_status}
                      </div>
                      <div>
                        <strong>Transaction ID:</strong> {order.transaction_id}
                      </div>
                      <div>
                        <strong>Order Number:</strong> {order.order_number}
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div>
                        <strong>Subtotal:</strong>{' '}
                        {this.formatPrice(order.subtotal_sum, order.currency)}
                      </div>
                      <div>
                        <strong>Tax value:</strong>{' '}
                        {this.formatPrice(order.total_sum - order.subtotal_sum, order.currency)}
                      </div>
                      <div>
                        <strong>Order Total:</strong>{' '}
                        {this.formatPrice(order.total_sum, order.currency)}
                      </div>
                      <div>
                        <strong>Shipping Total (excl Tax):</strong>{' '}
                        {this.formatPrice(order.subtotal_shipping, order.currency)}
                      </div>
                      <div>
                        <strong>Shipping Total:</strong>{' '}
                        {this.formatPrice(order.total_shipping, order.currency)}
                      </div>
                      <div>
                        <strong>Total:</strong>{' '}
                        {this.formatPrice(order.total_total, order.currency)}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          )}

          <h3>Delivery information</h3>
          <hr />

          {order && (
            <Row>
              <Col xs={24} md={{ span: 16, offset: 8 }}>
                <div>
                  <strong>Delivery Type:</strong> {order.delivery_type}
                </div>
                <div>
                  <strong>Delivery Status:</strong> {order.delivery_status}
                </div>
                <div>
                  <strong>Tracking Number:</strong>{' '}
                  {order.tracking_number ? order.tracking_number : '-'}
                </div>
                <div>
                  <strong>Shipment mail sent:</strong> {order.shipment_mail_sent ? 'Yes' : 'No'}
                </div>
              </Col>
            </Row>
          )}
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(OrderForm);
