import React, { Component } from 'react';
import { Form, Input, Tabs } from 'antd';
import FormHeaderComponent from '../components/forms/form-header.component';
import { formItemLayout } from '../components/forms/form-layouts.component';
import { ProductCategory } from '../types';
import { AuthState } from '../redux/states/user';
import { connect } from 'react-redux';
import { setFormData, updateFormData } from '../helpers/form.helper';
import { FormInstance } from 'antd/es/form';
import { settings } from '../../settings';

const { TabPane } = Tabs;

interface Props {
  auth: AuthState;
  type: string;
  visible: boolean;
  isSaving: boolean;
  object?: ProductCategory | null;
  formRef: React.RefObject<FormInstance>;
}

interface State {
  confirmLoading: boolean;
  slugIsValidating: boolean;
  slugExists: boolean;
  slugError: boolean;
  categoryTitle: string;
}

class ProductCategoryForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      confirmLoading: false,
      slugExists: false,
      slugIsValidating: false,
      slugError: false,
      categoryTitle: ''
    };
  }

  componentDidMount(): void {
    setFormData(this.props, this.props.object, this.props.formRef);
  }

  componentWillUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any) {
    updateFormData(this, nextProps);
  }

  getForm = (lang: string) => {
    return (
      <>
        <Form.Item
          label='Name'
          name={['name', lang]}
          rules={[{ required: true, message: 'Please enter a name' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Url'
          name={'slug'}
          rules={[{ required: false }]}
        >
          <Input addonBefore={process.env.REACT_APP_WEB_URL} />
        </Form.Item>
      </>
    );
  };

  render() {
    const productCategory = this.props.object;
    const languages = settings.languages;
    return (
      <>
        <FormHeaderComponent
          type={this.props.type}
          newTitle={productCategory ? '' : 'Add a new Product Category'}
          editTitle={productCategory ? `Edit ${productCategory.name[languages[0].code]}` : ''}
        />

        <Form {...formItemLayout} ref={this.props.formRef} name='basic'>
          <Tabs defaultActiveKey={languages[0].code.toLowerCase()}>
            {languages.map((language, index) => {
              return (
                <TabPane forceRender={true} tab={language.name} key={language.code.toLowerCase()}>
                  {this.getForm(language.code.toLowerCase())}
                </TabPane>
              );
            })}
          </Tabs>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(ProductCategoryForm);
