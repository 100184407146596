import { Button, Col, Form, Input, InputNumber, Row } from 'antd';
import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { AuthState } from '../../redux/states/user';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { formItemLayout } from '../../components/forms/form-layouts.component';
import FormImageSelectorComponent from '../../components/forms/form-image-selector.component';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { LoopbackFile } from '../../types';

interface Props {
  auth: AuthState;
  field: any;
  formRef: any;
  language: string;
  isSaving: boolean;
}

const DonationModule = (props: Props) => {
  const [fileList, setFileList] = useState<LoopbackFile[]>([]);

    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 22, offset: 0 },
        sm: { span: 16, offset: 8 }
      }
    };

    const subFormItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 }
      }
    };

    return (
      <>
        <Form.Item
          label='Title'
          name={[props.field.name, 'settings', 'title', props.language]}
          rules={[{ required: false }]}
        >
          <Input placeholder='Title of this block' />
        </Form.Item>
        <Form.Item
          label='Sub-title'
          name={[props.field.name, 'settings', 'description', props.language]}
          rules={[{ required: false }]}
        >
          <ReactQuill theme='snow' />
        </Form.Item>
        <Form.Item
          label='Background color'
          name={[props.field.name, 'settings', 'background_color']}
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>
        <Form.List name={[props.field.name, 'settings', 'donation_options']}>
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                    label={index === 0 ? 'Options' : ''}
                    required={false}
                    key={field.key}
                  >
                    <Row className={'form-sub-section'}>
                      <Col xs={22}>
                        <Row>
                          <Col xs={24}>
                            <Form.Item
                              {...subFormItemLayout}
                              label={'Image'}
                              name={[field.name, 'image', 'url']}
                              validateTrigger={['onChange', 'onBlur']}
                              rules={[{ required: true, message: 'Please select an image' }]}
                            >
                              <FormImageSelectorComponent
                                showAddon={true}
                                preloadedFileList={fileList}
                                callback={(fileList: LoopbackFile[]) => setFileList(fileList)}
                                formRef={props.formRef}
                                rootKey={'sections'}
                                subKeys={[
                                  props.field.name,
                                  'settings',
                                  'donation_options',
                                  field.name,
                                  'image'
                                ]}
                                formKey={'url'}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24}>
                            <Form.Item
                              {...subFormItemLayout}
                              label={'Title'}
                              name={[field.name, 'title', props.language]}
                              rules={[{ required: false }]}
                            >
                              <Input placeholder='enter a title' />
                            </Form.Item>
                          </Col>
                          <Col xs={24}>
                            <Form.Item
                              {...subFormItemLayout}
                              label={
                                process.env.REACT_APP_CURRENCY ? (
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: `Donation amount in ${process.env.REACT_APP_CURRENCY}`
                                    }}
                                  />
                                ) : (
                                  'Donation amount'
                                )
                              }
                              name={[field.name, 'amount']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter a donation amount',
                                  type: 'number'
                                }
                              ]}
                            >
                              <InputNumber
                                style={{ width: '100%' }}
                                formatter={(value) =>
                                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                }
                                parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                              />
                            </Form.Item>
                          </Col>

                          <Col xs={24}>
                            <Form.Item
                              {...subFormItemLayout}
                              label={'Button text'}
                              name={[field.name, 'button', 'text', props.language]}
                              rules={[{ required: false }]}
                            >
                              <Input placeholder='enter a subtitle...' />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={2}>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className='dynamic-delete-button'
                            style={{ margin: '0 8px' }}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        ) : null}
                      </Col>
                    </Row>
                  </Form.Item>
                ))}

                <Row>
                  {fields.length === 0 && (
                    <Col className={'placeholder-label'} xs={24} sm={8}>
                      <span>Options:</span>
                    </Col>
                  )}

                  <Col xs={24} sm={{ span: 16, offset: fields.length === 0 ? 0 : 8 }}>
                    <Form.Item>
                      <Button
                        type='primary'
                        onClick={() => {
                          add();
                        }}
                        style={{ width: '60%' }}
                      >
                        <PlusOutlined /> Add Option
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            );
          }}
        </Form.List>
      </>
    );
  
}

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(DonationModule);
