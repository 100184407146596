import { Button, Col, Form, Input, InputNumber, Row, Select, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { LoopbackFile, Product } from '../../types';
import { LoopBack } from '../../redux/api';
import { formItemLayout } from '../../components/forms/form-layouts.component';
import FormImageSelectorComponent from '../../components/forms/form-image-selector.component';
import ReactQuill from 'react-quill';

const { Option } = Select;

interface Props {
  field: any;
  language: string;
  formRef: any;
  object: any;
}

const HighlightedProductsModule = (props: Props) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [productsToShow, setProductsToShow] = useState(null);
  const [fileList, setFileList] = useState<LoopbackFile[]>([]);

  useEffect(() => {
    new LoopBack().get('/products').then((res) => {
      setProducts(res);
    });

    if (props.object) {
      setProductsToShow(props.object.productsToShow)
    }
  },[props]);

  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 22, offset: 0 },
      sm: { span: 16, offset: 8 }
    }
  };

  return (
    <>
      <Form.Item
        label='Title'
        name={[props.field.name, 'settings', 'title', props.language]}
        rules={[{ required: false }]}
      >
        <Input placeholder='Title of this block' />
      </Form.Item>
      <Form.Item
        label='Sub-title'
        name={[props.field.name, 'settings', 'subtitle', props.language]}
        rules={[{ required: false }]}
      >
        <ReactQuill theme='snow' />
      </Form.Item>
      <Form.Item
        label='Position of titles'
        name={[props.field.name, 'settings', 'titles_position']}
        rules={[{ required: true, message: 'Please select the position of the titles' }]}
      >
        <Select placeholder='Select num of products per slide'>
          <Option value={'above_products'}>Above products</Option>
          <Option value={'above_button'}>Above button</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label='Background image'
        name={[props.field.name, 'settings', 'background_image']}
        rules={[{ required: false }]}
      >
        <FormImageSelectorComponent
          showAddon={true}
          preloadedFileList={fileList}
          callback={(fileList: LoopbackFile[]) => setFileList(fileList)}
          formRef={props.formRef}
          rootKey={'sections'}
          subKeys={[props.field.name, 'settings']}
          formKey={'background_image'}
        />
      </Form.Item>
      <Form.Item
        label='Products to show'
        name={[props.field.name, 'settings', 'products_to_show']}
        rules={[{ required: true, message: 'Please select which products to show' }]}
      >
        <Select onChange={(e) => setProductsToShow(e)} placeholder='Select which products to show'>
          <Option value={'new_arrivals'}>New Arrivals</Option>
          <Option value={'custom'}>Custom</Option>
        </Select>
      </Form.Item>
      {productsToShow === 'custom' &&
        <Form.List name={[props.field.name, 'settings', 'products']}>
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                    label={index === 0 ? 'Products' : ''}
                    required={false}
                    key={field.key}
                  >
                    <Row>
                      <Col xs={22}>
                        <Form.Item
                          {...field}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: 'Please select a product',
                              type: 'number'
                            }
                          ]}
                          noStyle
                        >
                          <Select placeholder={'Select product...'}>
                            {products.map((product, pindex) => {
                              return (
                                <Option key={pindex} value={product.id}>
                                  {product.name[props.language]}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={2}>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className='dynamic-delete-button'
                            style={{ margin: '0 8px' }}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        ) : null}
                      </Col>
                    </Row>
                  </Form.Item>
                ))}
                <Row>
                  <Col xs={{ span: 16, offset: 8 }}>
                    <Form.Item>
                      <Button
                        type='primary'
                        onClick={() => {
                          add();
                        }}
                        style={{ width: '60%' }}
                      >
                        <PlusOutlined/> Add Product
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            );
          }}
        </Form.List>
      }
      <Form.Item
        label='Auto play?'
        name={[props.field.name, 'settings', 'auto_play']}
        valuePropName='checked'
        rules={[{ required: false, type: 'boolean' }]}
      >
        <Switch />
      </Form.Item>
      <Form.Item
        label='Products per slide'
        name={[props.field.name, 'settings', 'products_per_slide']}
        rules={[
          { required: true, message: 'Please select the number of products to show per slide' }
        ]}
      >
        <Select placeholder='Select num of products per slide'>
          <Option value={3}>3</Option>
          <Option value={4}>4</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label='Display time per slide'
        name={[props.field.name, 'settings', 'auto_play_speed']}
        rules={[{ required: false }]}
      >
        <InputNumber placeholder='Speed in ms...' />
      </Form.Item>
      <Form.Item
        label='Slide time'
        name={[props.field.name, 'settings', 'speed']}
        rules={[{ required: false }]}
      >
        <InputNumber placeholder='Speed in ms...' />
      </Form.Item>
      <Form.Item
        label='Button text'
        name={[props.field.name, 'settings', 'button', 'text', props.language]}
        rules={[{ required: false }]}
      >
        <Input placeholder='Title of this block' />
      </Form.Item>
      <Form.Item
        label='Button url'
        name={[props.field.name, 'settings', 'button', 'url']}
        rules={[{ required: false }]}
      >
        <Input addonBefore={process.env.REACT_APP_WEB_URL} />
      </Form.Item>
    </>
  );
};

export default HighlightedProductsModule;
