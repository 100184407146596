export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const SOFTWARE_CLEAR_CANVAS = 'SOFTWARE_CLEAR_CANVAS';
export const SOFTWARE_UPDATE_CANVAS = 'SOFTWARE_UPDATE_CANVAS';
export const SOFTWARE_SET_TOOL = 'SOFTWARE_SET_TOOL';
export const SOFTWARE_UPDATE_SELECTED_OBJECT = 'SOFTWARE_UPDATE_SELECTED_OBJECT';
export const SOFTWARE_SET_POLYGON_SIDES = 'SOFTWARE_SET_SELECTED_OBJECT';
export const SOFTWARE_SET_LOCK_CANVAS = 'SOFTWARE_SET_LOCK_CANVAS';
export const SOFTWARE_SET_DRAW_PROPERTIES = 'SOFTWARE_SET_DRAW_PROPERTIES';
export const SOFTWARE_SET_FILL_COLOR = 'SOFTWARE_SET_FILL_COLOR';
export const SOFTWARE_SET_STROKE_COLOR = 'SOFTWARE_SET_STROKE_COLOR';
export const SOFTWARE_SET_STROKE_CAP = 'SOFTWARE_SET_STROKE_CAP';
export const SOFTWARE_SET_STROKE_WIDTH = 'SOFTWARE_SET_STROKE_WIDTH';
export const SOFTWARE_SET_STROKE_JOIN = 'SOFTWARE_SET_STROKE_JOIN';

export const APP_SET_SIDEBAR = 'APP_SET_SIDEBAR';
export const APP_TOGGLE_SIDEBAR = 'APP_TOGGLE_SIDEBAR';

export interface ApiError {
  message: string;
  statusCode: number;
  name: string;
}
