enum RoleTypes {
  HOME = 'home',
  ADMIN = 'admin',
  MANAGE_ADMINS = 'admins',
  PRODUCT_TYPES = 'product_types',
  PRODUCT_THEMES = 'product_themes',
  PRODUCT_STYLES = 'product_styles',
  PRODUCT_OCCASIONS = 'product_occasions',
  PRODUCT_CATEGORIES = 'product_categories',
  EVENT_ENQUIRIES = 'event_enquiries',
  PRODUCTS = 'products',
  ORDERS = 'orders',
  GIFT_CARDS = 'gift_cards',
  EMBROIDERY_POSITIONS = 'embroidery_positions',
  COLORS = 'colors',
  CUSTOMERS = 'customers',
  QR_CODES = 'qr_codes',
  DASHBOARD_ANALYTICS = 'dashboard_analytics',
  BLOG_ARTICLES = 'blog_articles',
  FAQS = 'faqs',
  PAGES = 'pages',
  IMAGES = 'images',
  SETTINGS = 'settings',
  VOUCHERS = 'vouchers',
  CURRENCIES = 'currencies',
  COUNTRIES = 'countries',
  SIZES = 'sizes',
  TRANSLATIONS = 'translations',
  PURCHASES = 'purchases',
  SOFTWARE = 'software',
  EVENTS = 'events',
  COURSES = 'courses',
  MEDICINES = 'medicines',
  INGREDIENTS = 'ingredients',
  LOOKBOOKS = 'lookbooks'
}

const hasRole = (roles: Array<RoleTypes>, role: RoleTypes) => {
  return roles.includes(role);
};

export { hasRole, RoleTypes };
