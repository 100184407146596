import { combineReducers } from 'redux';
import auth from './auth.reducer';
import app from './app.reducer';
import software from './software.reducer';

export default combineReducers({
  auth: auth,
  app: app,
  software: software
});
