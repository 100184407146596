import { LoopBack } from '../redux/api';

// eslint-disable no-useless-escape
export const slugify = (string: string) => {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·_,:;';
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return (
    string
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with 'and'
      // .replace(/[^\w-]+/g, '') // Remove all non-word characters
      .replace(/--+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '')
  ); // Trim - from start of text
};

export const updateSlug = async (value: string, formRef: any, object: any) => {
  if (formRef && formRef.current) {
    let filter: any = { where: { slug: value }, limit: 1 };
    if (object) {
      filter = { where: { and: [{ slug: value }, { id: { neq: object.id } }] }, limit: 1 };
    }

    const products = await new LoopBack().get(`/products?filter=${JSON.stringify(filter)}`);
    const pages = await new LoopBack().get(`/pages?filter=${JSON.stringify(filter)}`);
    const blogs = await new LoopBack().get(`/blogs?filter=${JSON.stringify(filter)}`);
    const events = await new LoopBack().get(`/events?filter=${JSON.stringify(filter)}`);
    const giftCards = await new LoopBack().get(`/gift-cards?filter=${JSON.stringify(filter)}`);
    const productThemes = await new LoopBack().get(
      `/product-themes?filter=${JSON.stringify(filter)}`
    );
    const courses = await new LoopBack().get(`/courses?filter=${JSON.stringify(filter)}`);

    if (
      products.length > 0 ||
      pages.count > 0 ||
      blogs.length > 0 ||
      events.length > 0 ||
      giftCards.length > 0 ||
      productThemes.length > 0 ||
      courses.length > 0
    ) {
      return { slugExists: true, slugIsValidating: false };
    } else {
      return { slugExists: false, slugIsValidating: false };
    }
  }
  return { slugExists: false, slugIsValidating: false };
};
