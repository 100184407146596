import { AUTH_ERROR, AUTH_LOGIN, AUTH_LOGOUT, AUTH_REQUEST } from '../types';
import { UserState } from '../states/user';

export default function user(state = UserState, action: any) {
  switch (action.type) {
    case AUTH_REQUEST:
      return {
        ...state,
        user: null,
        isLoggedIn: false,
        loading: true,
        token: ''
      };
    case AUTH_LOGIN:
      return {
        ...state,
        user: action.payload,
        isLoggedIn: true,
        loading: false
      };
    case AUTH_ERROR:
    case AUTH_LOGOUT:
      return {
        ...state,
        user: null,
        isLoggedIn: false,
        loading: false,
        token: ''
      };
    default:
      return state;
  }
}
