import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import HomeComponent from './core-module/components/pages/home.component';
import LoginComponent from './core-module/components/auth/login.component';
import { AuthState, User } from './core-module/redux/states/user';
import ProductsComponent from './core-module/components/pages/products.component';
import FaqsComponent from './core-module/components/pages/faqs.component';
import BlogsComponent from './core-module/components/pages/blogs.component';
import ProductTypesComponent from './core-module/components/pages/product-types.component';
import ColorsComponent from './core-module/components/pages/colors.component';
import CustomersComponent from './core-module/components/pages/customers.component';
import PagesComponent from './core-module/components/pages/pages.component';
import OrdersComponent from './core-module/components/pages/orders.component';
import QRCodesComponent from './core-module/components/pages/qr-codes.component';
import AdminsComponent from './core-module/components/pages/admins.component';
import ImagesComponent from './core-module/components/pages/images.component';
import SettingsComponent from './core-module/components/pages/settings.component';
import VouchersComponent from './core-module/components/pages/vouchers.component';
import CurrenciesComponent from './core-module/components/pages/currencies.component';
import CountriesComponent from './core-module/components/pages/countries.component';
import SizesComponent from './core-module/components/pages/sizes.component';
import TranslationsComponent from './core-module/components/pages/translations.component';
import ProductCategoriesComponent from './core-module/components/pages/product-categories.component';
import ProductThemesComponent from './core-module/components/pages/product-themes.component';
import ProductOccasionsComponent from './core-module/components/pages/product-occasions.component';
import ProductStylesComponent from './core-module/components/pages/product-styles.component';
import NotFoundComponent from './core-module/components/app/not-found';
import LookbookSectionsComponent from './core-module/components/pages/lookbook-sections.component';
import LookbooksComponent from './core-module/components/pages/lookbooks.component';
import { hasRole, RoleTypes } from './core-module/helpers/role.helper';
import jwt_decode from 'jwt-decode';
import {settings} from "./settings";

interface Props {
  auth: AuthState;
}

const PublicRoutes = (props: Props) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (props.auth.isLoggedIn && props.auth.user) {
      if (window.location.pathname === `${process.env.PUBLIC_URL}/`) {
        navigate('/home');
      }
    } else if (window.location.pathname !== `${process.env.PUBLIC_URL}/`) {
      navigate('/');
    }
  }, [props.auth.isLoggedIn]);

  let roles: RoleTypes[] = [];
  if (props.auth.isLoggedIn && props.auth.user) {
    const user: User = jwt_decode(props.auth.user.token);
    roles = user.roles;
  }

  const getRoute = (path: string, role: RoleTypes, Component: JSX.Element) => {
    if (props.auth.isLoggedIn && hasRole(roles, role)) {
      return <Route path={path} element={Component} />;
    }
  };

  return (
    <Routes>
      {getRoute('/home', RoleTypes.ADMIN, <HomeComponent />)}
      {getRoute('/all-images', RoleTypes.ADMIN, <ImagesComponent />)}
      {getRoute('/products', RoleTypes.PRODUCTS, <ProductsComponent />)}
      {getRoute(
        '/product-categories',
        RoleTypes.PRODUCT_CATEGORIES,
        <ProductCategoriesComponent />
      )}
      {getRoute('/product-types', RoleTypes.PRODUCT_TYPES, <ProductTypesComponent />)}
      {getRoute('/product-occasions', RoleTypes.PRODUCT_OCCASIONS, <ProductOccasionsComponent />)}
      {getRoute('/product-styles', RoleTypes.PRODUCT_STYLES, <ProductStylesComponent />)}
      {getRoute('/product-themes', RoleTypes.PRODUCT_THEMES, <ProductThemesComponent />)}
      {getRoute('/product-types', RoleTypes.PRODUCT_TYPES, <ProductTypesComponent />)}
      {getRoute('/settings', RoleTypes.SETTINGS, <SettingsComponent />)}
      {getRoute(`/${settings.lookbook.label.toLowerCase()}`, RoleTypes.LOOKBOOKS, <LookbooksComponent />)}
      {getRoute(`/${settings.lookbook.label.toLowerCase()}-sections`, RoleTypes.LOOKBOOKS, <LookbookSectionsComponent />)}
      {getRoute('/translations', RoleTypes.TRANSLATIONS, <TranslationsComponent />)}
      {getRoute('/sizes', RoleTypes.SIZES, <SizesComponent />)}
      {getRoute('/countries', RoleTypes.COUNTRIES, <CountriesComponent />)}
      {getRoute('/currencies', RoleTypes.CURRENCIES, <CurrenciesComponent />)}
      {getRoute('/vouchers', RoleTypes.VOUCHERS, <VouchersComponent />)}
      {getRoute('/orders', RoleTypes.ORDERS, <OrdersComponent />)}
      {getRoute('/faqs', RoleTypes.FAQS, <FaqsComponent />)}
      {getRoute('/blog', RoleTypes.BLOG_ARTICLES, <BlogsComponent />)}
      {getRoute('/customers', RoleTypes.BLOG_ARTICLES, <CustomersComponent />)}
      {getRoute('/pages', RoleTypes.PAGES, <PagesComponent />)}
      {getRoute('/admins', RoleTypes.MANAGE_ADMINS, <AdminsComponent />)}
      {getRoute('/qr-codes', RoleTypes.QR_CODES, <QRCodesComponent />)}
      {getRoute('/colors', RoleTypes.COLORS, <ColorsComponent />)}
      <Route path={'/'} element={<LoginComponent />} />
      <Route path='*' element={<NotFoundComponent />} />
    </Routes>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(PublicRoutes);
