import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userLoginFetch } from '../redux/actions/user';
import { Button, Checkbox, Col, Form, Input, Row } from 'antd';
import { AuthState } from '../redux/states/user';
import QueueAnim from 'rc-queue-anim';
import '../styles/auth.less';

interface Props {
  userLoginFetch: typeof userLoginFetch;
  auth: AuthState;
  onModeChange: (value: string) => void;
}

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 }
};
const tailLayout = {
  wrapperCol: { xs: { offset: 0, span: 16 }, sm: { offset: 6, span: 16 } }
};

class LoginForm extends Component<Props> {
  componentDidMount(): void {}

  onFinish = (values: any) => {
    this.props.userLoginFetch(values);
  };

  render() {
    const titleLayout = { ...tailLayout.wrapperCol };
    titleLayout.xs = { offset: 0, span: 20 };

    return (
      <div id={'auth-form'}>
        <Row>
          <Col {...titleLayout}>
            <img
              alt={`Logo ${process.env.REACT_APP_NAME}`}
              src={require('../../assets/logo-white.png')}
            />
          </Col>
        </Row>

        <Row>
          <Col {...titleLayout}>
            <div id={'auth-subtitle'}>
              <h1>Login</h1>
            </div>
          </Col>
        </Row>

        <Form {...layout} name='basic' initialValues={{ remember: true }} onFinish={this.onFinish}>
          <QueueAnim type={'bottom'} duration={2500}>
            <div key={1}>
              <Form.Item
                label='Email'
                name='email'
                rules={[{ required: true, message: 'Please input your username!' }]}
              >
                <Input size={'large'} />
              </Form.Item>
            </div>

            <div key={2}>
              <Form.Item
                label='Password'
                name='password'
                rules={[{ required: true, message: 'Please input your password!' }]}
              >
                <Input.Password size={'large'} />
              </Form.Item>
            </div>

            <div key={3}>
              <Form.Item {...tailLayout} name='remember' valuePropName='checked'>
                <Checkbox>Remember me</Checkbox>
              </Form.Item>
            </div>

            <div key={4}>
              <Form.Item {...tailLayout}>
                <Button
                  size={'large'}
                  loading={this.props.auth.loading}
                  type='primary'
                  htmlType='submit'
                >
                  {this.props.auth.loading ? <span>Loading...</span> : <span>Submit</span>}
                </Button>
                <div
                  className={'reset-button'}
                  onClick={() => this.props.onModeChange('reset-request')}
                >
                  Forgot password {'->'}
                </div>
              </Form.Item>
            </div>
          </QueueAnim>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

const mapDispatchToProps = (dispatch: any) => ({
  userLoginFetch: (userInfo: any) => dispatch(userLoginFetch(userInfo))
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
