import { Form, Input, Select } from 'antd';
import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { AuthState } from '../../redux/states/user';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import FormImageSelectorComponent from '../../components/forms/form-image-selector.component';
import { LoopbackFile } from '../../types';

interface Props {
  auth: AuthState;
  field: any;
  formRef: any;
  language: string;
  isSaving: boolean;
}

const TextWithPictureBlockModule = (props: Props) => {
  const [fileList, setFileList] = useState<LoopbackFile[]>([]);

    return (
      <>
        <Form.Item
          label='Title'
          name={[props.field.name, 'settings', 'title', props.language]}
          rules={[{ required: false }]}
        >
          <Input placeholder='Title of this block' />
        </Form.Item>
        <Form.Item
          label='Description'
          name={[props.field.name, 'settings', 'description', props.language]}
          rules={[{ required: false }]}
        >
          <ReactQuill theme='snow' />
        </Form.Item>
        <Form.Item
          label={'Type'}
          name={[props.field.name, 'settings', 'type']}
          rules={[{ required: true, message: 'Please select the type of photo-gallery' }]}
        >
          <Select placeholder={'Select type'}>
            <Select.Option value={'right_to_left'}>
              Text Top Right and Picture Bottom Left
            </Select.Option>
            <Select.Option value={'left_to_right'}>
              Text Top Left and Picture Bottom Right
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label='Image'
          name={[props.field.name, 'settings', 'image', 'url']}
          rules={[{ required: true }]}
        >
          <FormImageSelectorComponent
            showAddon={true}
            preloadedFileList={fileList}
            callback={(fileList: LoopbackFile[]) => setFileList(fileList)}
            formRef={props.formRef}
            rootKey={'sections'}
            subKeys={[props.field.name, 'settings', 'image']}
            formKey={'url'}
          />
        </Form.Item>
        <Form.Item
          label='Button text'
          name={[props.field.name, 'settings', 'button', 'text', props.language]}
          rules={[{ required: true }]}
        >
          <Input placeholder='Book Now' />
        </Form.Item>
        <Form.Item
          label='Button color'
          name={[props.field.name, 'settings', 'button', 'color']}
          rules={[{ required: true }]}
        >
          <Input placeholder='Book Now' />
        </Form.Item>
        <Form.Item
          label='Button url'
          name={[props.field.name, 'settings', 'button', 'url']}
          rules={[{ required: true }]}
        >
          <Input addonBefore={process.env.REACT_APP_WEB_URL} />
        </Form.Item>
      </>
    );
  
}

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(TextWithPictureBlockModule);
