import React, { useEffect } from 'react';
import { Form, Input, Tabs } from 'antd';
import FormHeaderComponent from '../components/forms/form-header.component';
import { formItemLayout } from '../components/forms/form-layouts.component';
import { Color } from '../types';
import { AuthState } from '../redux/states/user';
import { connect } from 'react-redux';
import { setFormData } from '../helpers/form.helper';
import { FormInstance } from 'antd/es/form';
import { settings } from '../../settings';

const { TabPane } = Tabs;

interface Props {
  auth: AuthState;
  type: string;
  visible: boolean;
  isSaving: boolean;
  object?: Color | null;
  formRef: React.RefObject<FormInstance>;
}

const ColorForm = (props: Props) => {
  useEffect(() => {
    setFormData(props, props.object, props.formRef);
  }, [props.object]);

  const getForm = (lang: string) => {
    return (
      <>
        <Form.Item
          label='Name'
          name={['name', lang]}
          rules={[{ required: true, message: 'Please enter a name' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          extra={'Fill in a hex value including the hash sign. For example #AE03EF'}
          label='Color'
          name='value'
          rules={[{ required: true, message: 'Please input a hex value' }]}
        >
          <Input />
        </Form.Item>
      </>
    );
  };

  const color = props.object;
  const languages = settings.languages;

  return (
    <>
      <FormHeaderComponent
        type={props.type}
        newTitle={color ? '' : 'Add a new Color'}
        editTitle={color ? `Edit ${color.name[languages[0].code]}` : ''}
      />

      <Form {...formItemLayout} ref={props.formRef} name='basic'>
        <Tabs defaultActiveKey={languages[0].code.toLowerCase()}>
          {languages.map((language, index) => {
            return (
              <TabPane forceRender={true} tab={language.name} key={language.code.toLowerCase()}>
                {getForm(language.code.toLowerCase())}
              </TabPane>
            );
          })}
        </Tabs>
      </Form>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(ColorForm);
