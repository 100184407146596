import React, { useEffect, useState } from 'react';
import { Button, Col, Input, Layout, Modal, Radio, Row, Space, Table } from 'antd';
import NavbarComponent from '../navigation/navbar.component';
import FooterComponent from '../navigation/footer.component';
import SiderComponent from '../navigation/sider.component';
import { LoopBack } from '../../redux/api';
import {
  DeleteOutlined,
  EditOutlined,
  FilePdfOutlined,
  PlusOutlined,
  QrcodeOutlined
} from '@ant-design/icons';
import FormModal from '../forms/form-modal.component';
import '../../styles/home.less';
import Swal from 'sweetalert2';
import QRCode from 'qrcode.react';
import { connect } from 'react-redux';
import { AuthState } from '../../redux/states/user';
import FileDownload from 'js-file-download';
import { settings } from '../../../settings';
import { randomId } from '../../helpers/form.helper';

const { Content } = Layout;

interface Props {
  auth: AuthState;
  url: string;
  name: string;
  title: string;
  columns: any[];
  form: any;
  actions: string[];
  filter: any;
  searchFields: string[];
  onLanguageChange?: (language: string) => void;
  postUrl?: string;
  customButtonBar?: JSX.Element[];
  loading?: boolean;
  onReloadFinish?: () => void;
  hideActionsColumn?: boolean;
}

const TableComponent = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [searchValue, setSearchValue] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [modalType, setModalType] = useState('new');
  const [formData, setFormData] = useState(null);
  const [sortOptions, setSortOptions] = useState<null | {field: string, order: string}>(null);
  const [QRData, setQRData] = useState<null | { slug: string; name: { [key: string]: string } }>(
    null
  );
  const [showQRModal, setShowQRModal] = useState(false);

  useEffect(() => {
    if (props.loading) {
      fetch(
        {
          sortField: sortOptions  && sortOptions.field,
          sortOrder: sortOptions && sortOptions.order,
          pagination,
        },
        searchValue
      );
    }
  },[props.loading])

  useEffect(() => {
    document.title = `${process.env.REACT_APP_NAME} | ${props.title}`;
    fetch(
      {
        sortField: sortOptions  && sortOptions.field,
        sortOrder: sortOptions && sortOptions.order,
        pagination,
      },
      searchValue
    );
  }, []);

  const fetch = (params: any = {}, searchValue: string) => {
    setLoading(true);
    const filter = { ...props.filter };
    const original = { ...props.filter };
    if (searchValue !== '') {
      if (filter.where) {
        filter.where = { or: [] };
      } else {
        filter.where = { or: [] };
      }
      props.searchFields.map((field) => {
        const obj: any = {};
        obj[field] = { regexp: `/${searchValue}/i` };
        return filter.where.or.push({ and: [{ ...original.where }, { ...obj }] });
      });
    } else if (props.filter.where === undefined) {
      delete filter['where'];
    }

    if (params.sortOrder) {
      filter.order = [`${params.sortField} ${params.sortOrder === 'ascend' ? 'asc' : 'desc'}`];
    } else if (!props.filter.order) {
      delete filter['order'];
    }

    filter.limit = params.pagination.pageSize;
    filter.skip = (params.pagination.current - 1) * params.pagination.pageSize;

    new LoopBack(props.auth.user)
      .get(`${props.url}?filter=${JSON.stringify(filter)}`)
      .then((data) => {
        setLoading(false);
        props.onReloadFinish && props.onReloadFinish();
        setData(data.data ? data.data : data);
        setPagination({
          ...params.pagination,
          total: data.count ? data.count : data.length
        });
      });
  };

  const onDelete = (rowData: any) => {
    Swal.fire({
      title: 'Remove item',
      text: `Are you sure you want to remove this ${props.name.toLowerCase()}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        new LoopBack(props.auth.user)
          .delete(`${props.postUrl ? props.postUrl : props.url}/${rowData.id}`)
          .then((res) => {
            fetch(
              {
                sortField: sortOptions  && sortOptions.field,
                sortOrder: sortOptions && sortOptions.order,
                pagination,
              },
              searchValue
            );
            Swal.fire('Deleted!', `This ${props.name.toLowerCase()} has been delete`, 'success');
          })
          .catch((res) => {
            Swal.fire({
              title: 'Oops...',
              html: `Something went wrong while delete this ${props.name}`,
              icon: 'error'
            });
          });
      }
    });
  };

  const generatePDF = (orderId: number) => {
    new LoopBack(props.auth.user).download(`/orders/invoice/${orderId}`).then((res) => {
      FileDownload(res, 'invoice.pdf');
    });
  };

  const updateTableColumns = () => {
    const actions = props.actions;
    const showEditButton = actions.includes('edit');
    const showDeleteButton = actions.includes('delete');
    const showQRButton = actions.includes('qr');
    const showPDFButton = actions.includes('pdf');
    const size = window.outerWidth > 600 ? undefined : 'small';
    const columns = [...props.columns];

    if ((showEditButton || showDeleteButton) && !props.hideActionsColumn) {
      columns.push({
        title: 'Actions',
        width: 100,
        dataIndex: '',
        render: (rowData: any) => (
          <Space>
            {showEditButton && (
              <Button
                size={size}
                type={'primary'}
                onClick={() => {
                  setModalType('edit');
                  setModalVisible(true);
                  setFormData({ ...rowData, randomId: randomId() }); //random id to trigger form update
                }}
              >
                <EditOutlined /> {window.outerWidth > 768 && <span>Edit</span>}
              </Button>
            )}
            {showDeleteButton && (
              <Button size={size} onClick={() => onDelete(rowData)} type={'primary'}>
                <DeleteOutlined /> {window.outerWidth > 768 && <span>Delete</span>}
              </Button>
            )}
            {showQRButton && window.outerWidth > 600 && (
              <Button
                onClick={() => {
                  setShowQRModal(true);
                  setQRData(rowData);
                }}
              >
                <QrcodeOutlined /> {window.outerWidth > 768 && <span>QR</span>}
              </Button>
            )}
            {showPDFButton && window.outerWidth > 600 && (
              <Button
                size={size}
                onClick={() => {
                  generatePDF(rowData.id);
                }}
              >
                <FilePdfOutlined /> {window.outerWidth > 768 && <span>PDF</span>}
              </Button>
            )}
          </Space>
        )
      });
    }
    return columns;
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setSortOptions(sorter);
    fetch(
      {
        sortField: sorter.field,
        sortOrder: sorter.order,
        pagination,
        ...filters
      },
      searchValue
    );
  };

  const onHide = (refreshData?: boolean) => {
    if (refreshData !== false) {
      fetch(
        {
          sortField: sortOptions  && sortOptions.field,
          sortOrder: sortOptions && sortOptions.order,
          pagination,
        },
        searchValue
      );
    }
    setModalVisible(false);
  };

  const hideQRModal = () => {
    setShowQRModal(false);
  };

  const onSearch = (e: any) => {
    setSearchValue(e.target.value);
    const newPagination = { current: 1, pageSize: pagination.pageSize };
    setPagination(newPagination);

    fetch(
      {
        sortField: sortOptions  && sortOptions.field,
        sortOrder: sortOptions && sortOptions.order,
        pagination: newPagination,
      },
      e.target.value
    );
  };

  const columns = updateTableColumns();

  return (
    <Layout className={'app-container'}>
      <NavbarComponent />
      <Layout className='site-layout'>
        <SiderComponent />
        <Content>
          <div className={'app-container-body'}>
            <div className={'app-container-content'}>
              <div className={'app-container-content-title'}>Manage {props.title}</div>
              <Row>
                <Col xs={24} sm={12}>
                  {props.actions.includes('add') && (
                    <Button
                      style={{ marginRight: 5 }}
                      className={'ant-btn-add-item'}
                      type={'primary'}
                      onClick={() => {
                        setModalType('new');
                        setModalVisible(true);
                        setFormData(null);
                      }}
                    >
                      <PlusOutlined /> Add {props.name}
                    </Button>
                  )}

                  {props.customButtonBar &&
                    props.customButtonBar.map((button) => {
                      return button;
                    })}

                  {props.actions.includes('languages') && settings.languages.length > 1 && (
                    <Radio.Group
                      defaultValue={settings.languages[0].code.toLowerCase()}
                      onChange={(e) =>
                        props.onLanguageChange && props.onLanguageChange(e.target.value)
                      }
                    >
                      {settings.languages.map((language) => {
                        return (
                          <Radio.Button key={language.code} value={language.code}>
                            {language.name}
                          </Radio.Button>
                        );
                      })}
                    </Radio.Group>
                  )}
                </Col>

                <Col xs={24} sm={12}>
                  <Input
                    allowClear={true}
                    style={{ marginBottom: 10 }}
                    placeholder={'Enter a search value...'}
                    addonBefore={'Search'}
                    onChange={onSearch}
                  />
                </Col>
              </Row>
              <Table
                columns={columns}
                rowKey={(record: any) => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
              />
            </div>
          </div>
        </Content>
        <FormModal
          postUrl={props.postUrl}
          name={props.name}
          url={props.url}
          data={formData}
          type={modalType}
          visible={modalVisible}
          hide={onHide}
          form={props.form}
        />
        <Modal
          width={window.outerWidth < 900 ? '90%' : '60%'}
          visible={showQRModal}
          title={`QR Code ${QRData ? `for ${QRData.name[settings.languages[0].code]}` : ''}`}
          onCancel={() => setShowQRModal(false)}
          footer={[
            <Button key='submit' type='primary' onClick={hideQRModal}>
              Close
            </Button>
          ]}
        >
          {QRData && QRData.slug && (
            <div style={{ textAlign: 'center' }}>
              <QRCode size={256} value={process.env.REACT_APP_WEB_URL + QRData.slug} />
              <div style={{ marginTop: 20 }}>
                <strong>URL:</strong> {process.env.REACT_APP_WEB_URL + QRData.slug}
              </div>
            </div>
          )}
        </Modal>
      </Layout>
      <FooterComponent />
    </Layout>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(TableComponent);
