import { Form, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AuthState } from '../../redux/states/user';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { LoopBack } from '../../redux/api';
import { Course, Event } from '../../types';

interface Props {
  auth: AuthState;
  field: any;
  formRef: any;
  language: string;
  isSaving: boolean;
}

const EventWaitingListModule = (props: Props) => {
  const [events, setEvents] = useState<Event[]>([]);
  const [courses, setCourses] = useState<Course[]>([]);

  useEffect(() => {
    new LoopBack().get('/events').then((res) => {
      setEvents(res);
    });

    new LoopBack().get('/courses').then((res) => {
      setCourses(res);
    });
  }, []);

  return (
    <>
      <Form.Item
        label='Title'
        name={[props.field.name, 'settings', 'title', props.language]}
        rules={[{ required: false }]}
      >
        <Input placeholder='Title of this block' />
      </Form.Item>
      <Form.Item
        label='Description'
        name={[props.field.name, 'settings', 'description', props.language]}
        rules={[{ required: false }]}
      >
        <ReactQuill theme='snow' />
      </Form.Item>
      <Form.Item
        label='Background color'
        name={[props.field.name, 'settings', 'background_color']}
        rules={[{ required: false }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={'Course'}
        name={[props.field.name, 'settings', 'course_id']}
        rules={[{ required: false, message: ' Please select a course' }]}
      >
        <Select placeholder={'Select a course'}>
          {courses.map((course, pindex) => {
            return (
              <Select.Option key={pindex} value={course.id}>
                {course.name[props.language]}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(EventWaitingListModule);
