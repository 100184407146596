import React, { useEffect, useState } from 'react';
import { InputProps } from 'antd/es/input/Input';
import { Button, List, Modal } from 'antd';
import { LoopbackFile } from '../../types';
import { LoopBack } from '../../redux/api';
import { AuthState } from '../../redux/states/user';
import { connect } from 'react-redux';
import '../../styles/filepicker.less';

interface Props extends InputProps {
  auth: AuthState;
  showModal: boolean;
  onImageSelected: (file: LoopbackFile) => void;
  hideModal: () => void;
    callback?: any;
    preloadedFileList?: LoopbackFile[];
}

const FormImageSelectorNoInputComponent = (props: Props) => {
  const [fileList, setFileList] = useState<LoopbackFile[]>([]);
  const [loading, setLoading] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [showAll, setShowAll] = useState(false);

    const loadImages = (_showAll: boolean = false) => {
        setLoading(true);
        new LoopBack(props.auth.user)
            .get(`/containers/images/files`)
            .then((res) => {
                const files = res as LoopbackFile[];
                const apiUrl = process.env.REACT_APP_API_URL;
                const fileList = files
                    .map((file, index) => {
                        file.uid = file.mtime + Math.random();
                        file.type = 'image';
                        file.url = `${apiUrl}/containers/${file.container}/download/${file.name}`;
                        file.apiPath = `/images/${file.name}`;
                        return file;
                    });
                const _fileList = fileList.sort((a: any, b: any) => (a.uid > b.uid ? 1 : -1)).reverse();
                setFileList(_fileList);
                setLoading(false);
                if (props.callback) {
                    props.callback(_fileList)
                }
            })
            .catch(() => {
                setLoading(false);
            });
    }

    useEffect(() => {

        if (props.preloadedFileList && props.preloadedFileList.length > 0) {
            setFileList(props.preloadedFileList);
            setLoading(false);
        } else {
            if (props.showModal && fileList.length === 0) {
                loadImages();
            }
        }
    }, [props.showModal]);

  const onSelectedImage = (file: LoopbackFile) => {
    props.onImageSelected(file);
    props.hideModal();
  };

  return (
    <>
      <Modal
        title={'Image Library'}
        visible={props.showModal}
        onCancel={() => {
          props.hideModal();
        }}
        onOk={() => {
          props.hideModal();
        }}
        width={'70%'}
        footer={[
          <Button
            key='Cancel'
            onClick={() => {
              props.hideModal();
            }}
          >
            Cancel
          </Button>
        ]}
      >
        <List
          loading={loading}
          style={{ marginTop: 20 }}
          grid={{ gutter: 16, column: 8 }}
          dataSource={showMore ? showAll ? fileList : fileList.slice(0,75) : fileList.slice(0, 20)}
          renderItem={(file) => (
            <List.Item>
              <div
                title={file.name}
                className='filepicker-image grow'
                style={{
                  backgroundImage: `url('${process.env.REACT_APP_API_URL}/images/${file.name}')`
                }}
                onClick={() => onSelectedImage(file)}
              />
            </List.Item>
          )}
        />

          {!showAll &&
              <>
                  {!showMore ?
                      <Button type={'primary'} onClick={() => setShowMore(true)}>
                          {'Show More Pictures...'}
                      </Button>
                      :
                      <Button type={'primary'} onClick={() => {
                          setShowAll(true);
                      }}>
                          {'Show All Pictures'}
                      </Button>
                  }
              </>
          }
      </Modal>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(FormImageSelectorNoInputComponent);
