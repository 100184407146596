import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Layout, Menu } from 'antd';
import {
  AppstoreOutlined,
  BarcodeOutlined,
  BgColorsOutlined,
  BookOutlined, CalendarOutlined,
  DollarOutlined,
  EditOutlined,
  FileImageOutlined,
  FileOutlined,
  FlagOutlined,
  FontSizeOutlined,
  GiftOutlined,
  GlobalOutlined,
  InfoCircleOutlined,
  MedicineBoxOutlined,
  PieChartOutlined,
  PoundOutlined,
  QrcodeOutlined,
  QuestionCircleOutlined,
  RadiusSettingOutlined,
  SafetyOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  SkinOutlined,
  StarOutlined,
  TagsOutlined,
  UserOutlined,
  YoutubeOutlined
} from '@ant-design/icons';
import '../../styles/sider.less';
import { AuthState, User } from '../../redux/states/user';
import { RoleTypes } from '../../helpers/role.helper';
import jwt_decode from 'jwt-decode';
import { settings } from '../../../settings';
import { AppState } from '../../redux/states/app';
import { setSidebar, toggleSidebar } from '../../redux/actions/app';
import { useNavigate } from 'react-router-dom';

const { Sider } = Layout;
const { SubMenu } = Menu;

interface MainMenuItem {
  name: string;
  label: string;
  url: string;
  key: string;
  icon: JSX.Element;
  submenus?: MenuItem[];
}

interface MenuItem {
  label: string;
  url: string;
  key: string;
  icon: JSX.Element;
  submenus?: MenuItem[];
}

interface Props {
  auth: AuthState;
  app: AppState;
  toggleSidebar: typeof toggleSidebar;
  setSidebar: typeof setSidebar;
}

const SiderComponent = (props: Props) => {
  let navigate = useNavigate();
  let [collapsible, setCollapsible] = useState(true);
  let [collapsed, setCollapsed] = useState(props.app.collapseSide);

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return () => window.removeEventListener('resize', updateWindowDimensions);
  });

  const updateWindowDimensions = () => {
    setCollapsible(window.innerWidth > 768);
    setCollapsed(window.innerWidth <= 768 ? true : props.app.collapseSide);
  };

  const getMenu = (): MenuItem[] => {
    let menu = [
      { label: 'Home', url: '/home', key: 'home', icon: <PieChartOutlined /> },
      { label: 'Admins', url: '/admins', key: 'admins', icon: <SafetyOutlined /> }
    ];

    if (settings.products.enable) {
      let submenus: MenuItem[] = [
        { label: 'Sizes', url: '/sizes', key: 'sizes', icon: <FontSizeOutlined /> }
      ];

      if (settings.products.colors) {
        submenus.push({
          label: 'Colors',
          url: '/colors',
          key: 'colors',
          icon: <BgColorsOutlined />
        });
      }

      if (settings.productTypes.enable) {
        submenus.push({
          label: 'Product Types',
          url: '/product-types',
          key: 'product_types',
          icon: <SkinOutlined />
        });
      }

      submenus = submenus.concat([
        {
          label: 'Product Categories',
          url: '/product-categories',
          key: 'product_categories',
          icon: <AppstoreOutlined />
        }
      ]);

      if (settings.productThemes.enable) {
        submenus.push({
          label: 'Product Themes',
          url: '/product-themes',
          key: 'product_themes',
          icon: <AppstoreOutlined />
        });
      }

      if (settings.products.styles) {
        submenus.push({
          label: 'Product Styles',
          url: '/product-styles',
          key: 'product_styles',
          icon: <AppstoreOutlined />
        });
      }

      if (settings.products.occasions) {
        submenus.push({
          label: 'Product Occasions',
          url: '/product-occasions',
          key: 'product_occasions',
          icon: <AppstoreOutlined />
        });
      }

      const productsItem: MenuItem = {
        label: 'Products',
        url: '/products',
        key: 'products',
        icon: <TagsOutlined />,
        submenus: submenus
      };
      menu.push(productsItem);
    }

    if (settings.lookbook.enable) {
      let submenus: MenuItem[] = [
        {
          label: settings.lookbook.label + 'Sections',
          url: `/${settings.lookbook.label.toLowerCase()}-sections`,
          key: 'lookbook_sections',
          icon: <BookOutlined />
        }
      ];

      const lookbookItem: MenuItem = {
        label: settings.lookbook.label,
        url: settings.lookbook.rootUrl,
        key: 'lookbooks',
        icon: <BookOutlined />,
        submenus: submenus
      };
      menu.push(lookbookItem);
    }

    if (settings.events.enable) {
      menu.push({ label: 'Events', url: '/events', key: 'events', icon: <CalendarOutlined/> });
    }

    if (settings.eventEnquiries) {
      menu.push({
        label: 'Event Enquiries',
        url: '/event-enquiries',
        key: 'event_enquiries',
        icon: <InfoCircleOutlined />
      });
    }

    if (settings.courses.enable) {
      menu.push({ label: 'Courses', url: '/courses', key: 'courses', icon: <YoutubeOutlined /> });
    }

    menu = menu.concat([
      { label: 'Images', url: '/all-images', key: 'images', icon: <FileImageOutlined /> },
      { label: 'FAQs', url: '/faqs', key: 'faqs', icon: <QuestionCircleOutlined /> },
    ]);

    if (settings.webshop.enable) {
      menu.concat([
        { label: 'Customers', url: '/customers', key: 'customers', icon: <UserOutlined /> },
        { label: 'Orders', url: '/orders', key: 'orders', icon: <ShoppingCartOutlined /> },
        { label: 'Vouchers', url: '/vouchers', key: 'vouchers', icon: <DollarOutlined /> },
        { label: 'Currencies', url: '/currencies', key: 'currencies', icon: <PoundOutlined /> },
        { label: 'Countries', url: '/countries', key: 'countries', icon: <GlobalOutlined /> }
      ])
    }

    menu.push(
    { label: 'Translations', url: '/translations', key: 'translations', icon: <FlagOutlined /> })

    if (settings.blog.enable) {
      menu.push({ label: 'Blog', url: '/blog', key: 'blog_articles', icon: <EditOutlined /> });
    }

    if (settings.enableGiftCartForVouchers) {
      menu.push({
        label: 'Gift Cards',
        url: '/gift-cards',
        key: 'gift_cards',
        icon: <GiftOutlined />
      });
    }

    if (settings.ingredients.enable) {
      menu.push({
        label: 'Medicine',
        url: '/medicines',
        key: 'medicines',
        icon: <MedicineBoxOutlined />
      });
      menu.push({
        label: 'Ingredients',
        url: '/ingredients',
        key: 'ingredients',
        icon: <StarOutlined />
      });
    }

    if (settings.embroidery) {
      const embroideryMenu: MenuItem = {
        label: 'Embroidery Positions',
        url: '/embroidery-positions',
        key: 'embroidery_positions',
        icon: <RadiusSettingOutlined />
      };

      const menuProducts: any = menu[2];
      if (menuProducts.submenus) {
        menuProducts.submenus.push(embroideryMenu);
      }

      menu.push({ label: 'Software', url: '/software', key: 'software', icon: <GlobalOutlined /> });
    }

    menu.push({ label: 'Pages', url: '/pages', key: 'pages', icon: <FileOutlined /> });
    if (settings.qrCodes) {
      menu.push({ label: 'QR Codes', url: '/qr-codes', key: 'qr_codes', icon: <QrcodeOutlined /> });
    }
    if (settings.purchases) {
      menu.push({
        label: 'Purchases',
        url: '/purchases',
        key: 'purchases',
        icon: <BarcodeOutlined />
      });
    }
    menu.push({ label: 'Settings', url: '/settings', key: 'settings', icon: <SettingOutlined /> });
    return menu;
  };

  const onCollapse = (collapsed: boolean) => {
    props.toggleSidebar();
  };

  const onClick = (e: any) => {
    navigate(e.key, { replace: true });
  };

  let roles: RoleTypes[] = [];
  if (props.auth.user) {
    const user: User = jwt_decode(props.auth.user.token);
    roles = user.roles;
  }
  roles.push(RoleTypes.HOME);

  const path = '/' + window.location.pathname.split('/').pop();
  const _menu = getMenu();

  return (
    <Sider collapsible={collapsible} collapsed={collapsed} onCollapse={onCollapse}>
      <div className='logo' />
      <Menu theme='dark' selectedKeys={[path]} mode='inline' onClick={onClick}>
        {_menu.map((item) => {
          const role = item.key as RoleTypes;
          if (roles.includes(role) || (role === 'images' && roles.includes(RoleTypes.ADMIN))) {
            if (item.submenus) {
              return (
                <SubMenu key={item.url} icon={item.icon} title={item.label}>
                  <Menu.Item key={item.url}>
                    {item.icon}
                    <span>{item.label}</span>
                  </Menu.Item>
                  {item.submenus.map((menuItem) => {
                    return (
                      <Menu.Item key={menuItem.url}>
                        {menuItem.icon}
                        <span>{menuItem.label}</span>
                      </Menu.Item>
                    );
                  })}
                </SubMenu>
              );
            } else {
              return (
                <Menu.Item key={item.url}>
                  {item.icon}
                  <span>{item.label}</span>
                </Menu.Item>
              );
            }
          }
          return undefined;
        })}
      </Menu>
    </Sider>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth,
  app: state.app
});

const mapDispatchToProps = (dispatch: any) => ({
  setSidebar: (e: boolean) => dispatch(setSidebar(e)),
  toggleSidebar: () => dispatch(toggleSidebar())
});

export default connect<any, any, any>(mapStateToProps, mapDispatchToProps)(SiderComponent);
