import React, { Component } from 'react';
import { Alert, Col, Form, Input, Row } from 'antd';
import FormHeaderComponent from '../components/forms/form-header.component';
import { formItemLayout } from '../components/forms/form-layouts.component';
import { AuthState } from '../redux/states/user';
import { connect } from 'react-redux';
import { FormInstance } from 'antd/es/form';
import { debounce } from '../helpers/debounce.helper';

interface Props {
  auth: AuthState;
  visible: boolean;
  formRef: React.RefObject<FormInstance>;
}

interface State {
  showPasswordError: boolean;
}

class ChangePasswordForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showPasswordError: false
    };

    this.onKeyUp = this.onKeyUp.bind(this);
  }

  onKeyUp = () => {
    if (this.props.formRef && this.props.formRef.current) {
      const values = this.props.formRef.current.getFieldsValue(['password', 'password_repeat']);
      if (values.password !== values.password_repeat) {
        this.setState({ showPasswordError: true });
      } else {
        this.setState({ showPasswordError: false });
      }
    }
  };

  render() {
    return (
      <>
        <FormHeaderComponent type={'new'} newTitle={'Change Password'} editTitle={''} />

        <Form {...formItemLayout} ref={this.props.formRef} name='basic'>
          {this.state.showPasswordError && (
            <Row style={{ marginBottom: 10 }}>
              <Col xs={{ offset: 8, span: 16 }}>
                <Alert
                  message={'Warning'}
                  description={'The passwords do not match'}
                  type={'warning'}
                  showIcon={true}
                  closable={false}
                />
              </Col>
            </Row>
          )}

          <Form.Item
            label='New Password'
            name='password'
            rules={[{ required: true, message: 'Please input a password!' }]}
          >
            <Input type={'password'} placeholder={'Password'} />
          </Form.Item>

          <Form.Item
            label='New Password repeat'
            name='password_repeat'
            rules={[{ required: true, message: 'Please repeat your password!' }]}
          >
            <Input
              type={'password'}
              placeholder={'Password'}
              onKeyUp={debounce(this.onKeyUp, 1000)}
            />
          </Form.Item>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(ChangePasswordForm);
