import React, { useEffect, useState } from 'react';
import { Button, Col, Empty, Layout, Row, Select, Spin } from 'antd';
import NavbarComponent from '../navigation/navbar.component';
import FooterComponent from '../navigation/footer.component';
import SiderComponent from '../navigation/sider.component';
import { initRevenueCart, initSalesChart } from '../../helpers/charts-init.helper';
import { LoopBack } from '../../redux/api';
import { connect } from 'react-redux';
import { AuthState, User } from '../../redux/states/user';
import { Order } from '../../types';
import moment from 'moment';
import '../../styles/home.less';
import { formatPrice } from '../../helpers/price.helper';
import { settings } from '../../../settings';
import FormModal from '../forms/form-modal.component';
import PaymentLinkForm from '../../forms/payment-link.form';
import { hasRole, RoleTypes } from '../../helpers/role.helper';
import jwt_decode from 'jwt-decode';

const { Content } = Layout;

interface Props {
  auth: AuthState;
}

const HomeComponent = (props: Props) => {
  const [year, setYear] = useState(parseInt(moment().format('YYYY')));
  const [customers, setCustomers]: [number[], any] = useState([]);
  const [products, setProducts]: [number, any] = useState(0);
  const [todaysOrders, setTodaysOrders]: [any, any] = useState([]);
  const [monthlyOrders, setMonthlyOrders]: [any, any] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [salesChart, setSalesChart]: [any, any] = useState(null);
  const [revenueChart, setRevenueChart]: [any, any] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    updateCharts(year);

    const startOfYear = moment().startOf('year').format('YYYY-MM-DD');
    const endOfYear = moment().endOf('year').format('YYYY-MM-DD');
    const filter = {
      where: { order_date: { between: [startOfYear, endOfYear] }, payment_status: 'paid' },
      fields: { id: true, order_date: true, total_sum: true, currency_rate: true }
    };

    const startOfToday = moment().startOf('day');
    const endOfToday = moment().add(1, 'days').startOf('day');

    const _filter = {
      where: {
        created: { between: [startOfToday.format('YYYY-MM-DD'), endOfToday.format('YYYY-MM-DD')] }
      },
      fields: { id: true }
    };
    if (hasRole(roles, RoleTypes.CUSTOMERS)) {
      new LoopBack(props.auth.user)
        .get(`/customers?filter=${JSON.stringify(_filter)}`)
        .then((res) => {
          setCustomers(res);
        });
    }

    if (hasRole(roles, RoleTypes.PRODUCTS)) {
      const _filter = {active: true};
      new LoopBack(props.auth.user).get(`/products/count?where=${JSON.stringify(_filter)}`).then((res) => {
        setProducts(res.count);
      });
    }

    if (hasRole(roles, RoleTypes.ORDERS)) {
      new LoopBack(props.auth.user).get(`/orders?filter=${JSON.stringify(filter)}`).then((res) => {
        const _todaysOrders: number[] = [];
        const _monthlyOrders: number[] = [];

        res.data.forEach((order: Order) => {
          const orderDate = order.order_date;

          if (moment(orderDate).format('YYYY-MM-DD') === startOfToday.format('YYYY-MM-DD')) {
            _todaysOrders.push(order.total_sum / order.currency_rate);
          }

          if (moment(orderDate).format('M') === moment().format('M')) {
            _monthlyOrders.push(order.total_sum / order.currency_rate);
          }
        });
        setTodaysOrders(_todaysOrders);
        setMonthlyOrders(_monthlyOrders);
      });
    }
  }, []);

  let roles: RoleTypes[] = [];
  if (props.auth.isLoggedIn && props.auth.user) {
    const user: User = jwt_decode(props.auth.user.token);
    roles = user.roles;
  }

  const updateCharts = (year: number) => {
    const startOfYear = moment().year(year).startOf('year').format('YYYY-MM-DD');
    const endOfYear = moment().year(year).endOf('year').format('YYYY-MM-DD');
    const filter = {
      where: { order_date: { between: [startOfYear, endOfYear] }, payment_status: 'paid' },
      fields: { id: true, order_date: true, total_sum: true, currency_rate: true }
    };

    if (hasRole(roles, RoleTypes.ORDERS)) {
      new LoopBack(props.auth.user)
        .get(`/orders?filter=${JSON.stringify(filter)}`)
        .then((res) => {
          // TODO: move to cronjob in API to reduce front-end overhead
          const data = [
            { month: 'January', sold: 0, sum: 0 },
            { month: 'February', sold: 0, sum: 0 },
            { month: 'March', sold: 0, sum: 0 },
            { month: 'April', sold: 0, sum: 0 },
            { month: 'May', sold: 0, sum: 0 },
            { month: 'June', sold: 0, sum: 0 },
            { month: 'July', sold: 0, sum: 0 },
            { month: 'August', sold: 0, sum: 0 },
            { month: 'September', sold: 0, sum: 0 },
            { month: 'October', sold: 0, sum: 0 },
            { month: 'November', sold: 0, sum: 0 },
            { month: 'December', sold: 0, sum: 0 }
          ];

          res.data.forEach((order: Order) => {
            const orderDate = order.order_date;
            const monthIndex = parseInt(moment(orderDate).format('M')) - 1;
            const monthOrderCount = data[monthIndex].sold;
            const monthSumCount = data[monthIndex].sum;
            data[monthIndex].sold = monthOrderCount + 1;
            data[monthIndex].sum = monthSumCount + order.total_sum / order.currency_rate;
          });

          setData(res.data);

          if (salesChart) {
            salesChart.destroy();
          }
          if (revenueChart) {
            revenueChart.destroy();
          }
          setSalesChart(initSalesChart(data));
          setRevenueChart(initRevenueCart(data));
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const updateSalesYear = (e: number) => {
    setYear(e);
    setLoading(true);
    updateCharts(e);
  };

  const totalSumToday = todaysOrders.reduce((sum: number, x: number) => sum + x, 0);
  const totalSumMonth = monthlyOrders.reduce((sum: number, x: number) => sum + x, 0);

  return (
    <Layout className={'app-container'}>
      <NavbarComponent />
      <Layout className='site-layout'>
        <SiderComponent />
        <Content>
          <div className={'app-container-body'}>
            <Row gutter={12}>
              <Col xs={24} sm={12} lg={6}>
                <div className={'app-container-content'}>
                  <div className={'chart-statistic'}>
                    <div className={'chart-statistic-title'}>Active Products</div>
                    <div className={'chart-statistic-value'}>{products}</div>
                    <div className={'chart-statistic-subtitle'}>Total active products offered</div>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={12} lg={6}>
                <div className={'app-container-content'}>
                  <div className={'chart-statistic'}>
                    <div className={'chart-statistic-title'}>Orders</div>
                    <div className={'chart-statistic-value'}>{todaysOrders.length}</div>
                    <div className={'chart-statistic-subtitle'}>Total orders today</div>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={12} lg={6}>
                <div className={'app-container-content'}>
                  <div className={'chart-statistic'}>
                    <div className={'chart-statistic-title'}>Revenue</div>
                    <div className={'chart-statistic-value'}>
                      <span dangerouslySetInnerHTML={{ __html: formatPrice(totalSumToday) }} />
                    </div>
                    <div className={'chart-statistic-subtitle'}>Total revenue today</div>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={12} lg={6}>
                <div className={'app-container-content'}>
                  <div className={'chart-statistic'}>
                    <div className={'chart-statistic-title'}>Monthly Revenue</div>
                    <div className={'chart-statistic-value'}>
                      <span dangerouslySetInnerHTML={{ __html: formatPrice(totalSumMonth) }} />
                    </div>
                    <div className={'chart-statistic-subtitle'}>Total revenue this month</div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row gutter={12}>
              <Col xs={24} xl={12}>
                <div className={'app-container-content'}>
                  <div className={'app-container-content-title'}>
                    Sales per month
                    <Select value={year} onChange={updateSalesYear}>
                      <Select.Option value={2020}>2020</Select.Option>
                      <Select.Option value={2021}>2021</Select.Option>
                      <Select.Option value={2022}>2022</Select.Option>
                    </Select>
                  </div>
                  {hasRole(roles, RoleTypes.ORDERS) ? (
                    <Spin spinning={loading}>
                      {(data && data.length > 0) ? (
                        <div id='c1' />
                      ) : (
                        <div className={'empty-data'}>
                          <Empty
                            description={
                              'You have not received any orders for the selected year yet'
                            }
                          />
                        </div>
                      )}
                    </Spin>
                  ) : (
                    <div id='c1'>
                      <em>You need access to Orders in order to see this data</em>
                    </div>
                  )}
                </div>
              </Col>
              <Col xs={24} xl={12}>
                <div className={'app-container-content'}>
                  <div className={'app-container-content-title'}>Revenue per month</div>
                  {hasRole(roles, RoleTypes.ORDERS) ? (
                    <Spin spinning={loading}>
                      {data && data.length > 0 ? (
                        <div id='c2' />
                      ) : (
                        <div className={'empty-data'}>
                          <Empty
                            description={
                              'You have not received any orders for the selected year yet'
                            }
                          />
                        </div>
                      )}
                    </Spin>
                  ) : (
                    <div id='c2'>
                      <em>You need access to Orders in order to see this data</em>
                    </div>
                  )}
                </div>
              </Col>
            </Row>

            {settings.home.enablePaymentLink && (
              <Row gutter={12}>
                <Col xs={24} xl={12}>
                  <div className={'app-container-content'}>
                    <div className={'app-container-content-title'}>Payment link</div>
                    <Button onClick={() => setModalVisible(true)} type={'primary'}>
                      Create Payment Link
                    </Button>
                  </div>

                  <FormModal
                    name={'Payment Link'}
                    url={'/create-payment-link'}
                    data={null}
                    type={'new'}
                    visible={modalVisible}
                    hide={() => setModalVisible(false)}
                    form={PaymentLinkForm}
                  />
                </Col>
              </Row>
            )}
          </div>
        </Content>
      </Layout>
      <FooterComponent />
    </Layout>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(HomeComponent);
