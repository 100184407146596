import { Button, Col, Form, Input, Row, Select, Switch } from 'antd';
import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { LoopbackFile } from '../../types';
import { connect } from 'react-redux';
import FormImageSelectorNoInputComponent from '../../components/forms/form-image-selector-no-input.component';
import ReactQuill, { Quill } from 'react-quill';
import { linkType } from '../../helpers/quill';
import { settings } from '../../../settings';
import ColumnLayoutColumnModule from './column-layout-column.module';
import FormImageSelectorComponent from '../../components/forms/form-image-selector.component';
import 'react-quill/dist/quill.snow.css';

const { Option } = Select;

Quill.register(linkType);

interface Props {
  field: any;
  formRef: any;
  language: string;
  isSaving: boolean;
}

const ColumnLayoutModule = (props: Props) => {
  const [showImageModal, setShowImageModal] = useState(false);
  const [activeSection, setActiveSection] = useState(0);
  const [fileList, setFileList] = useState<LoopbackFile[]>([]);

  const quill: any = [];

  const createToolbar = (ind: number) => {
    return {
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
          ['link', 'image', 'video'],
          ['clean']
        ],
        handlers: {
          image: () => {
            setShowImageModal(true);
            setActiveSection(ind);
          }
        }
      },
      clipboard: {
        matchVisual: false
      }
    };
  };

  const doAddImage = (filePath: string, index: number) => {
    if (quill[index].current) {
      const _quill = quill[index].current.getEditor();
      const range = _quill.getSelection(true);

      _quill.insertEmbed(range.index, 'image', filePath);
    }
  };

  return (
    <>
      <Form.Item
        label='Title'
        name={[props.field.name, 'settings', 'title', props.language]}
        rules={[{ required: false }]}
      >
        <Input placeholder='Title of this block' />
      </Form.Item>
      <Form.Item
        label='Subtitle'
        name={[props.field.name, 'settings', 'subtitle', props.language]}
        rules={[{ required: false }]}
      >
        <ReactQuill theme='snow' modules={createToolbar(0)} />
      </Form.Item>
      <Form.Item
        label='Background color'
        name={[props.field.name, 'settings', 'background_color']}
        rules={[{ required: false }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label='Background image'
        name={[props.field.name, 'settings', 'background_image']}
        rules={[{ required: false }]}
      >
        <FormImageSelectorComponent
          showAddon={true}
          preloadedFileList={fileList}
          callback={(fileList: LoopbackFile[]) => setFileList(fileList)}
          formRef={props.formRef}
          rootKey={'sections'}
          subKeys={[props.field.name, 'settings']}
          formKey={'background_image'}
        />
      </Form.Item>
      <Form.Item
        label={'Spacing'}
        name={[props.field.name, 'settings', 'spacing']}
        rules={[{ required: true, message: 'Choose a value for spacing' }]}
      >
        <Select placeholder={'select value'}>
          <Option value='small'>Small</Option>
          <Option value='medium'>Medium</Option>
          <Option value='large'>Large</Option>
        </Select>
      </Form.Item>
      {settings.pages.enableWaveLayout && (
        <Form.Item
          label={'Add wave design at top?'}
          name={[props.field.name, 'settings', 'top_fluid']}
          valuePropName='checked'
        >
          <Switch />
        </Form.Item>
      )}
      {settings.pages.enableWaveLayout && (
        <Form.Item
          label={'Top wave layout'}
          name={[props.field.name, 'settings', 'top_fluid_type']}
          rules={[{ required: false }]}
        >
          <Select placeholder={'select value'}>
            <Option value='br-t-tl'>Bottom-right to Top-left</Option>
            <Option value='bl-t-tr'>Bottom-left to Top-right</Option>
          </Select>
        </Form.Item>
      )}
      {settings.pages.enableWaveLayout && (
        <Form.Item
          label={'Add wave design at bottom?'}
          name={[props.field.name, 'settings', 'bottom_fluid']}
          valuePropName='checked'
        >
          <Switch />
        </Form.Item>
      )}
      {settings.pages.enableWaveLayout && (
        <Form.Item
          label={'Top wave layout'}
          name={[props.field.name, 'settings', 'bottom_fluid_type']}
          rules={[{ required: false }]}
        >
          <Select placeholder={'select value'}>
            <Option value='tr-t-bl'>Top-right to bottom-left</Option>
            <Option value='tl-t-br'>Top-left to bottom-right</Option>
          </Select>
        </Form.Item>
      )}
      <Form.Item
        label='Align columns to top?'
        name={[props.field.name, 'settings', 'align_to_top']}
        valuePropName='checked'
        rules={[{ required: false, type: 'boolean' }]}
      >
        <Switch />
      </Form.Item>
      <Form.Item
        label='Button text'
        name={[props.field.name, 'settings', 'button', 'text', props.language]}
        rules={[{ required: false }]}
      >
        <Input placeholder='Button text' />
      </Form.Item>
      <Form.Item
        label='Button url'
        name={[props.field.name, 'settings', 'button', 'url']}
        rules={[{ required: false }]}
      >
        <Input addonBefore={process.env.REACT_APP_WEB_URL} />
      </Form.Item>
      <Form.List name={[props.field.name, 'settings', 'columns']}>
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map((field, index) => {
                return (
                  <ColumnLayoutColumnModule
                    key={index}
                    formRef={props.formRef}
                    rootField={props.field}
                    field={field}
                    fields={fields}
                    language={props.language}
                    index={index}
                    remove={remove}
                  />
                );
              })}

              <Row>
                {fields.length === 0 && (
                  <Col className={'placeholder-label'} xs={24} sm={8}>
                    <span>Columns:</span>
                  </Col>
                )}

                <Col xs={24} sm={{ span: 16, offset: fields.length === 0 ? 0 : 8 }}>
                  <Form.Item>
                    <Button
                      type='primary'
                      onClick={() => {
                        add();
                      }}
                      style={{ width: '60%' }}
                    >
                      <PlusOutlined /> Add Column
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          );
        }}
      </Form.List>

      <FormImageSelectorNoInputComponent
        showModal={showImageModal}
        hideModal={() => setShowImageModal(false)}
        onImageSelected={(file: LoopbackFile) => {
          const url = file.apiPath;
          doAddImage(process.env.REACT_APP_API_URL + url, activeSection);
          setShowImageModal(false);
        }}
      />
    </>
  );
};

export default connect()(ColumnLayoutModule);
