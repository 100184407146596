import React, { Component } from 'react';
import { Form, Input, InputNumber, Select } from 'antd';
import FormHeaderComponent from '../components/forms/form-header.component';
import { formItemLayout } from '../components/forms/form-layouts.component';
import { ProductType } from '../types';
import { AuthState } from '../redux/states/user';
import { connect } from 'react-redux';
import { setFormData, updateFormData } from '../helpers/form.helper';
import { FormInstance } from 'antd/es/form';
import { settings } from '../../settings';
import moment from 'moment';

interface Props {
  auth: AuthState;
  type: string;
  visible: boolean;
  isSaving: boolean;
  object?: ProductType | null;
  formRef: React.RefObject<FormInstance>;
}

interface State {
  confirmLoading: boolean;
}

class PaymentLinkForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      confirmLoading: false
    };
  }

  componentDidMount(): void {
    setFormData(this.props, this.props.object, this.props.formRef);
  }

  componentWillUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any) {
    updateFormData(this, nextProps);
  }

  render() {
    const productType = this.props.object;
    const languages = settings.languages;
    const now = moment().add(7, 'days');
    return (
      <>
        <FormHeaderComponent
          type={this.props.type}
          newTitle={productType ? '' : 'Create Payment Link'}
          editTitle={productType ? `Edit ${productType.name[languages[0].code]}` : ''}
        />

        <Form {...formItemLayout} ref={this.props.formRef} name='basic'>
          <Form.Item
            label='Description'
            name={'description'}
            rules={[{ required: true, message: 'Please enter a description' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={'Total Price'}
            name='costs'
            rules={[{ required: true, message: 'Please enter a price', type: 'number' }]}
          >
            <InputNumber
              style={{ width: '100%' }}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
          <Form.Item
            label={'Currency'}
            name={'currency'}
            rules={[{ required: true, message: 'Please select a currency' }]}
          >
            <Select placeholder='Please select a currency'>
              <Select.Option value='GBP'>GBP</Select.Option>
              <Select.Option value='EUR'>EUR</Select.Option>
              <Select.Option value='USD'>USD</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(PaymentLinkForm);
