import React, { useState } from 'react';
import TableComponent from './table.component';
import PagesForm from '../../forms/pages.form';
import { settings } from '../../../settings';

const PagesComponent = () => {
  const [language, setLanguage] = useState(settings.languages[0].code.toLowerCase());

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      render: (x: { [key: string]: string }) => x[language]
    },
    {
      title: 'Url',
      dataIndex: 'slug',
      render: (x: string) => (
        <a rel={'noopener noreferrer'} target={'_blank'} href={process.env.REACT_APP_WEB_URL + x}>
          {x}
        </a>
      ),
      responsive: ['sm'],
      sorter: true
    },
    {
      title: 'Description',
      width: 600,
      dataIndex: 'description',
      responsive: ['lg'],
      render: (x: { [key: string]: string }) => (
        <div dangerouslySetInnerHTML={{ __html: x[language] }} />
      )
    }
  ];

  return (
    <TableComponent
      form={PagesForm}
      url={'/pages'}
      filter={{}}
      name={'Page'}
      title={'Pages'}
      columns={columns}
      searchFields={['title', 'slug', 'description']}
      actions={['view', 'add', 'edit', 'delete', 'languages']}
      onLanguageChange={(lang: string) => setLanguage(lang)}
    />
  );
};

export default PagesComponent;
