import React, { Component } from 'react';
import { Alert, Button, Col, Form, Input, Row } from 'antd';
import QueueAnim from 'rc-queue-anim';
import { LoopBack } from '../redux/api';
import Swal from 'sweetalert2';
import '../styles/auth.less';
import { FormInstance } from 'antd/lib/form';
import { debounce } from '../helpers/debounce.helper';

interface Props {
  onModeChange: (value: string) => void;
  token: string;
}

interface State {
  loading: boolean;
  showPasswordError: boolean;
}

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 }
};
const tailLayout = {
  wrapperCol: { xs: { offset: 0, span: 16 }, sm: { offset: 6, span: 16 } }
};

class ResetPasswordForm extends Component<Props, State> {
  formRef = React.createRef<FormInstance>();

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      showPasswordError: false
    };
  }

  onFinish = (values: any) => {
    if (this.props.token) {
      values.token = this.props.token;
      new LoopBack()
        .post(`/admins/update-password`, values)
        .then((res) => {
          this.setState({ loading: false });
          Swal.fire({
            title: 'Password changed',
            text: `You have successfully reset your password`,
            icon: 'success',
            confirmButtonText: 'Ok'
          });
          this.props.onModeChange('login');
        })
        .catch((err) => {
          this.setState({ loading: false });
          Swal.fire({
            title: 'Oops...',
            text: `Something went wrong with resetting your password reset`,
            icon: 'error',
            confirmButtonText: 'Ok'
          });
        });
    } else {
      Swal.fire({
        title: 'Oops...',
        text: `The verification token is missing from your request. Please use the link from the email and try again`,
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }
  };

  onKeyUp = () => {
    if (this.formRef && this.formRef.current) {
      const values = this.formRef.current.getFieldsValue(['password', 'password_repeat']);
      if (values.password !== values.password_repeat) {
        this.setState({ showPasswordError: true });
      } else {
        this.setState({ showPasswordError: false });
      }
    }
  };

  render() {
    return (
      <div id={'auth-form'}>
        <Row>
          <Col {...tailLayout.wrapperCol}>
            <img
              alt={`Logo ${process.env.REACT_APP_NAME}`}
              src={require('../../assets/logo-white.png')}
            />
          </Col>
        </Row>
        <Row>
          <Col {...tailLayout.wrapperCol}>
            <div id={'auth-subtitle'}>
              <h1>Reset Password</h1>
            </div>
          </Col>
        </Row>
        <Form
          {...layout}
          name='basic'
          initialValues={{ remember: true }}
          onFinish={this.onFinish}
          ref={this.formRef}
        >
          {this.state.showPasswordError && (
            <Row style={{ marginBottom: 10 }}>
              <Col xs={{ offset: 6, span: 16 }}>
                <Alert
                  message={'Warning'}
                  description={'The passwords do not match'}
                  type={'warning'}
                  showIcon={true}
                  closable={false}
                />
              </Col>
            </Row>
          )}

          <QueueAnim type={'bottom'} duration={2500}>
            <div key={1}>
              <Form.Item
                label='Password'
                name='password'
                rules={[{ required: true, message: 'Please input a password!' }]}
              >
                <Input type={'password'} placeholder={'Password'} size={'large'} />
              </Form.Item>
            </div>

            <div key={2}>
              <Form.Item
                label='Password repeat'
                name='password_repeat'
                rules={[{ required: true, message: 'Please input a password!' }]}
              >
                <Input
                  type={'password'}
                  placeholder={'Password'}
                  size={'large'}
                  onKeyUp={debounce(this.onKeyUp, 750)}
                />
              </Form.Item>
            </div>

            <div key={3}>
              <Form.Item {...tailLayout} className={'auth-buttons'}>
                <Button
                  size={'large'}
                  style={{ float: 'left' }}
                  htmlType={'button'}
                  onClick={() => this.props.onModeChange('login')}
                >
                  Go to Login
                </Button>
                <Button
                  size={'large'}
                  loading={this.state.loading}
                  type='primary'
                  htmlType='submit'
                >
                  {this.state.loading ? <span>Loading...</span> : <span>Submit</span>}
                </Button>
              </Form.Item>
            </div>
          </QueueAnim>
        </Form>
      </div>
    );
  }
}

export default ResetPasswordForm;
