import React, { Component } from 'react';
import { Form, Input, InputNumber, Select, Switch, Tabs } from 'antd';
import FormHeaderComponent from '../components/forms/form-header.component';
import { formItemLayout } from '../components/forms/form-layouts.component';
import { Currency, Voucher } from '../types';
import { AuthState } from '../redux/states/user';
import { connect } from 'react-redux';
import { setFormData, updateFormData } from '../helpers/form.helper';
import { FormInstance } from 'antd/es/form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { settings } from '../../settings';
import { LoopBack } from '../redux/api';

const { Option } = Select;
const { TabPane } = Tabs;

interface Props {
  auth: AuthState;
  type: string;
  visible: boolean;
  isSaving: boolean;
  object?: Voucher | null;
  formRef: React.RefObject<FormInstance>;
}

interface State {
  confirmLoading: boolean;
  currencies: Currency[];
  type: string | null;
}

class VoucherForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      confirmLoading: false,
      currencies: [],
      type: this.props.object ? this.props.object.type : null
    };

    new LoopBack(this.props.auth.user)
      .get('/currencies')
      .then((res) => {
        this.setState({ currencies: res });
      })
      .catch((err) => {});
  }

  componentDidMount(): void {
    setFormData(this.props, this.props.object, this.props.formRef);
  }

  componentWillUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any) {
    updateFormData(this, nextProps);
  }

  getForm = (language: string) => {
    return (
      <>
        <Form.Item
          label='Name'
          name='name'
          rules={[{ required: true, message: 'Please enter a name' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Code'
          name='code'
          rules={[{ required: true, message: 'Please enter a code' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Navbar text'
          name={['navbar_text', language]}
          rules={[{ required: true, message: 'Please enter a text' }]}
          extra={
            'This text will be displayed in the top navigation bar on the website after a customer successfully applies the voucher'
          }
        >
          <Input />
        </Form.Item>

        {settings.enableGiftCartForVouchers && (
          <Form.Item
            label='Description'
            name={['description', language]}
            rules={[{ required: true, message: 'Please enter a description' }]}
            extra={'This text will be displayed on the giftcard detail page'}
          >
            <ReactQuill theme='snow' />
          </Form.Item>
        )}

        <Form.Item
          label='Max usages'
          name='max_usages'
          rules={[{ required: true, message: 'Please enter a value', type: 'number' }]}
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          label='For new customers only?'
          name='new_customers_only'
          valuePropName='checked'
          rules={[{ required: true, message: 'Please make a choice', type: 'boolean' }]}
        >
          <Switch />
        </Form.Item>

        {settings.enableGiftCartForVouchers && (
          <Form.Item
            extra={
              'This value can not be changed and will be automatically handled by the software'
            }
            label='Part of gift card?'
            name='part_of_gift_card'
            valuePropName='checked'
            rules={[{ required: false, type: 'boolean' }]}
          >
            <Switch disabled={true} />
          </Form.Item>
        )}

        <Form.Item
          label='Type'
          name='type'
          extra={'The reduction will be applied to the total cost incl tax and incl shipping costs'}
          rules={[{ required: true, message: 'Please select a type', type: 'string' }]}
        >
          <Select
            placeholder='Please select a type'
            onChange={(e) => e && this.setState({ type: e.toString() })}
          >
            <Option value='percentage'>Percentage reduction</Option>
            <Option value='fixed_amount'>Fixed value reduction</Option>
          </Select>
        </Form.Item>

        {this.state.type === 'fixed_amount' && (
          <Form.Item
            label={'Currency for this voucher'}
            name={'currency'}
            rules={[{ required: true, message: 'Please select a currency' }]}
          >
            <Select placeholder={'Select a currency'}>
              {this.state.currencies.map((currency) => {
                return (
                  <Select.Option key={currency.id} value={currency.code}>
                    {currency.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          label='Value'
          name='value'
          rules={[{ required: true, message: 'Please enter a value', type: 'number' }]}
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          label='Active'
          name='active'
          valuePropName='checked'
          rules={[{ required: true, message: 'Please make a choice', type: 'boolean' }]}
        >
          <Switch />
        </Form.Item>
      </>
    );
  };

  render() {
    const voucher = this.props.object;

    return (
      <>
        <FormHeaderComponent
          type={this.props.type}
          newTitle={voucher ? '' : 'Add a new Voucher'}
          editTitle={voucher ? `Edit ${voucher.code}` : ''}
        />

        <Form {...formItemLayout} ref={this.props.formRef} name='basic'>
          <Tabs defaultActiveKey={settings.languages[0].code.toLowerCase()}>
            {settings.languages.map((language, index) => {
              return (
                <TabPane forceRender={true} tab={language.name} key={language.code.toLowerCase()}>
                  {this.getForm(language.code.toLowerCase())}
                </TabPane>
              );
            })}
          </Tabs>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(VoucherForm);
