import * as React from 'react';
import { useEffect } from 'react';
import { Button, Col, Result, Row } from 'antd';
import Layout from 'antd/lib/layout';
import { HomeOutlined } from '@ant-design/icons';

const ErrorComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Layout.Content style={{ paddingTop: 0 }}>
        <div>
          <Row style={{ height: '100vh' }} justify={'space-around'} align={'middle'}>
            <Col xs={24} style={{ textAlign: 'center' }}>
              <Result
                status='500'
                title={'Oops...'}
                subTitle={'Something has gone wrong'}
                extra={
                  <a href={'/'}>
                    <Button icon={<HomeOutlined />} type={'primary'} size={'large'}>
                      Go back home
                    </Button>
                  </a>
                }
              />
            </Col>
          </Row>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default ErrorComponent;
