import { Form, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AuthState } from '../../redux/states/user';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { LoopBack } from '../../redux/api';
import { ProductCategory } from '../../types';

interface Props {
  auth: AuthState;
  field: any;
  formRef: any;
  language: string;
  isSaving: boolean;
}

const UpcomingProductsModule = (props: Props) => {
  const [productCategories, setProductCategories] = useState<ProductCategory[]>([]);

  useEffect(() => {
    new LoopBack().get('/product-categories').then((res) => {
      setProductCategories(res);
    });
  }, []);

  return (
    <>
      <Form.Item
        label='Title'
        name={[props.field.name, 'settings', 'title', props.language]}
        rules={[{ required: false }]}
      >
        <Input placeholder='Title of this block' />
      </Form.Item>
      <Form.Item
        label='Sub-title'
        name={[props.field.name, 'settings', 'description', props.language]}
        rules={[{ required: false }]}
      >
        <ReactQuill theme='snow' />
      </Form.Item>
      <Form.Item
        label='Background color'
        name={[props.field.name, 'settings', 'background_color']}
        rules={[{ required: false }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={'Product Category'}
        extra={'Leave empty for all categories'}
        name={[props.field.name, 'settings', 'product_category_id']}
        rules={[{ required: false }]}
      >
        <Select placeholder={'Select a product category'}>
          {productCategories.map((product, pindex) => {
            return (
              <Select.Option key={pindex} value={product.id}>
                {product.name[props.language]}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(UpcomingProductsModule);
