import React, { useState } from 'react';
import TableComponent from './table.component';
import { settings } from '../../../settings';
import ProductThemeForm from '../../forms/product-theme.form';

const ProductThemesComponent = () => {
  const [language, setLanguage] = useState(settings.languages[0].code.toLowerCase());

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      render: (x: { [key: string]: string }) => x[language]
    },
    {
      title: 'Url',
      dataIndex: 'slug',
      render: (x: string) => (
        <a
          rel={'noopener noreferrer'}
          target={'_blank'}
          href={process.env.REACT_APP_WEB_URL + settings.productCategories.rootUrl + x}
        >
          {x}
        </a>
      )
    },
    {
      width: 600,
      title: 'Summary',
      dataIndex: 'summary',
      sorter: true,
      responsive: ['xl'],
      render: (x: { [key: string]: string }) => (
        <div dangerouslySetInnerHTML={{ __html: x[language] }} />
      )
    }
  ];

  return (
    <TableComponent
      form={ProductThemeForm}
      url={'/product-themes'}
      filter={{}}
      name={'Product Theme'}
      title={'Product Themes'}
      columns={columns}
      searchFields={['name']}
      actions={['view', 'add', 'edit', 'delete', 'languages']}
      onLanguageChange={(lang: string) => setLanguage(lang)}
    />
  );
};

export default ProductThemesComponent;
