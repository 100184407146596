import React, { Component } from 'react';
import TableComponent from './table.component';
import { settings } from '../../../settings';
import ProductOccasionForm from '../../forms/product-occasion.form';

interface State {
  language: string;
}

class ProductOccasionsComponent extends Component<{}, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      language: settings.languages[0].code.toLowerCase()
    };
  }

  render() {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: true,
        render: (x: { [key: string]: string }) => x[this.state.language]
      }
    ];

    return (
      <TableComponent
        form={ProductOccasionForm}
        url={'/product-occasions'}
        filter={{}}
        name={'Product Occasion'}
        title={'Product Occasions'}
        columns={columns}
        searchFields={['name']}
        actions={['view', 'add', 'edit', 'delete', 'languages']}
        onLanguageChange={(lang: string) => this.setState({ language: lang })}
      />
    );
  }
}

export default ProductOccasionsComponent;
