import React, { Component } from 'react';
import TableComponent from './table.component';
import { Button, Tag } from 'antd';
import StatusIcon from '../app/status-icon';
import CustomerForm from '../../forms/customer.form';
import FormModal from '../forms/form-modal.component';
import NewsletterForm from '../../forms/newsletter.form';

interface State {
  visible: boolean;
}

class CustomersComponent extends Component<{}, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      visible: false
    };
  }

  render() {
    const columns = [
      { title: 'First Name', dataIndex: 'first_name', sorter: true },
      { title: 'Last Name', dataIndex: 'last_name', sorter: true },
      { title: 'Email', dataIndex: 'email', sorter: true, responsive: ['md'] },
      {
        title: 'Tags',
        dataIndex: 'tags',
        render: (tags: string[]) =>
          tags && tags.length > 0
            ? tags.map((tag, idx) => {
                return (
                  <Tag key={idx} className={'payment-tag'}>
                    {tag}
                  </Tag>
                );
              })
            : undefined,
        responsive: ['xl']
      },
      {
        title: 'Subscribed',
        dataIndex: 'subscribed',
        render: (x: boolean) => new StatusIcon().render(x),
        responsive: ['xl']
      }
    ];

    return (
      <>
        <TableComponent
          form={CustomerForm}
          url={'/customers'}
          filter={{}}
          name={'Customer'}
          title={'Customer'}
          columns={columns}
          searchFields={['first_name', 'last_name', 'email']}
          actions={['add', 'view', 'edit', 'delete']}
          customButtonBar={[
            <Button onClick={() => this.setState({ visible: true })} type={'primary'}>
              Send Newsletter...
            </Button>
          ]}
        />
        <FormModal
          postUrl={'/send-newsletter'}
          name={'Newsletter'}
          url={'/newsletter'}
          data={{}}
          type={'new'}
          visible={this.state.visible}
          hide={() => this.setState({ visible: false })}
          form={NewsletterForm}
        />
      </>
    );
  }
}

export default CustomersComponent;
