import { User } from '../states/user';

export class LoopBack {
  apiURL = process.env.REACT_APP_API_URL;
  token: string | null = null;

  constructor(user?: User) {
    if (user) {
      this.token = user.token;
    }
  }

  buildRequest(method: string, type: 'json' | 'svg', data?: any) {
    let headers: HeadersInit = {};

    headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };

    if (this.token) {
      headers['Authorization'] = `Bearer ${this.token}`;
    }
    const options: RequestInit = { method: method, headers: headers };
    if (data) {
      if (type === 'json') {
        data = JSON.stringify(data);
      } else {
        data = JSON.stringify({ data: data });
      }
      options.body = data;
    }
    return options;
  }

  doFetch(url: string, options: any) {
    const requestURL = this.apiURL + url;
    return fetch(requestURL, options).then((resp: any) => {
      if (resp.ok === false) {
        throw resp;
      }
      return resp.json();
    });
  }

  doDownload(url: string, options: any) {
    const requestURL = this.apiURL + url;
    return fetch(requestURL, options)
      .then((response) => {
        if (response.status && response.status !== 200) {
          throw response;
        }
        return response.blob();
      })
      .then((blob) => {
        return blob;
      });
  }

  get(url: string) {
    const options = this.buildRequest('GET', 'json');
    return this.doFetch(url, options);
  }

  downloadPES(url: string, data: any) {
    const options = this.buildRequest('POST', 'svg', data);
    return this.doDownload(url, options);
  }

  post(url: string, data: any) {
    const options = this.buildRequest('POST', 'json', data);
    return this.doFetch(url, options);
  }

  download(url: string) {
    const options = this.buildRequest('GET', 'json');
    return this.doDownload(url, options);
  }

  downloadPost(url: string, data: any) {
    const options = this.buildRequest('POST', 'json', data);
    return this.doDownload(url, options);
  }

  patch(url: string, data: any) {
    const options = this.buildRequest('PATCH', 'json', data);
    const requestURL = this.apiURL + url;

    return fetch(requestURL, options).then((resp: any) => {
      if (resp.ok === false) {
        throw resp;
      }
      return resp;
    });
  }

  delete(url: string) {
    const options = this.buildRequest('DELETE', 'json');
    const requestURL = this.apiURL + url;

    return fetch(requestURL, options).then((resp: any) => {
      if (resp.ok === false) {
        throw resp;
      }
      return resp;
    });
  }
}
