import { Col, Form, Input, Radio, Row, Switch } from 'antd';
import React, { useState } from 'react';
import FormImageSelectorComponent from '../../components/forms/form-image-selector.component';
import { LoopbackFile } from '../../types';

export const HowItWorksModule = (field: {
  field: { name: string; key: string };
  formRef: any;
  numOfSteps: number;
  onUpdateSteps: any;
  language: string;
}) => {
  const [fileList, setFileList] = useState<LoopbackFile[]>([]);

  return (
    <div>
      <Form.Item
        label='Title'
        name={[field.field.name, 'settings', 'title', field.language]}
        rules={[{ required: true, message: 'Please enter the displayed title for this block' }]}
      >
        <Input placeholder='Title of this block' />
      </Form.Item>
      <Form.Item
        name={[field.field.name, 'settings', 'button', 'show']}
        label='Show button?'
        valuePropName='checked'
      >
        <Switch />
      </Form.Item>
      <Form.Item
        label='Button text'
        name={[field.field.name, 'settings', 'button', 'text', field.language]}
        rules={[{ required: false }]}
      >
        <Input placeholder='Title of this block' />
      </Form.Item>
      <Form.Item
        label='Button url'
        name={[field.field.name, 'settings', 'button', 'url']}
        rules={[{ required: false }]}
      >
        <Input addonBefore={process.env.REACT_APP_WEB_URL} />
      </Form.Item>
      <Form.Item
        label='Background image'
        name={[field.field.name, 'settings', 'background_image', 'url']}
        rules={[{ required: false }]}
      >
        <FormImageSelectorComponent
          showAddon={true}
          preloadedFileList={fileList}
          callback={(fileList: LoopbackFile[]) => setFileList(fileList)}
          formRef={field.formRef}
          rootKey={'sections'}
          subKeys={[field.field.name, 'settings', 'background_image']}
          formKey={'url'}
        />
      </Form.Item>
      <Form.Item
        label='Number of steps'
        name={[field.field.name, 'settings', 'num_of_steps']}
        rules={[{ required: true, message: 'Please enter the displayed title for this block' }]}
      >
        <Radio.Group defaultValue={3} onChange={(e) => field.onUpdateSteps(e.target.value)}>
          <Radio.Button value={3}>Three</Radio.Button>
          <Radio.Button value={4}>Four</Radio.Button>
          <Radio.Button value={5}>Five</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Row>
        <Col xs={{ span: 16, offset: 8 }}>
          <Row className={'form-stretch-columns'}>
            <Col xs={8}>
              <Form.Item
                name={[field.field.name, 'settings', 'step_one', 'title', field.language]}
                rules={[{ required: true, message: 'Please enter a title' }]}
              >
                <Input placeholder='Step 1 - Title' />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item
                name={[field.field.name, 'settings', 'step_one', 'description', field.language]}
                rules={[{ required: true, message: 'Please enter a description' }]}
              >
                <Input placeholder='Step 1 - Description' />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item
                name={[field.field.name, 'settings', 'step_one', 'icon']}
                rules={[{ required: true, message: 'Please choose an icon' }]}
              >
                <FormImageSelectorComponent
                  showAddon={true}
                  preloadedFileList={fileList}
                  callback={(fileList: LoopbackFile[]) => setFileList(fileList)}
                  formRef={field.formRef}
                  rootKey={'sections'}
                  subKeys={[field.field.name, 'settings', 'step_one']}
                  formKey={'icon'}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className={'form-stretch-columns'}>
            <Col xs={8}>
              <Form.Item
                name={[field.field.name, 'settings', 'step_two', 'title', field.language]}
                rules={[{ required: true, message: 'Please enter a title' }]}
              >
                <Input placeholder='Step 2 - Title' />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item
                name={[field.field.name, 'settings', 'step_two', 'description', field.language]}
                rules={[{ required: true, message: 'Please enter a description' }]}
              >
                <Input placeholder='Step 2 - Description' />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item
                name={[field.field.name, 'settings', 'step_two', 'icon']}
                rules={[{ required: true, message: 'Please choose an icon' }]}
              >
                <FormImageSelectorComponent
                  showAddon={true}
                  preloadedFileList={fileList}
                  callback={(fileList: LoopbackFile[]) => setFileList(fileList)}
                  formRef={field.formRef}
                  rootKey={'sections'}
                  subKeys={[field.field.name, 'settings', 'step_two']}
                  formKey={'icon'}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className={'form-stretch-columns'}>
            <Col xs={8}>
              <Form.Item
                name={[field.field.name, 'settings', 'step_three', 'title', field.language]}
                rules={[{ required: true, message: 'Please enter a title' }]}
              >
                <Input placeholder='Step 3 - Title' />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item
                name={[field.field.name, 'settings', 'step_three', 'description', field.language]}
                rules={[{ required: true, message: 'Please enter a description' }]}
              >
                <Input placeholder='Step 3 - Description' />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item
                name={[field.field.name, 'settings', 'step_three', 'icon']}
                rules={[{ required: true, message: 'Please choose an icon' }]}
              >
                <FormImageSelectorComponent
                  showAddon={true}
                  preloadedFileList={fileList}
                  callback={(fileList: LoopbackFile[]) => setFileList(fileList)}
                  formRef={field.formRef}
                  rootKey={'sections'}
                  subKeys={[field.field.name, 'settings', 'step_three']}
                  formKey={'icon'}
                />
              </Form.Item>
            </Col>
          </Row>
          {field.numOfSteps > 3 && (
            <Row className={'form-stretch-columns'}>
              <Col xs={8}>
                <Form.Item
                  name={[field.field.name, 'settings', 'step_four', 'title', field.language]}
                  rules={[{ required: true, message: 'Please enter a title' }]}
                >
                  <Input placeholder='Step 4 - Title' />
                </Form.Item>
              </Col>
              <Col xs={8}>
                <Form.Item
                  name={[field.field.name, 'settings', 'step_four', 'description', field.language]}
                  rules={[{ required: true, message: 'Please enter a description' }]}
                >
                  <Input placeholder='Step 4 - Description' />
                </Form.Item>
              </Col>
              <Col xs={8}>
                <Form.Item
                  name={[field.field.name, 'settings', 'step_four', 'icon']}
                  rules={[{ required: true, message: 'Please choose an icon' }]}
                >
                  <FormImageSelectorComponent
                    showAddon={true}
                    preloadedFileList={fileList}
                    callback={(fileList: LoopbackFile[]) => setFileList(fileList)}
                    formRef={field.formRef}
                    rootKey={'sections'}
                    subKeys={[field.field.name, 'settings', 'step_four']}
                    formKey={'icon'}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          {field.numOfSteps > 4 && (
            <Row className={'form-stretch-columns'}>
              <Col xs={8}>
                <Form.Item
                  name={[field.field.name, 'settings', 'step_five', 'title', field.language]}
                  rules={[{ required: true, message: 'Please enter a title' }]}
                >
                  <Input placeholder='Step 5 - Title' />
                </Form.Item>
              </Col>
              <Col xs={8}>
                <Form.Item
                  name={[field.field.name, 'settings', 'step_five', 'description', field.language]}
                  rules={[{ required: true, message: 'Please enter a description' }]}
                >
                  <Input placeholder='Step 5 - Description' />
                </Form.Item>
              </Col>
              <Col xs={8}>
                <Form.Item
                  name={[field.field.name, 'settings', 'step_five', 'icon']}
                  rules={[{ required: true, message: 'Please choose an icon' }]}
                >
                  <FormImageSelectorComponent
                    showAddon={true}
                    preloadedFileList={fileList}
                    callback={(fileList: LoopbackFile[]) => setFileList(fileList)}
                    formRef={field.formRef}
                    rootKey={'sections'}
                    subKeys={[field.field.name, 'settings', 'step_five']}
                    formKey={'icon'}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </div>
  );
};
