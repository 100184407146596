import { Button, Col, Form, Input, Row, Select } from 'antd';
import React, { Component } from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Color, LoopbackFile, Product, ProductType } from '../../types';
import { formItemLayout } from '../../components/forms/form-layouts.component';
import FormImageSelectorComponent from '../../components/forms/form-image-selector.component';
import { SelectValue } from 'antd/es/select';
import { LoopBack } from '../../redux/api';
import { connect } from 'react-redux';
import { AuthState } from '../../redux/states/user';

const { Option } = Select;

interface Props {
  auth: AuthState;
  field: any;
  formRef: any;
}

interface State {
  products: Product[];
  colors: Color[];
  productTypes: ProductType[];
  fileList: LoopbackFile[]
}

class ImageWallModule extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      products: [],
      colors: [],
      productTypes: [],
      fileList: [],
    };
  }

  componentDidMount(): void {
    new LoopBack(this.props.auth.user).get('/products').then((res) => {
      this.setState({ products: res });
    });

    new LoopBack(this.props.auth.user).get('/colors').then((res) => {
      this.setState({ colors: res });
    });

    new LoopBack(this.props.auth.user).get('/product-types').then((res) => {
      this.setState({ productTypes: res });
    });
  }

  onChange = (value: SelectValue) => {
    this.forceUpdate();
  };

  onFilterChange = (value: SelectValue) => {
    this.forceUpdate();
  };

  getSectionDropdownValue = (fieldKey: number) => {
    if (this.props.formRef && this.props.formRef.current) {
      const sections: any = this.props.formRef.current.getFieldValue('sections');
      const section = sections[this.props.field.key];
      if (section) {
        const settings = section.settings;
        const images = settings.images;
        const image = images[fieldKey];

        if (image && image.image && image.image.link) {
          return image.image.link.type;
        }
      }
    }
    return false;
  };

  getFilterDropdownValue = (fieldKey: number) => {
    if (this.props.formRef && this.props.formRef.current) {
      const sections: any = this.props.formRef.current.getFieldValue('sections');
      const section = sections[this.props.field.key];
      if (section) {
        const settings = section.settings;
        const images = settings.images;
        const image = images[fieldKey];

        if (image && image.image && image.image.link && image.image.link.filter) {
          return image.image.link.filter.property;
        }
      }
    }
    return false;
  };

  render() {
    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 22, offset: 0 },
        sm: { span: 16, offset: 8 }
      }
    };

    const subFormItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 }
      }
    };

    return (
      <>
        <Form.Item
          label='Title'
          name={[this.props.field.name, 'settings', 'title']}
          rules={[{ required: false }]}
        >
          <Input placeholder='Title of this block' />
        </Form.Item>
        <Form.Item
          label='Sub-title'
          name={[this.props.field.name, 'settings', 'subtitle']}
          rules={[{ required: false }]}
        >
          <Input placeholder='Sub-Title of this block' />
        </Form.Item>
        <Form.List name={[this.props.field.name, 'settings', 'images']}>
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                    label={index === 0 ? 'Pictures' : ''}
                    required={false}
                    key={field.key}
                  >
                    <Row className={'form-sub-section'}>
                      <Col xs={22}>
                        <Row>
                          <Col xs={24}>
                            <Form.Item
                              {...subFormItemLayout}
                              label={'Image'}
                              name={[field.name, 'image', 'url']}
                              validateTrigger={['onChange', 'onBlur']}
                              rules={[{ required: true, message: 'Please select an image' }]}
                            >
                              <FormImageSelectorComponent
                                showAddon={true}
                                preloadedFileList={this.state.fileList}
                                callback={(fileList: LoopbackFile[]) => this.setState({fileList})}
                                formRef={this.props.formRef}
                                rootKey={'sections'}
                                subKeys={[
                                  this.props.field.name,
                                  'settings',
                                  'images',
                                  field.name,
                                  'image'
                                ]}
                                formKey={'url'}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24}>
                            <Form.Item
                              {...subFormItemLayout}
                              label={'Title'}
                              name={[field.name, 'image', 'title_block', 'title']}
                              rules={[{ required: false }]}
                            >
                              <Input placeholder='enter a title' />
                            </Form.Item>
                          </Col>
                          <Col xs={24}>
                            <Form.Item
                              {...subFormItemLayout}
                              label={'Subtitle'}
                              name={[field.name, 'image', 'title_block', 'subtitle']}
                              rules={[{ required: false }]}
                            >
                              <Input placeholder='enter a subtitle...' />
                            </Form.Item>
                          </Col>

                          <Col xs={24}>
                            <Form.Item
                              {...subFormItemLayout}
                              label={'Text position'}
                              name={[field.name, 'image', 'title_block', 'position']}
                              rules={[{ required: false }]}
                            >
                              <Select placeholder={'Select a position'}>
                                <Option value={'top'}>Top</Option>
                                <Option value={'middle'}>Middle</Option>
                                <Option value={'bottom'}>Bottom</Option>
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col xs={24}>
                            <Form.Item
                              {...subFormItemLayout}
                              label={'Text alignment'}
                              name={[field.name, 'image', 'title_block', 'alignment']}
                              rules={[{ required: false }]}
                            >
                              <Select placeholder={'Choose an alignment'}>
                                <Option value={'left'}>Left</Option>
                                <Option value={'center'}>Center</Option>
                                <Option value={'right'}>Right</Option>
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col xs={24}>
                            <Form.Item
                              {...subFormItemLayout}
                              label={'Link to'}
                              name={[field.name, 'image', 'link', 'type']}
                              rules={[
                                { required: true, message: 'Choose a value for the link type' }
                              ]}
                            >
                              <Select placeholder={'Select a link type'} onChange={this.onChange}>
                                <Option value={'webpage'}>Webpage</Option>
                                <Option value={'products_with_filter'}>Webshop with filter</Option>
                                <Option value={'product_page'}>Product page</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          {this.getSectionDropdownValue(field.key) === 'webpage' && (
                            <Col xs={24}>
                              <Form.Item
                                {...subFormItemLayout}
                                label={'Link'}
                                name={[field.name, 'image', 'link', 'value']}
                                rules={[{ required: true, message: 'Choose a value for the link' }]}
                              >
                                <Input
                                  addonBefore={process.env.REACT_APP_WEB_URL}
                                  placeholder='enter a subtitle...'
                                />
                              </Form.Item>
                            </Col>
                          )}
                          {this.getSectionDropdownValue(field.key) === 'products_with_filter' && (
                            <Col xs={24}>
                              <Form.Item
                                {...subFormItemLayout}
                                label={'Filter by'}
                                name={[field.name, 'image', 'link', 'filter', 'property']}
                                rules={[{ required: true, message: 'Choose a value for the link' }]}
                              >
                                <Select
                                  placeholder={'Select filter type'}
                                  onChange={this.onFilterChange}
                                >
                                  <Option value={'color'}>Color</Option>
                                  <Option value={'product-type'}>Product Type</Option>
                                  <Option value={'gender'}>Gender</Option>
                                </Select>
                              </Form.Item>
                            </Col>
                          )}
                          {this.getFilterDropdownValue(field.key) === 'product-type' && (
                            <Col xs={24}>
                              <Form.Item
                                {...subFormItemLayout}
                                label={'Filter value'}
                                name={[field.name, 'image', 'link', 'filter', 'value']}
                                rules={[{ required: true, message: 'Choose a value for the link' }]}
                              >
                                <Select placeholder={'select value'}>
                                  {this.state.productTypes.map((productType, pindex) => {
                                    return (
                                      <Option key={pindex} value={productType.id}>
                                        {productType.name['en']}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </Col>
                          )}
                          {this.getFilterDropdownValue(field.key) === 'color' && (
                            <Col xs={24}>
                              <Form.Item
                                {...subFormItemLayout}
                                label={'Filter value'}
                                name={[field.name, 'image', 'link', 'filter', 'value']}
                                rules={[{ required: true, message: 'Choose a value for the link' }]}
                              >
                                <Select placeholder={'select value'}>
                                  {this.state.colors.map((color, pindex) => {
                                    return (
                                      <Option key={pindex} value={color.id}>
                                        {color.name['en']}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </Col>
                          )}
                          {this.getFilterDropdownValue(field.key) === 'gender' && (
                            <Col xs={24}>
                              <Form.Item
                                {...subFormItemLayout}
                                label={'Filter value'}
                                name={[field.name, 'image', 'link', 'filter', 'value']}
                                rules={[{ required: true, message: 'Choose a value for the link' }]}
                              >
                                <Select placeholder={'select value'}>
                                  <Option value='men'>Men</Option>
                                  <Option value='women'>Women</Option>
                                  <Option value='kids'>Kids</Option>
                                  <Option value='baby'>Baby</Option>
                                </Select>
                              </Form.Item>
                            </Col>
                          )}
                          {this.getSectionDropdownValue(field.key) === 'product_page' && (
                            <Col xs={24}>
                              <Form.Item
                                {...subFormItemLayout}
                                label={'Product'}
                                name={[field.name, 'image', 'link', 'value']}
                                rules={[{ required: true, message: 'Choose a product' }]}
                              >
                                <Select placeholder={'Select a product'}>
                                  {this.state.products.map((product, pindex) => {
                                    return (
                                      <Option key={pindex} value={product.id}>
                                        {product.name['en']}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </Col>
                          )}
                        </Row>
                      </Col>
                      <Col xs={2}>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className='dynamic-delete-button'
                            style={{ margin: '0 8px' }}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        ) : null}
                      </Col>
                    </Row>
                  </Form.Item>
                ))}
                <Row>
                  <Col xs={{ span: 16, offset: 8 }}>
                    <Form.Item>
                      <Button
                        type='primary'
                        onClick={() => {
                          add();
                        }}
                        style={{ width: '60%' }}
                      >
                        <PlusOutlined /> Add Image
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            );
          }}
        </Form.List>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(ImageWallModule);
