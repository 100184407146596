import React, { useEffect } from 'react';
import { Form, Input, InputNumber } from 'antd';
import FormHeaderComponent from '../components/forms/form-header.component';
import { formItemLayout } from '../components/forms/form-layouts.component';
import { Currency } from '../types';
import { AuthState } from '../redux/states/user';
import { connect } from 'react-redux';
import { setFormData } from '../helpers/form.helper';
import { FormInstance } from 'antd/es/form';

interface Props {
  auth: AuthState;
  type: string;
  visible: boolean;
  isSaving: boolean;
  object?: Currency | null;
  formRef: React.RefObject<FormInstance>;
}

const CurrencyForm = (props: Props) => {
  useEffect(() => {
    setFormData(props, props.object, props.formRef);
  }, [props.object]);

  const currency = props.object;

  return (
    <>
      <FormHeaderComponent
        type={props.type}
        newTitle={currency ? '' : 'Add a new Currency'}
        editTitle={currency ? `Edit ${currency.code}` : ''}
      />

      <Form {...formItemLayout} ref={props.formRef} name='basic'>
        <Form.Item
          label='Name'
          name='name'
          rules={[{ required: true, message: 'Please enter the name of the currency' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Code'
          name='code'
          rules={[{ required: true, message: 'Please enter a 3 letter code' }]}
        >
          <Input placeholder={'EUR'} />
        </Form.Item>

        <Form.Item
          label='Symbol'
          name='symbol'
          rules={[{ required: true, message: 'Please enter a symbol' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          extra={`Enter the rate against the ${process.env.REACT_APP_CURRENCY_NAME}`}
          label='Rate'
          name='rate'
          rules={[{ required: true, message: 'Please enter a rate', type: 'number' }]}
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
      </Form>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(CurrencyForm);
