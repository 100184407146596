import React from 'react';
import TableComponent from './table.component';
import CurrencyForm from '../../forms/currency.form';
import moment from 'moment';

const CurrenciesComponent = () => {
  const columns = [
    { title: 'Name', dataIndex: 'name', sorter: true },
    { title: 'Code', dataIndex: 'code', sorter: true, responsive: ['lg'] },
    { title: 'Symbol', dataIndex: 'symbol' },
    { title: 'Rate', dataIndex: 'rate', responsive: ['lg'] },
    {
      responsive: ['lg'],
      title: 'Updated',
      dataIndex: 'updated',
      render: (date: string) => moment(date).format('DD-MM-YYYY @ HH:mm')
    }
  ];

  return (
    <TableComponent
      form={CurrencyForm}
      url={'/currencies'}
      filter={{}}
      name={'Currency'}
      title={'Currencies'}
      columns={columns}
      searchFields={['code']}
      actions={['view', 'add', 'edit', 'delete']}
    />
  );
};

export default CurrenciesComponent;
