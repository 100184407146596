import React, { useEffect } from 'react';
import { Form, Input, InputNumber, Select } from 'antd';
import FormHeaderComponent from '../components/forms/form-header.component';
import { formItemLayout } from '../components/forms/form-layouts.component';
import { Size } from '../types';
import { AuthState } from '../redux/states/user';
import { connect } from 'react-redux';
import { setFormData } from '../helpers/form.helper';
import { FormInstance } from 'antd/es/form';

const { Option } = Select;

interface Props {
  auth: AuthState;
  type: string;
  visible: boolean;
  isSaving: boolean;
  object?: Size | null;
  formRef: React.RefObject<FormInstance>;
}

const SizeForm = (props: Props) => {
  useEffect(() => {
    setFormData(props, props.object, props.formRef);
  }, [props.object]);

  const size = props.object;

  return (
    <>
      <FormHeaderComponent
        type={props.type}
        newTitle={size ? '' : 'Add a new Size'}
        editTitle={size ? `Edit ${size.imperial_name}` : ''}
      />

      <Form {...formItemLayout} ref={props.formRef} name='basic'>
        <Form.Item
          label='Imperial Name'
          name='imperial_name'
          rules={[{ required: true, message: 'Please enter a name' }]}
        >
          <Input placeholder={'i.e. 50" x 50"'} />
        </Form.Item>
        <Form.Item
          label='Metric Name'
          name='metric_name'
          rules={[{ required: true, message: 'Please enter a name' }]}
        >
          <Input placeholder={'i.e. 50cm x 50cm'} />
        </Form.Item>
        <Form.Item
          name='category'
          label='Category'
          rules={[{ required: true, message: 'Please select a category' }]}
        >
          <Select placeholder='Select category'>
            <Option value='liquid'>Liquid (ml)</Option>
            <Option value='garment'>Garment (S, M, L, XL)</Option>
            <Option value='square'>Square (a x a)</Option>
            <Option value='rectangular'>Rectangular (a x b)</Option>
            <Option value='volume'>Volume (a x b x c)</Option>
            <Option value='weight'>Weight (gram)</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label='Imperial Dimensions'
          name='imperial_dimensions'
          rules={[{ required: true, message: 'Please input a the dimensions' }]}
        >
          <Input placeholder={'i.e. 50x50 or XL'} />
        </Form.Item>

        <Form.Item
          label='Metric Dimensions'
          name='metric_dimensions'
          rules={[{ required: true, message: 'Please input a the dimensions' }]}
        >
          <Input placeholder={'i.e. 50x50 or XL'} />
        </Form.Item>

        <Form.Item
          extra={
            'These are additional costs that customers have to pay on top of the product price for this particular size'
          }
          label={
            process.env.REACT_APP_CURRENCY ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: `Costs in ${process.env.REACT_APP_CURRENCY}`
                }}
              />
            ) : (
              'Additional Costs'
            )
          }
          name='price'
          rules={[{ required: true, message: 'Please enter a price', type: 'number' }]}
        >
          <InputNumber
            style={{ width: '100%' }}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
          />
        </Form.Item>
      </Form>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(SizeForm);
