import { Button, Col, Form, Input, Row } from 'antd';
import React, { Component, useState } from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { formItemLayout } from '../../components/forms/form-layouts.component';
import FormImageSelectorComponent from '../../components/forms/form-image-selector.component';
import { connect } from 'react-redux';
import { AuthState } from '../../redux/states/user';
import { LoopbackFile } from '../../types';

interface Props {
  auth: AuthState;
  field: any;
  formRef: any;
  language: string;
}

const SliderVideoModule = (props: Props) => {
  const [fileList, setFileList] = useState<LoopbackFile[]>([]);

    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 22, offset: 0 },
        sm: { span: 16, offset: 8 }
      }
    };

    const subFormItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 }
      }
    };

    return (
      <>
        <Form.Item
          label='Title'
          name={[props.field.name, 'settings', 'title', props.language]}
          rules={[{ required: false }]}
        >
          <Input placeholder='Title of this block' />
        </Form.Item>
        <Form.Item
          label='Background color'
          name={[props.field.name, 'settings', 'background_color']}
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Background image'
          name={[props.field.name, 'settings', 'background_image']}
          rules={[{ required: false }]}
        >
          <FormImageSelectorComponent
            showAddon={true}
            preloadedFileList={fileList}
            callback={(fileList: LoopbackFile[]) => setFileList(fileList)}
            formRef={props.formRef}
            rootKey={'sections'}
            subKeys={[props.field.name, 'settings']}
            formKey={'background_image'}
          />
        </Form.Item>
        <Form.List name={[props.field.name, 'settings', 'slides']}>
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                    label={index === 0 ? 'Slides' : ''}
                    required={false}
                    key={field.key}
                  >
                    <Row className={'form-sub-section'}>
                      <Col xs={22}>
                        <Row>
                          <Col xs={24}>
                            <Form.Item
                              {...subFormItemLayout}
                              label={'Video'}
                              name={[field.name, 'video', 'url']}
                              validateTrigger={['onChange', 'onBlur']}
                              rules={[{ required: true, message: 'Please select a video' }]}
                            >
                              <FormImageSelectorComponent
                                showAddon={true}
                                preloadedFileList={fileList}
                                callback={(fileList: LoopbackFile[]) => setFileList(fileList)}
                                formRef={props.formRef}
                                rootKey={'sections'}
                                subKeys={[
                                  props.field.name,
                                  'settings',
                                  'slides',
                                  field.name,
                                  'video'
                                ]}
                                formKey={'url'}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24}>
                            <Form.Item
                              {...subFormItemLayout}
                              label={'Title'}
                              name={[field.name, 'video', 'title', props.language]}
                              rules={[{ required: false }]}
                            >
                              <Input placeholder='enter a title' />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={2}>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className='dynamic-delete-button'
                            style={{ margin: '0 8px' }}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        ) : null}
                      </Col>
                    </Row>
                  </Form.Item>
                ))}
                <Row>
                  <Col xs={{ span: 16, offset: 8 }}>
                    <Form.Item>
                      <Button
                        type='primary'
                        onClick={() => {
                          add();
                        }}
                        style={{ width: '60%' }}
                      >
                        <PlusOutlined /> Add Video
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            );
          }}
        </Form.List>
      </>
    );
}

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(SliderVideoModule);
