import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import { AppState } from '../../redux/states/app';
import '../../styles/footer.less';

const { Footer } = Layout;

interface Props {
  app: AppState;
}

const FooterComponent = (props: Props) => {
  let [collapsed, setCollapsed] = useState(props.app.collapseSide);

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return () => window.removeEventListener('resize', updateWindowDimensions);
  });

  const updateWindowDimensions = () => {
    setCollapsed(window.innerWidth <= 768 ? true : props.app.collapseSide);
  };

  return (
    <Footer className={collapsed ? 'collapsed' : ''} style={{ textAlign: 'center' }}>
      <img
        alt={`Logo ${process.env.REACT_APP_NAME}`}
        src={require('../../../assets/logo-black.png')}
      />
      ©{moment().year()} | By {process.env.REACT_APP_SHOP_OWNER}
    </Footer>
  );
};

const mapStateToProps = (state: any) => ({
  app: state.app
});

export default connect(mapStateToProps)(FooterComponent);
