import React, { useState } from 'react';
import TableComponent from './table.component';
import ColorForm from '../../forms/color.form';
import { Tag } from 'antd';
import { settings } from '../../../settings';

const ColorsComponent = () => {
  const [language, setLanguage] = useState(settings.languages[0].code.toLowerCase());

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      render: (x: { [key: string]: string }) => x[language]
    },
    {
      title: 'Color',
      dataIndex: 'value',
      render: (x: any) => <Tag color={x} />,
      responsive: ['sm']
    }
  ];

  return (
    <TableComponent
      form={ColorForm}
      url={'/colors'}
      filter={{}}
      name={'Color'}
      title={'Colors'}
      columns={columns}
      searchFields={['name']}
      actions={['view', 'add', 'edit', 'delete', 'languages']}
      onLanguageChange={(lang: string) => setLanguage(lang)}
    />
  );
};

export default ColorsComponent;
