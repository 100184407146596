import React, { Component } from 'react';
import { Col, Layout, Row } from 'antd';
import '../../styles/auth.less';
import LoginFormComponent from '../../forms/login.form';
import * as qs from 'query-string';
import ResetPasswordRequestForm from '../../forms/reset-password-request.form';
import ResetPasswordForm from '../../forms/reset-password.form';

interface State {
  mode: string;
  token: string;
}

class LoginComponent extends Component<{}, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      mode: 'login',
      token: ''
    };

    this.onModeChange = this.onModeChange.bind(this);
  }

  getToken() {
    let token: any = null;
    // eslint-disable-next-line no-restricted-globals
    let queryParams = qs.parse(location.search);
    if (Object.keys(queryParams).indexOf('access_token') > -1) {
      token = queryParams.access_token;
    }
    return token;
  }

  componentDidMount() {
    let token = this.getToken();
    this.setState({ token: token, mode: token ? 'reset-password' : 'login' });
  }

  onModeChange = (value: string) => {
    this.setState({ mode: value });
  };

  render() {
    return (
      <Layout className={'auth-container'}>
        <Row className={'auth-container-row'}>
          <Col className={'auth-banner-col'} xs={0} md={12} lg={14} xl={16}>
            <img alt={'Tango'} src={require('../../../assets/banner-auth.jpg')} />
          </Col>
          <Col className={'auth-form-col'} xs={24} md={12} lg={10} xl={8}>
            <Row>
              <Col xs={{ offset: 2, span: 20 }} sm={{ offset: 2, span: 20 }}>
                {this.state.mode === 'login' && (
                  <LoginFormComponent onModeChange={this.onModeChange} />
                )}
                {this.state.mode === 'reset-request' && (
                  <ResetPasswordRequestForm onModeChange={this.onModeChange} />
                )}
                {this.state.mode === 'reset-password' && (
                  <ResetPasswordForm token={this.state.token} onModeChange={this.onModeChange} />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Layout>
    );
  }
}

export default LoginComponent;
