import React, { Component } from 'react';
import { Button, Col, Input, Layout, Radio, Row, Space, Table, Tabs } from 'antd';
import NavbarComponent from '../navigation/navbar.component';
import SiderComponent from '../navigation/sider.component';
import FormModal from '../forms/form-modal.component';
import FooterComponent from '../navigation/footer.component';
import { LoopBack } from '../../redux/api';
import Swal from 'sweetalert2';
import { AuthState } from '../../redux/states/user';
import TranslationForm from '../../forms/translation.form';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { settings } from '../../../settings';

const { TabPane } = Tabs;

interface Props {
  auth: AuthState;
}

interface State {
  data: any[];
  pagination: any;
  loading: boolean;
  modalVisible: boolean;
  modalType: string;
  formData: any;
  searchValue: string;
  filter: any;
  tab: string;
  language: string;
}
const { Content } = Layout;

class TranslationsComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      data: [],
      pagination: {
        current: 1,
        pageSize: 10
      },
      loading: false,
      modalVisible: false,
      modalType: 'new',
      formData: null,
      searchValue: '',
      filter: { namespace: 'app', language: 'en' },
      tab: 'app',
      language: 'en'
    };

    this.fetch = this.fetch.bind(this);
  }

  componentDidMount(): void {
    document.title = `${process.env.REACT_APP_NAME} | Translations`;

    const { pagination } = this.state;
    this.fetch({ pagination });
  }

  fetch = (params: any = {}) => {
    this.setState({ loading: true });
    const filter: any = { where: this.state.filter };
    if (this.state.searchValue !== '') {
      filter.where = { and: [this.state.filter], or: [] };
      const obj: any = {};
      obj['value'] = { like: `%25${this.state.searchValue}%25` };
      // obj['key'] = { like: `%25${this.state.searchValue}%25` };
      filter.where.or.push(obj);
    } else {
      delete filter['where']['or'];
    }

    new LoopBack(this.props.auth.user)
      .get(`/translations?filter=${JSON.stringify(filter)}`)
      .then((data) => {
        this.setState({
          loading: false,
          data: data,
          pagination: {
            ...params.pagination,
            total: data.length
          }
        });
      });
  };

  delete = (rowData: any) => {
    Swal.fire({
      title: 'Remove item',
      text: `Are you sure you want to remove this translation?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        new LoopBack(this.props.auth.user)
          .delete(`/translations/${rowData.id}`)
          .then((res) => {
            const { pagination } = this.state;
            this.fetch({ pagination });
            Swal.fire('Deleted!', `This translation has been deletes`, 'success');
          })
          .catch((res) => {
            Swal.fire({
              title: 'Oops...',
              html: `Something went wrong while delete this translation`,
              icon: 'error'
            });
          });
      }
    });
  };

  handleTableChange = (pagination: any, filters: any, sorter: any) => {
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters
    });
  };

  onHide = () => {
    const { pagination } = this.state;
    this.fetch({ pagination });
    this.setState({ modalVisible: false });
  };

  onSearch = (e: any) => {
    this.setState({ searchValue: e.target.value }, () => {
      const { pagination } = this.state;
      this.fetch({ pagination: { current: 1, pageSize: pagination.pageSize } });
    });
  };

  onTabChange = (e: string) => {
    this.setState(
      { tab: e, filter: { namespace: e, language: this.state.language }, searchValue: '' },
      () => {
        const { pagination } = this.state;
        this.fetch({ pagination });
      }
    );
  };

  handleLanguageChange = (e: any) => {
    this.setState(
      { language: e.target.value, filter: { namespace: this.state.tab, language: e.target.value } },
      () => {
        const { pagination } = this.state;
        this.fetch({ pagination });
      }
    );
  };

  getTable = () => {
    const columns = [
      { title: 'Key', dataIndex: 'key', sorter: true },
      { title: 'Value', dataIndex: 'value', sorter: true },
      {
        title: 'Actions',
        width: 100,
        dataIndex: '',
        render: (rowData: any) => (
          <Space>
            <Button
              type={'primary'}
              onClick={() =>
                this.setState({ modalVisible: true, modalType: 'edit', formData: rowData })
              }
            >
              <EditOutlined /> {window.outerWidth > 768 && <span>Edit</span>}
            </Button>
            <Button onClick={() => this.delete(rowData)} type={'primary'}>
              <DeleteOutlined /> {window.outerWidth > 768 && <span>Delete</span>}
            </Button>
          </Space>
        )
      }
    ];
    const { data, pagination, loading } = this.state;

    return (
      <>
        <Row>
          <Col xs={24} sm={12}>
            <Button
              style={{ marginRight: 10 }}
              className={'ant-btn-add-item'}
              type={'primary'}
              onClick={() =>
                this.setState({ modalType: 'new', modalVisible: true, formData: null })
              }
            >
              <PlusOutlined /> Add Translation
            </Button>
            <Radio.Group onChange={this.handleLanguageChange} defaultValue={this.state.language}>
              {settings.languages.map((language) => {
                return <Radio.Button value={language.code}>{language.name}</Radio.Button>;
              })}
            </Radio.Group>
          </Col>
          <Col xs={{ span: 24, offset: 0 }} sm={{ span: 12 }}>
            <Input
              style={{ marginBottom: 10 }}
              placeholder={'Enter a search value...'}
              addonBefore={'Search'}
              onChange={this.onSearch}
            />
          </Col>
        </Row>
        <Table
          columns={columns}
          rowKey={(record: any) => record.id}
          dataSource={data}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
        />
      </>
    );
  };

  render() {
    return (
      <Layout className={'app-container'}>
        <NavbarComponent />
        <Layout className='site-layout'>
          <SiderComponent />
          <Content>
            <div className={'app-container-body'}>
              <div className={'app-container-content'}>
                <div className={'app-container-content-title'}>Manage Translations</div>
                <Tabs defaultActiveKey='1' type='card' onChange={this.onTabChange}>
                  <TabPane tab='General' key='app'>
                    {this.getTable()}
                  </TabPane>
                  <TabPane tab='Authentication' key='auth'>
                    {this.getTable()}
                  </TabPane>
                  <TabPane tab='Products' key='products'>
                    {this.getTable()}
                  </TabPane>
                  <TabPane tab='Pages' key='pages'>
                    {this.getTable()}
                  </TabPane>
                  {settings.events.enable && (
                    <TabPane tab='Events' key='events'>
                      {this.getTable()}
                    </TabPane>
                  )}
                  <TabPane tab='Cart' key='cart'>
                    {this.getTable()}
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </Content>
          <FormModal
            name={'Translation'}
            url={'/translations'}
            data={this.state.formData}
            type={this.state.modalType}
            visible={this.state.modalVisible}
            hide={this.onHide}
            form={TranslationForm}
          />
        </Layout>
        <FooterComponent />
      </Layout>
    );
  }
}

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(TranslationsComponent);
