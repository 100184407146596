import * as React from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

export class StatusIcon {
  public render = (active: boolean) => {
    const bgColor = active ? 'bg-success' : 'bg-alert';

    return {
      props: { className: bgColor },
      children: (
        <div
          style={{
            backgroundColor: bgColor,
            color: '#ffffff'
          }}
        >
          {active ? <CheckOutlined /> : <CloseOutlined />}
        </div>
      )
    };
  };
}

export default StatusIcon;
