import { Form, Input } from 'antd';
import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { AuthState } from '../../redux/states/user';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import FormImageSelectorComponent from '../../components/forms/form-image-selector.component';
import { LoopbackFile } from '../../types';

interface Props {
  auth: AuthState;
  field: any;
  formRef: any;
  language: string;
  isSaving: boolean;
}

const UpcomingEventsModule = (props: Props) => {
  const [fileList, setFileList] = useState<LoopbackFile[]>([]);


    return (
      <>
        <Form.Item
          label='Title'
          name={[props.field.name, 'settings', 'title', props.language]}
          rules={[{ required: false }]}
        >
          <Input placeholder='Title of this block' />
        </Form.Item>
        <Form.Item
          label='Sub-title'
          name={[props.field.name, 'settings', 'description', props.language]}
          rules={[{ required: false }]}
        >
          <ReactQuill theme='snow' />
        </Form.Item>
        <Form.Item
          label='Background color'
          name={[props.field.name, 'settings', 'background_color']}
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Background image'
          name={[props.field.name, 'settings', 'background_image']}
          rules={[{ required: false }]}
        >
          <FormImageSelectorComponent
            showAddon={true}
            preloadedFileList={fileList}
            callback={(fileList: LoopbackFile[]) => setFileList(fileList)}
            formRef={props.formRef}
            rootKey={'sections'}
            subKeys={[props.field.name, 'settings']}
            formKey={'background_image'}
          />
        </Form.Item>
        <Form.Item
          label='Button text'
          name={[props.field.name, 'settings', 'button', 'text', props.language]}
          rules={[{ required: false }]}
        >
          <Input placeholder='Book Now' />
        </Form.Item>

        <Form.Item
          label='Button url'
          name={[props.field.name, 'settings', 'button', 'url']}
          rules={[{ required: false }]}
        >
          <Input addonBefore={process.env.REACT_APP_WEB_URL} />
        </Form.Item>
      </>
    );
  
}

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(UpcomingEventsModule);
