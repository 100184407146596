import React, { useEffect, useState } from 'react';
import HeaderModule from '../modules/header.module';
import { GenderModule } from '../modules/gender.module';
import { HowItWorksModule } from '../modules/how-it-works.module';
import { ParagraphModule } from '../modules/paragraph.module';
import HighlightedProductsModule from '../modules/highlighted-products.module';
import ImageWallModule from '../modules/image-wall.module';
import { CollapsedListItemsModule } from '../modules/collapsed-list-items.module';
import ColumnLayoutModule from '../modules/column-layout.module';
import WebshopModule from '../modules/webshop.module';
import BannerModule from '../modules/banner.module';
import SliderModule from '../modules/slider.module';
import SliderPartnersModule from '../modules/slider-partners.module';
import UpcomingEventsModule from '../modules/upcoming-events.module';
import PhotoGalleryModule from '../modules/photo-gallery.module';
import { TwoColoredBlocksModule } from '../modules/two-colored-blocks.module';
import TextWithPictureBlockModule from '../modules/text-with-picture-block.module';
import GiftCardsModule from '../modules/gift-cards.module';
import UpcomingProductsModule from '../modules/upcoming-products.module';
import DonationModule from '../modules/donation.module';
import ImagesModule from '../modules/images.module';
import { LinkTreeModule } from '../modules/linktree.module';
import EventWaitingListModule from '../modules/event-waiting-list.module';
import LookbooksModule from '../modules/lookbooks.module';
import GridModule from '../modules/grid.module';
import InstagramFeedModule from '../modules/instagram-feed.module';
import NewsletterModule from '../modules/newsletter.module';
import SliderVideoModule from '../modules/slider-video.module';

interface Props {
  formRef: any;
  field: any;
  object: any;
  selectedModules: any;
  language: string;
  isSaving: boolean;
}

const SectionFactory = (props: Props) => {
  const data = props.selectedModules[props.field.key];
  const [stepsCount, setStepsCount] = useState<{ [key: string]: number } | null>(null);
  const [type, setType] = useState<string | null>(null);

  useEffect(() => {
    setType(data && data.type ? data.type : null);
  }, [props.selectedModules]);

  const updateSteps = (steps: number) => {
    const field = props.field;
    if (props.formRef && props.formRef.current) {
      let currentCount = stepsCount;
      if (currentCount === null) {
        currentCount = {};
      }
      currentCount[field.key] = steps;
      setStepsCount(currentCount);
    }
  };

  const getModule = (type: string) => {
    const field = props.field;
    const formRef = props.formRef;
    const language = props.language;

    switch (type) {
      case 'header':
        return (
          <HeaderModule
            field={field}
            formRef={formRef}
            language={language}
            isSaving={props.isSaving}
          />
        );
      case 'gender_block':
        return <GenderModule name={field.name} formRef={formRef} />;
      case 'two_colored_blocks':
        return (
          <TwoColoredBlocksModule
            isSaving={props.isSaving}
            name={field.name}
            language={language}
            formRef={formRef}
            field={field}
          />
        );
      case 'usps':
        return <></>;
      case 'how_it_works':
        const numOfStepsSettings = props.selectedModules[field.key];
        let numOfSteps =
          numOfStepsSettings && numOfStepsSettings.settings
            ? numOfStepsSettings.settings.num_of_steps
            : 3;
        const currentCount = stepsCount;
        if (currentCount && currentCount[field.key]) {
          numOfSteps = currentCount[field.key];
        }
        return (
          <HowItWorksModule
            field={field}
            formRef={formRef}
            numOfSteps={numOfSteps}
            onUpdateSteps={(value: number) => updateSteps(value)}
            language={language}
          />
        );
      case 'centered_paragraph':
        return (
          <ParagraphModule
            name={field.name}
            formRef={formRef}
            language={language}
            isSaving={props.isSaving}
          />
        );
      case 'grid':
        return (
          <GridModule
            field={field}
            formRef={formRef}
            language={language}
            isSaving={props.isSaving}
          />
        );
      case 'lookbooks':
        return <LookbooksModule field={field} formRef={formRef} language={language} />;
      case 'paragraph':
        return (
          <ParagraphModule
            name={field.name}
            formRef={formRef}
            language={language}
            isSaving={props.isSaving}
          />
        );
      case 'instagram_feed':
        return <InstagramFeedModule isSaving={props.isSaving} formRef={formRef} field={field} language={language} />;
      case 'highlighted_products':
        return <HighlightedProductsModule object={props.object} formRef={formRef} field={field} language={language} />;
      case 'slider_partners':
        return <SliderPartnersModule field={field} formRef={formRef} language={language} />;
      case 'slider_videos':
        return <SliderVideoModule field={field} formRef={formRef} language={language} />;
      case 'images':
        return <ImagesModule field={field} formRef={formRef} language={language} />;
      case 'slider':
        return <SliderModule field={field} formRef={formRef} language={language} />;
      case 'donation_module':
        return (
          <DonationModule
            field={field}
            formRef={formRef}
            language={language}
            isSaving={props.isSaving}
          />
        );
      case 'image_wall':
        return <ImageWallModule field={field} formRef={formRef} />;
      case 'upcoming_events':
        return (
          <UpcomingEventsModule
            field={field}
            formRef={formRef}
            language={language}
            isSaving={props.isSaving}
          />
        );
      case 'upcoming_products':
        return (
          <UpcomingProductsModule
            field={field}
            formRef={formRef}
            language={language}
            isSaving={props.isSaving}
          />
        );
      case 'gift_cards':
        return (
          <GiftCardsModule
            field={field}
            formRef={formRef}
            language={language}
            isSaving={props.isSaving}
          />
        );
      case 'photo_gallery':
        return (
          <PhotoGalleryModule
            field={field}
            formRef={formRef}
            language={language}
            isSaving={props.isSaving}
          />
        );
      case 'link_tree':
        return <LinkTreeModule field={field} formRef={formRef} />;
      case 'collapsed_list_items':
        return <CollapsedListItemsModule field={field} isSaving={props.isSaving} />;
      case 'text_with_picture_block':
        return (
          <TextWithPictureBlockModule
            field={field}
            formRef={formRef}
            language={language}
            isSaving={props.isSaving}
          />
        );
      case 'column_layout':
        return (
          <ColumnLayoutModule
            field={field}
            formRef={formRef}
            language={language}
            isSaving={props.isSaving}
          />
        );
      case 'webshop':
        return (
          <WebshopModule
            object={props.object}
            field={field}
            formRef={formRef}
            language={language}
          />
        );
      case 'banner':
        return <BannerModule field={field} formRef={formRef} language={language} />;
      case 'waiting_list_signup':
        return (
          <EventWaitingListModule
            field={field}
            formRef={formRef}
            language={language}
            isSaving={props.isSaving}
          />
        );
      case 'newsletter_signup':
        return (
          <NewsletterModule
            field={field}
            formRef={formRef}
            language={language}
            isSaving={props.isSaving}
          />
        );
      default:
        return <></>;
    }
  };

  return type ? getModule(type) : <></>;
};

export default SectionFactory;
