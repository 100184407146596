import React, { Component } from 'react';
import { Form, Input, InputNumber, Select } from 'antd';
import FormHeaderComponent from '../components/forms/form-header.component';
import { formItemLayout } from '../components/forms/form-layouts.component';
import { Country, Currency, PaymentMethod } from '../types';
import { AuthState } from '../redux/states/user';
import { connect } from 'react-redux';
import { setFormData, updateFormData } from '../helpers/form.helper';
import { FormInstance } from 'antd/es/form';
import { LoopBack } from '../redux/api';
import { settings } from '../../settings';

interface Props {
  auth: AuthState;
  type: string;
  visible: boolean;
  object?: Country | null;
  isSaving: boolean;
  formRef: React.RefObject<FormInstance>;
}

interface State {
  confirmLoading: boolean;
  currencies: Currency[];
  paymentMethods: PaymentMethod[];
}

class CountryForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      confirmLoading: false,
      currencies: [],
      paymentMethods: []
    };

    new LoopBack(this.props.auth.user)
      .get('/payment-methods')
      .then((res) => {
        this.setState({ paymentMethods: res });
      })
      .catch((err) => {});

    new LoopBack(this.props.auth.user)
      .get('/currencies')
      .then((res) => {
        this.setState({ currencies: res });
      })
      .catch((err) => {});
  }

  componentDidMount(): void {
    setFormData(this.props, this.props.object, this.props.formRef);
  }

  componentWillUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any) {
    updateFormData(this, nextProps);
  }

  render() {
    const color = this.props.object;

    return (
      <>
        <FormHeaderComponent
          type={this.props.type}
          newTitle={color ? '' : 'Add a new Country'}
          editTitle={color ? `Edit ${color.name}` : ''}
        />

        <Form {...formItemLayout} ref={this.props.formRef} name='basic'>
          <Form.Item
            label='Name'
            name='name'
            rules={[{ required: true, message: 'Please enter a name' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Tax Rate'
            name='tax_rate'
            rules={[{ required: true, message: 'Please enter a rate', type: 'number' }]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            extra={
              'Fill in the value in capital letters. This feature will help to auto-detect the users country on the website'
            }
            label='Two-letter country code'
            name='code'
            rules={[{ required: true, message: 'Please enter the two letter country code' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            extra={
              'This currency will automatically be selected for the customer when a specific country is chosen on the website'
            }
            label={'Preferred Currency'}
            name={'preferred_currency'}
            rules={[{ required: true, message: 'Please select a currency' }]}
          >
            <Select placeholder={'Select a currency'}>
              {this.state.currencies.map((currency) => {
                return (
                  <Select.Option key={currency.id} value={currency.code}>
                    {currency.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            extra={
              'This language will automatically be selected for the customer when a specific country is chosen on the website'
            }
            label={'Preferred Language'}
            name={'preferred_language'}
            rules={[{ required: true, message: 'Please select a language' }]}
          >
            {settings.languages && (
              <Select placeholder={'Select a language'}>
                {settings.languages.map((language) => {
                  return <Select.Option value={language.code}>{language.name}</Select.Option>;
                })}
              </Select>
            )}
          </Form.Item>

          <Form.Item
            label={'Preferred Measurement unit'}
            name={'preferred_measurement_unit'}
            rules={[{ required: true, message: 'Please select a measurement unit' }]}
          >
            <Select placeholder={'Select a measurment unit'}>
              <Select.Option value={'imperial'}>Imperial</Select.Option>
              <Select.Option value={'metric'}>Metric</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            extra={
              'Enter the amount without tax and in the currency chosen under preferred currency'
            }
            label='Standard Shipping costs'
            name='default_shipping_costs'
            rules={[
              {
                required: true,
                message: 'Please enter the standard shipping costs to this country',
                type: 'number'
              }
            ]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            extra={
              'Enter the amount without tax and in the currency chosen under preferred currency'
            }
            label='Tracked Shipping costs'
            name='tracked_shipping_costs'
            rules={[
              {
                required: true,
                message: 'Please enter the tracked shipping costs to this country',
                type: 'number'
              }
            ]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            extra={
              'Enter the amount of the total order including tax after which the shipping costs will be 0'
            }
            label='Shipping costs limit'
            name='shipping_costs_limit'
            rules={[
              {
                required: true,
                message: 'Please enter the shipping costs to this country',
                type: 'number'
              }
            ]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            label={'Payment Methods'}
            name={'payment_methods'}
            rules={[{ required: true, message: 'Please select at least one method' }]}
          >
            <Select mode={'multiple'} placeholder={'Select payment methods'}>
              {this.state.paymentMethods.map((paymentMethod) => {
                return (
                  <Select.Option key={paymentMethod.id} value={paymentMethod.type}>
                    {paymentMethod.description}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(CountryForm);
