import { revenueChart, salesChart } from './charts-build.helper';

interface chartDataType {
  month: string;
  sold: number;
  sum: number;
}

export const initSalesChart = (data: chartDataType[]) => {
  return salesChart(data);
};

export const initRevenueCart = (data: chartDataType[]) => {
  return revenueChart(data);
};
