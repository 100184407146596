import { SoftwareState } from '../states/software';
import {
  SOFTWARE_CLEAR_CANVAS,
  SOFTWARE_SET_DRAW_PROPERTIES,
  SOFTWARE_SET_FILL_COLOR,
  SOFTWARE_SET_LOCK_CANVAS,
  SOFTWARE_SET_POLYGON_SIDES,
  SOFTWARE_SET_STROKE_CAP,
  SOFTWARE_SET_STROKE_COLOR,
  SOFTWARE_SET_STROKE_JOIN,
  SOFTWARE_SET_STROKE_WIDTH,
  SOFTWARE_SET_TOOL,
  SOFTWARE_UPDATE_CANVAS,
  SOFTWARE_UPDATE_SELECTED_OBJECT
} from '../types';

export default function software(state = SoftwareState, action: any) {
  switch (action.type) {
    case SOFTWARE_CLEAR_CANVAS:
      return {
        ...state
      };
    case SOFTWARE_UPDATE_CANVAS:
      return {
        ...state,
        paper: action.payload
      };
    case SOFTWARE_SET_TOOL:
      return {
        ...state,
        action: action.payload.action,
        tool: action.payload.tool
      };
    case SOFTWARE_UPDATE_SELECTED_OBJECT:
      return {
        ...state,
        selectedObject: action.payload
      };
    case SOFTWARE_SET_DRAW_PROPERTIES:
      const properties = action.payload;
      return {
        ...state,
        fillColor: properties.fillColor,
        strokeColor: properties.strokeColor,
        strokeWidth: properties.strokeWidth,
        dasharray: properties.dasharray,
        strokeJoin: properties.strokeJoin,
        strokeCap: properties.strokeCap
      };
    case SOFTWARE_SET_FILL_COLOR:
      return {
        ...state,
        fillColor: action.payload.fillColor
      };
    case SOFTWARE_SET_STROKE_COLOR:
      return {
        ...state,
        strokeColor: action.payload.strokeColor
      };
    case SOFTWARE_SET_STROKE_WIDTH:
      return {
        ...state,
        strokeWidth: action.payload.strokeWidth
      };
    case SOFTWARE_SET_STROKE_JOIN:
      return {
        ...state,
        strokeJoin: action.payload.strokeJoin
      };
    case SOFTWARE_SET_STROKE_CAP:
      return {
        ...state,
        strokeCap: action.payload.strokeCap
      };
    case SOFTWARE_SET_POLYGON_SIDES:
      return {
        ...state,
        polygonSides: action.payload
      };
    case SOFTWARE_SET_LOCK_CANVAS:
      return {
        ...state,
        lockCanvas: action.payload
      };
    case 'persist/REHYDRATE':
      if (action.key === 'root' && action.payload) {
        return {
          ...state,
          selectedObject: null
        };
      }

      return {
        ...state,
        selectedObject: null
      };
    // return state;
    default:
      return state;
  }
}
