export const updateFormData = (that: any, nextProps: any) => {
  const currentProps = that.props;

  if (nextProps.visible && !currentProps.visible) {
    const formRef = nextProps.formRef;
    const data = nextProps.object;

    setFormData(nextProps, data, formRef);
    const selectedModules =
      nextProps.object && nextProps.object.sections ? nextProps.object.sections : [];
    that.setState({ selectedModules: selectedModules });
  }
};

export const setFormData = (currentProps: any, data: any, formRef: any) => {
  const actionType = currentProps.type;

  if (formRef) {
    const currentForm = formRef.current;
    if (currentForm && actionType === 'edit' && data) {
      currentForm.resetFields();
      currentForm.setFieldsValue(data);
    } else if (actionType === 'new') {
      currentForm.resetFields();
    }
  }
};

export const moveItem = (arr: any[], itemIndex: number, targetIndex: number) => {
  if (arr && arr.length > 0) {
    let itemRemoved = arr.splice(itemIndex, 1); // splice() returns the remove element as an array
    arr.splice(targetIndex, 0, itemRemoved[0]); // Insert itemRemoved into the target index
    return arr;
  }
  return arr;
};

export const randomId = () => {
  return [...Array(30)].map(() => Math.random().toString(36)[2]).join('');
};

export const stripHtml = (value: string) => {
  console.log(value.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, ''));
  return value.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, '');
};
