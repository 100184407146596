import { RoleTypes } from '../../helpers/role.helper';

export interface User {
  token: string;
  roles: RoleTypes[];
}

export interface AuthState {
  user: User;
  isLoggedIn: boolean;
  loading: boolean;
}

export const UserState: AuthState = {
  user: { token: '', roles: [] },
  isLoggedIn: false,
  loading: false
};
