import { Button, Col, Form, Input, Row } from 'antd';
import React, { useState } from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { formItemLayout } from '../../components/forms/form-layouts.component';
import 'react-quill/dist/quill.snow.css';
import FormImageSelectorComponent from '../../components/forms/form-image-selector.component';
import { LoopbackFile } from '../../types';

interface Props {
  field: any;
  formRef: any;
}

export const LinkTreeModule = (props: Props) => {
  const [fileList, setFileList] = useState<LoopbackFile[]>([]);

  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 22, offset: 0 },
      sm: { span: 16, offset: 8 }
    }
  };

  const subFormItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 19 }
    }
  };

  return (
    <>
      <Form.Item
        label='Title'
        name={[props.field.name, 'settings', 'title']}
        rules={[{ required: false }]}
      >
        <Input placeholder='Title of this block' />
      </Form.Item>
      <Form.Item
        label='Sub-title'
        name={[props.field.name, 'settings', 'subtitle']}
        rules={[{ required: false }]}
      >
        <Input placeholder='Sub-Title of this block' />
      </Form.Item>
      <Form.Item
        extra={'Enter a hex value (i.e. #EEEEEE) or rgba value (i.e. rgba(234,134,255,0.5))'}
        label='Background color'
        name={[props.field.name, 'settings', 'background_color']}
        rules={[{ required: false }]}
      >
        <Input placeholder={'#EEEEEE'} />
      </Form.Item>
      <Form.Item
        extra={'Note: This will override any background color'}
        label='Background image'
        name={[props.field.name, 'settings', 'background_image']}
        rules={[{ required: false }]}
      >
        <FormImageSelectorComponent
          showAddon={true}
          preloadedFileList={fileList}
          callback={(fileList: LoopbackFile[]) => setFileList(fileList)}
          formRef={props.formRef}
          rootKey={'sections'}
          subKeys={[props.field.name, 'settings']}
          formKey={'background_image'}
        />
      </Form.Item>
      <Form.Item
        extra={'Enter a hex value (i.e. #EEEEEE) or rgba value (i.e. rgba(234,134,255,0.5))'}
        label='Button color'
        name={[props.field.name, 'settings', 'button_color']}
        rules={[{ required: false }]}
      >
        <Input placeholder={'#EEEEEE'} />
      </Form.Item>
      <Form.List name={[props.field.name, 'settings', 'items']}>
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map((field, index) => (
                <Form.Item
                  {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                  label={index === 0 ? 'Items' : ''}
                  required={false}
                  key={field.key}
                >
                  <Row className={'form-sub-section'}>
                    <Col xs={22}>
                      <Row>
                        <Col xs={24}>
                          <Form.Item
                            {...subFormItemLayout}
                            name={[field.name, 'title']}
                            label='Title'
                            rules={[{ required: true, message: 'Enter a title for this item' }]}
                          >
                            <Input placeholder='Title of this item' />
                          </Form.Item>
                        </Col>
                        <Col xs={24}>
                          <Form.Item
                            {...subFormItemLayout}
                            name={[field.name, 'url']}
                            label='Url'
                            extra={'The url has to start with https://'}
                            rules={[{ required: true, message: 'Enter a url for this item' }]}
                          >
                            <Input placeholder='https://youtube.com/my-youtube-link' />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={2}>
                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          className='dynamic-delete-button'
                          style={{ margin: '0 8px' }}
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      ) : null}
                    </Col>
                  </Row>
                </Form.Item>
              ))}

              <Row>
                {fields.length === 0 && (
                  <Col className={'placeholder-label'} xs={24} sm={8}>
                    <span>Items:</span>
                  </Col>
                )}

                <Col xs={24} sm={{ span: 16, offset: fields.length === 0 ? 0 : 8 }}>
                  <Form.Item>
                    <Button
                      type='primary'
                      onClick={() => {
                        add();
                      }}
                      style={{ width: '60%' }}
                    >
                      <PlusOutlined /> Add Item
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          );
        }}
      </Form.List>
    </>
  );
};
