import { Chart } from '@antv/g2';
import { formatPrice } from './price.helper';

export const salesChart = (data: any) => {
  const chart = new Chart({
    container: 'c1',
    autoFit: true,
    height: 300
  });
  chart.clear();
  chart.data(data);
  chart.interval().position('month*sold');
  chart.render();

  return chart;
};

export const revenueChart = (data: any) => {
  const revenueChart = new Chart({
    container: 'c2',
    height: 300,
    autoFit: true
  });

  revenueChart.data(data);
  revenueChart.scale({
    month: {
      range: [0, 1]
    },
    sum: {
      min: 0,
      nice: true
    }
  });

  revenueChart.tooltip({
    showCrosshairs: true,
    shared: true
  });

  revenueChart
    .line()
    .position('month*sum')
    .label('sum', { content: (e) => formatPrice(e.sum) });
  revenueChart.point().position('month*sum');
  revenueChart.render();

  return revenueChart;
};
