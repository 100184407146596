import React, { useEffect } from 'react';
import PublicRoutes from './router';
import { ConfigProvider, message } from 'antd';
import enGB from 'antd/es/locale/en_GB';
import { AuthState } from './core-module/redux/states/user';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { errorBoundry } from './core-module/components/error-boundary';
import jwtDecode from 'jwt-decode';
import { Token } from './core-module/types';
import { logoutUser } from './core-module/redux/actions/user';
import { BrowserRouter } from 'react-router-dom';
import moment from 'moment';
import { AppState } from './core-module/redux/states/app';

interface Props {
  app: AppState;
  auth: AuthState;
  dispatch: Dispatch;
}

const App = (props: Props) => {
  useEffect(() => {
    verifyLoginToken();
    const interval = setInterval(() => {
      verifyLoginToken();
    }, 3000);

    return () => clearInterval(interval);
  }, [props.auth]);

  const verifyLoginToken = () => {
    if (props.auth.isLoggedIn) {
      if (isTokenExpired()) {
        props.dispatch(logoutUser());
        message.info('Your login session has expired. Please login again');
      }
    }
  };

  const isTokenExpired = () => {
    const { auth } = props;
    const containsInvalidUserObject = auth.user && Object.keys(auth.user).length === 0;

    let isExpired = false;

    if (auth.isLoggedIn && !containsInvalidUserObject) {
      const token: Token = jwtDecode(auth.user.token);
      const now = moment();
      isExpired = moment.unix(token.exp).isBefore(now);
    }

    return (auth.isLoggedIn && isExpired) || containsInvalidUserObject;
  };

  return (
    <ConfigProvider locale={enGB}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <PublicRoutes />
      </BrowserRouter>
    </ConfigProvider>
  );
};

const mapStateToProps = (state: any) => ({
  app: state.app,
  auth: state.auth
});

export default connect(mapStateToProps)(errorBoundry(App));
