import React, { Component } from 'react';
import TableComponent from './table.component';
import CountryForm from '../../forms/country.form';
import { Layout, Spin, Tag } from 'antd';
import { LoopBack } from '../../redux/api';
import { connect } from 'react-redux';
import { AuthState } from '../../redux/states/user';
import { Country, PaymentMethod } from '../../types';
import NavbarComponent from '../navigation/navbar.component';
import SiderComponent from '../navigation/sider.component';

const { Content } = Layout;

interface Props {
  auth: AuthState;
}

interface State {
  loading: boolean;
  paymentMethods: PaymentMethod[];
}

class CountriesComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      paymentMethods: []
    };

    new LoopBack(this.props.auth.user)
      .get('/payment-methods')
      .then((res) => {
        this.setState({ paymentMethods: res, loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  }

  getPaymentMethod = (type: string): PaymentMethod | null => {
    const paymentMethod = this.state.paymentMethods.filter((method) => method.type === type);
    if (paymentMethod) {
      return paymentMethod[0];
    }
    return null;
  };

  render() {
    const columns = [
      { title: 'Name', dataIndex: 'name', sorter: true },
      { title: 'Code', dataIndex: 'code', sorter: true, responsive: ['xl'] },
      {
        title: 'Currency',
        dataIndex: 'preferred_currency',
        sorter: true,
        responsive: ['xl']
      },
      {
        title: 'Units',
        dataIndex: 'preferred_measurement_unit',
        sorter: true,
        responsive: ['xl']
      },
      {
        title: 'Language',
        dataIndex: 'preferred_language',
        sorter: true,
        responsive: ['xl']
      },
      {
        title: 'Payment methods',
        dataIndex: 'payment_methods',
        render: (methods: string[]) =>
          methods.map((methodType) => {
            const paymentMethod = this.getPaymentMethod(methodType);
            if (paymentMethod) {
              return (
                <Tag className={'payment-tag'} key={paymentMethod.id}>
                  <img alt={paymentMethod.description} src={paymentMethod.image} />{' '}
                  {paymentMethod.description}
                </Tag>
              );
            }
            return undefined;
          }),
        responsive: ['sm']
      },
      {
        width: 180,
        title: 'Shipping costs',
        dataIndex: '',
        sorter: true,
        responsive: ['lg'],
        render: (x: Country) => (
          <div
            dangerouslySetInnerHTML={{
              __html: `${x.preferred_currency} ${x.default_shipping_costs} | ${x.preferred_currency} ${x.tracked_shipping_costs}`
            }}
          />
        )
      },
      {
        title: 'Shipping costs limit',
        dataIndex: '',
        sorter: true,
        responsive: ['lg'],
        render: (x: Country) => (
          <div
            dangerouslySetInnerHTML={{
              __html: `${x.preferred_currency} ${x.shipping_costs_limit}`
            }}
          />
        )
      },
      {
        title: 'Tax rate',
        dataIndex: 'tax_rate',
        sorter: true,
        responsive: ['lg'],
        render: (rate: number) => rate + '%'
      }
    ];

    return (
      <>
        {this.state.loading ? (
          <Layout className={'app-container'}>
            <NavbarComponent />
            <Layout className='site-layout'>
              <SiderComponent />
              <Content>
                <div className={'app-container-body'}>
                  <div className={'app-container-content'}>
                    <div className={'app-container-content-title'}>Manage Countries</div>
                    <Spin spinning={true} /> Loading payment methods...
                  </div>
                </div>
              </Content>
            </Layout>
          </Layout>
        ) : (
          <TableComponent
            form={CountryForm}
            url={'/countries'}
            filter={{}}
            name={'Country'}
            title={'Countries'}
            columns={columns}
            searchFields={['code', 'name']}
            actions={['view', 'add', 'edit', 'delete']}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(CountriesComponent);
