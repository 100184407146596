import React, { useEffect, useState } from 'react';
import { logoutUser } from '../../redux/actions/user';
import { connect } from 'react-redux';
import { Dropdown, Layout, Menu } from 'antd';
import { DownOutlined, KeyOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { AuthState } from '../../redux/states/user';
import jwtDecode from 'jwt-decode';
import { Token } from '../../types';
import FormModal from '../forms/form-modal.component';
import ChangePasswordForm from '../../forms/change-password.form';
import '../../styles/header.less';
import { useNavigate } from 'react-router-dom';

const { Header } = Layout;

interface Props {
  auth: AuthState;
  logoutUser: typeof logoutUser;
}

const NavbarComponent = (props: Props) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!props.auth.isLoggedIn) {
      navigate('/', { replace: true });
    }
  }, [props.auth.isLoggedIn]);

  const menu = () => (
    <Menu>
      <Menu.Item onClick={() => setVisible(true)}>
        <KeyOutlined /> Change Password
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={() => props.logoutUser()}>
        <LogoutOutlined /> Logout
      </Menu.Item>
    </Menu>
  );

  const onHide = () => {
    setVisible(false);
  };

  const onSuccess = () => {
    props.logoutUser();
  };

  const token: Token | '' =
    props.auth.user && props.auth.user.token !== '' ? jwtDecode(props.auth.user.token) : '';

  return (
    <Header className='site-layout-background'>
      <div id={'cms-logo'}>
        <img
          alt={`Logo ${process.env.REACT_APP_NAME}`}
          src={require('../../../assets/logo-white.png')}
        />
      </div>
      <h1>CMS</h1>
      <div id={'nav-menu'}>
        <Dropdown overlay={menu()}>
          <span>
            <UserOutlined /> {token !== '' ? token.name : ''} <DownOutlined />
          </span>
        </Dropdown>
      </div>
      <FormModal
        name={'Password'}
        type={'new'}
        data={[]}
        onSuccess={onSuccess}
        description={'Password has been successfully updated. You will need to re-login.'}
        form={ChangePasswordForm}
        hide={onHide}
        visible={visible}
        url={'/admins/change-password'}
      />
    </Header>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

const mapDispatchToProps = (dispatch: any) => ({
  logoutUser: () => dispatch(logoutUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(NavbarComponent);
