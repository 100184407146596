import React, { createRef, useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Select, Space, Tabs } from 'antd';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DeleteOutlined,
  PlusOutlined
} from '@ant-design/icons';
import FormHeaderComponent from '../components/forms/form-header.component';
import { AuthState } from '../redux/states/user';
import { Page } from '../types';
import { FormInstance } from 'antd/es/form';
import { formItemLayout } from '../components/forms/form-layouts.component';
import ReactQuill from 'react-quill';
import { SelectValue } from 'antd/es/select';
import { moveItem, setFormData, stripHtml } from '../helpers/form.helper';
import { slugify, updateSlug } from '../helpers/slugify.helper';
import SectionFactory from './helpers/section-factory.helper';
import { settings } from '../../settings';
import 'react-quill/dist/quill.snow.css';

const { Option } = Select;
const { TabPane } = Tabs;

interface Props {
  auth: AuthState;
  type: string;
  visible: boolean;
  isSaving: boolean;
  object?: Page | null;
  formRef: React.RefObject<FormInstance>;
}

const PagesForm = (props: Props) => {
  const quillRef = createRef<ReactQuill>();
  const [slugIsValidating, setSlugIsValidating] = useState(false);
  const [slugExists, setSlugExists] = useState(false);
  const [selectedModules, setSelectedModules] = useState<any[]>(
    props.object && props.object.sections ? props.object.sections : []
  );
  const [slugError, setSlugError] = useState(false);
  const [updated, setUpdated] = useState(0);
  const [htmlValue, setHtmlValue] = useState<string>(props.object?.title ? '' : '');

  let toolbar: any = {
    toolbar: {
      container: [
        ['bold', 'italic'],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          {
            color: [
              '#000000',
              '#e60000',
              '#ff9900',
              '#ffff00',
              '#008a00',
              '#0066cc',
              '#9933ff',
              '#ffffff',
              '#facccc',
              '#ffebcc',
              '#ffffcc',
              '#cce8cc',
              '#cce0f5',
              '#ebd6ff',
              '#bbbbbb',
              '#f06666',
              '#ffc266',
              '#ffff66',
              '#66b966',
              '#66a3e0',
              '#c285ff',
              '#888888',
              '#a10000',
              '#b26b00',
              '#b2b200',
              '#006100',
              '#0047b2',
              '#6b24b2',
              '#444444',
              '#5c0000',
              '#663d00',
              '#666600',
              '#003700',
              '#002966',
              '#3d1466',
              'custom-color'
            ]
          }
        ]
      ],
      handlers: {
        color: (value: string | null) => {
          if (value === 'custom-color') {
            value = window.prompt('Enter Hex Color Code');
            if (quillRef && quillRef.current) {
              quillRef.current.getEditor().format('color', value);
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    setSlugError(false);
    setFormData(props, props.object, props.formRef);
    if (props.object && props.object.sections) {
      setSelectedModules(props.object.sections);
    } else {
      setSelectedModules([]);
    }
  }, [props.object]);

  const onModuleChange = (val: SelectValue, key: number) => {
    const currentSelection = selectedModules;
    currentSelection[key] = { type: val };
    setSelectedModules([...currentSelection]);
  };

  const verifySlug = (e: any) => {
    if (!props.object) {
      const inputValue = e.target.value.toLowerCase();
      let value = slugify(inputValue);

      if (value.charAt(0) !== '/') {
        value = '/' + value;
      }

      if (props.formRef && props.formRef.current) {
        props.formRef.current.setFieldsValue({ slug: value });
      }

      setSlugIsValidating(true);
      updateSlug(value, props.formRef, props.object)
        .then((res) => {
          setSlugIsValidating(false);
          setSlugExists(res.slugExists);
          setSlugError(false);
        })
        .catch((err) => {
          setSlugIsValidating(false);
          setSlugError(true);
        });
    }
  };

  const handleChange = (htmlValue: string) => {
    if (htmlValue !== '<p><br></p>') {
      setHtmlValue(htmlValue);
    } else {
      setHtmlValue('');
    }
  };

  const getForm = (language: string) => {
    return (
      <>
        <Form.Item
          label='Name'
          name={['name', language]}
          rules={[{ required: true, message: 'Please enter a name for the page' }]}
        >
          <Input onChange={language === settings.languages[0].code ? verifySlug : undefined} />
        </Form.Item>
        <Form.Item
          label='SEO Title'
          name={['title', language]}
          rules={[{ required: true, message: 'Please enter a title' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          extra={`Characters: ${stripHtml(htmlValue).length} (Best to have a min of ${
            settings.seoDescription.min
          } and max of ${settings.seoDescription.max})`}
          label='SEO Description'
          name={['description', language]}
          rules={[
            {
              required: true,
              message: `Please enter a description of minimal ${settings.seoDescription.min} characters`
            }
          ]}
        >
          <ReactQuill onChange={handleChange} ref={() => quillRef} modules={toolbar} theme='snow' />
        </Form.Item>

        <Form.Item
          label='Url'
          name='slug'
          hasFeedback={slugIsValidating}
          validateStatus={
            slugIsValidating ? 'validating' : slugExists || slugError ? 'error' : undefined
          }
          help={
            slugError
              ? 'Unable to check if this url is already in use'
              : slugExists
              ? 'This url seems to already exist. Please choose a different url'
              : undefined
          }
          rules={[{ required: true, message: 'Please enter a url' }]}
        >
          <Input
            addonBefore={process.env.REACT_APP_WEB_URL}
            onChange={language === settings.languages[0].code ? verifySlug : undefined}
          />
        </Form.Item>

        <Form.List name='sections'>
          {(fields, { add, remove, move }) => {
            return (
              <>
                {fields.map((field, index) => (
                  <Row className={'form-section'} key={field.key}>
                    <Space>
                      <Button
                        disabled={index === fields.length - 1}
                        onClick={() => {
                          move(index, index + 1);
                          if (props.object) {
                            const newSections = moveItem(props.object.sections, index, index + 1);
                            if (props.formRef && props.formRef.current) {
                              setSelectedModules(newSections);
                              setUpdated(updated + 1);
                            }
                          }
                        }}
                        icon={<ArrowDownOutlined />}
                      />

                      <Button onClick={() => remove(field.name)} icon={<DeleteOutlined />} />

                      <Button
                        disabled={index === 0}
                        onClick={() => {
                          move(index, index - 1);
                          if (props.object) {
                            const newSections = moveItem(props.object.sections, index, index - 1);
                            if (props.formRef && props.formRef.current) {
                              setSelectedModules(newSections);
                              setUpdated(updated + 1);
                            }
                          }
                        }}
                        icon={<ArrowUpOutlined />}
                      />
                    </Space>
                    <Col xs={{ span: 24 }}>
                      <Form.Item
                        label={'Section Type'}
                        name={[field.name, 'type']}
                        rules={[{ required: true }]}
                      >
                        <Select
                          onChange={(value) => onModuleChange(value, field.key)}
                          placeholder='Please select a section to add'
                        >
                          {settings.pages.modules.banner && <Option value='banner'>Banner</Option>}

                          {settings.pages.modules.centeredParagraph && (
                            <Option value='centered_paragraph'>Centered Paragraph</Option>
                          )}
                          {settings.pages.modules.grid && (
                            <Option value='grid'>Grid</Option>
                          )}
                          {settings.pages.modules.collapsedListItem && (
                            <Option value='collapsed_list_items'>Collapsed List Items</Option>
                          )}
                          {settings.pages.modules.columnLayout && (
                            <Option value='column_layout'>Column Layout</Option>
                          )}
                          {settings.pages.modules.donationModule && (
                            <Option value='donation_module'>Donation Module</Option>
                          )}
                          {settings.pages.modules.giftCards && (
                            <Option value='gift_cards'>Gift Cards</Option>
                          )}
                          {settings.pages.modules.header && <Option value='header'>Header</Option>}
                          {settings.pages.modules.highlightedProducts && (
                            <Option value='highlighted_products'>Highlighted Products</Option>
                          )}
                          {settings.pages.modules.howItWorks && (
                            <Option value='how_it_works'>How it works</Option>
                          )}
                          {settings.pages.modules.images && <Option value='images'>Images</Option>}
                          {settings.pages.modules.imageWall && (
                            <Option value='image_wall'>Image Wall</Option>
                          )}
                          {settings.pages.modules.linkTree && (
                            <Option value='link_tree'>Link Tree</Option>
                          )}
                          {settings.pages.modules.latestProducts && (
                            <Option value='upcoming_products'>Latest Products</Option>
                          )}
                          {settings.pages.modules.paragraph && (
                            <Option value='paragraph'>Paragraph</Option>
                          )}
                          {settings.pages.modules.photoGallery && (
                            <Option value='photo_gallery'>Photo gallery</Option>
                          )}
                          {settings.pages.modules.slider && <Option value='slider'>Slider</Option>}
                          {settings.pages.modules.sliderPartners && (
                            <Option value='slider_partners'>Slider Partners</Option>
                          )}
                          {settings.pages.modules.sliderVideos && (
                            <Option value='slider_videos'>Slider Videos</Option>
                          )}
                          {settings.pages.modules.textWithPictureBlock && (
                            <Option value='text_with_picture_block'>
                              Text with Shifted Picture Block
                            </Option>
                          )}
                          {settings.pages.modules.twoColoredBlocks && (
                            <Option value='two_colored_blocks'>Two Colored Blocks</Option>
                          )}
                          {settings.pages.modules.instagramFeed && (
                            <Option value='instagram_feed'>Instagram Feed</Option>
                          )}
                          {settings.pages.modules.upcomingEvents && (
                            <Option value='upcoming_events'>Upcoming Events</Option>
                          )}

                          {settings.pages.modules.lookbooks && (
                            <Option value='lookbooks'>Lookbooks</Option>
                          )}

                          {settings.pages.modules.waitingListSignUp && (
                            <Option value='waiting_list_signup'>Waiting list sign-up</Option>
                          )}

                          {settings.pages.modules.newsletterSignup && (
                            <Option value='newsletter_signup'>Newsletter sign-up</Option>
                          )}

                          {settings.pages.modules.webshop && (
                            <Option value='webshop'>Webshop</Option>
                          )}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        label={'Unique navbar ID'}
                        extra={
                          'WARNING: changing the value of his field may impact the styling of the website. Contact the developer to make sure it can be changed. If applicable, enter this ID under the settings of the navigation bar to make a link scroll to this section'
                        }
                        name={[field.name, 'uid']}
                        rules={[{ required: false }]}
                      >
                        <Input placeholder={'about-us'} />
                      </Form.Item>

                      <SectionFactory
                        object={props.object}
                        isSaving={props.isSaving}
                        formRef={props.formRef}
                        field={field}
                        selectedModules={selectedModules}
                        language={language}
                      />
                    </Col>
                  </Row>
                ))}

                <Row>
                  {fields.length === 0 && (
                    <Col className={'placeholder-label'} xs={24} sm={8}>
                      <span>Sections:</span>
                    </Col>
                  )}

                  <Col xs={24} sm={{ span: 16, offset: fields.length === 0 ? 0 : 8 }}>
                    <Form.Item>
                      <Button
                        type='primary'
                        onClick={() => {
                          add();
                        }}
                        style={{ width: '60%' }}
                      >
                        <PlusOutlined /> Add Section
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            );
          }}
        </Form.List>
      </>
    );
  };

  const page = props.object;
  const languages = settings.languages;

  return (
    <>
      <FormHeaderComponent
        type={props.type}
        newTitle={page ? '' : 'Add a new Page'}
        editTitle={page ? `Edit ${page.name[languages[0].code]}` : ''}
      />

      <Form {...formItemLayout} ref={props.formRef}>
        <Tabs defaultActiveKey={languages[0].code.toLowerCase() + updated}>
          {languages.map((language, index) => {
            return (
              <TabPane
                forceRender={true}
                tab={language.name}
                key={language.code.toLowerCase() + updated + index}
              >
                {getForm(language.code.toLowerCase())}
              </TabPane>
            );
          })}
        </Tabs>
      </Form>
    </>
  );
};

export default PagesForm;
