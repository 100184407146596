import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, InputNumber, Row, Select, Switch, Tabs } from 'antd';
import FormHeaderComponent from '../components/forms/form-header.component';
import {
  Color, LoopbackFile,
  Product,
  ProductCategory,
  ProductOccasion,
  ProductStyle,
  ProductTheme,
  ProductType,
  Size
} from '../types';
import { AuthState } from '../redux/states/user';
import { connect } from 'react-redux';
import { setFormData } from '../helpers/form.helper';
import { FormInstance } from 'antd/es/form';
import { LoopBack } from '../redux/api';
import ReactQuill from 'react-quill';
import { slugify, updateSlug } from '../helpers/slugify.helper';
import { SelectValue } from 'antd/es/select';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import FormImageSelectorComponent from '../components/forms/form-image-selector.component';
import { formItemLayout } from '../components/forms/form-layouts.component';
import { settings } from '../../settings';

const { TabPane } = Tabs;

const { Option, OptGroup } = Select;

interface Props {
  auth: AuthState;
  type: string;
  visible: boolean;
  object?: Product | null;
  isSaving: boolean;
  formRef: React.RefObject<FormInstance>;
  dataIsSet: boolean;
}

const ProductForm = (props: Props) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [productTypes, setProductTypes] = useState<ProductType[]>([]);
  const [productCategories, setProductCategories] = useState<ProductCategory[]>([]);
  const [productThemes, setProductThemes] = useState<ProductTheme[]>([]);
  const [productStyles, setProductStyles] = useState<ProductStyle[]>([]);
  const [productOccasions, setProductOccasions] = useState<ProductOccasion[]>([]);
  const [colors, setColors] = useState<Color[]>([]);
  const [sizes, setSizes] = useState<Size[]>([]);
  const [manageStock, setManageStock] = useState(props.object?.manage_stock);
  const [preOrder, setPreOrder] = useState(props.object?.enable_pre_order);

  const [customUrl, setCustomUrl] = useState(false);
  const [personalise, setPersonalise] = useState(false);
  const [enableCustomization, setEnableCustomization] = useState(false);
  const [chooseThreadColor, setChooseThreadColor] = useState(false);
  const [sizeType, setSizeType] = useState(props.object?.size_type);
  const [tags, setTags] = useState(props.object?.tags);

  const [slugIsValidating, setSlugIsValidating] = useState(false);
  const [slugExists, setSlugExists] = useState(false);
  const [slugError, setSlugError] = useState(false);

  const [productTypesError, setProductTypesError] = useState(false);
  const [productCategoriesError, setProductCategoriesError] = useState(false);
  const [productThemesError, setProductThemesError] = useState(false);
  const [productStylesError, setProductStylesError] = useState(false);
  const [productOccasionsError, setProductOccasionsError] = useState(false);
  const [colorsError, setColorsError] = useState(false);
  const [fileList, setFileList] = useState<LoopbackFile[]>([]);

  const updateCustomUrl = (e: boolean) => {
    if (props.formRef && props.formRef.current && props.type === 'new') {
      props.formRef.current.setFieldsValue({ slug: null });
    }
    setCustomUrl(e);
  };

  useEffect(() => {
    setFormData(props, props.object, props.formRef);

    new LoopBack().get('/products').then((res) => {
      setProducts(res);
    });

    if (props.object?.pictures === null) {
      props.object.pictures = [];
    }

    new LoopBack(props.auth.user)
      .get('/colors')
      .then((res: Color[]) => {
        setColors(res.sort((a,b) => a.name['en'] > b.name['en'] ? 1 : -1));
        setColorsError(false);
      })
      .catch((res) => {
        setColorsError(true);
      });

    if (settings.productThemes.enable) {
      new LoopBack(props.auth.user)
        .get('/product-themes')
        .then((res: ProductTheme[]) => {
          setProductThemes(res.sort((a,b) => a.name['en'] > b.name['en'] ? 1 : -1));
          setProductThemesError(false);
        })
        .catch((res) => {
          setProductThemesError(true);
        });
    }

    if (settings.productStyles.enable) {
      new LoopBack(props.auth.user)
        .get('/product-styles')
        .then((res: {data: ProductStyle[]}) => {
          setProductStyles(res.data.sort((a,b) => a.name['en'] > b.name['en'] ? 1 : -1));
          setProductStylesError(false);
        })
        .catch((res) => {
          setProductStylesError(true);
        });
    }

    if (settings.productOccasions.enable) {
      new LoopBack(props.auth.user)
        .get('/product-occasions')
        .then((res: ProductOccasion[]) => {
          setProductOccasions(res.sort((a,b) => a.name['en'] > b.name['en'] ? 1 : -1));
          setProductOccasionsError(false);
        })
        .catch((res) => {
          setProductOccasionsError(true);
        });
    }

    new LoopBack(props.auth.user)
      .get('/product-categories')
      .then((res: ProductCategory[]) => {
        setProductCategories(res.sort((a,b) => a.name['en'] > b.name['en'] ? 1 : -1));
        setProductCategoriesError(false);
      })
      .catch((res) => {
        setProductCategoriesError(true);
      });

    if (settings.productTypes.enable) {
      new LoopBack(props.auth.user)
        .get('/product-types')
        .then((res: ProductType[]) => {
          setProductTypes(res.sort((a,b) => a.name['en'] > b.name['en'] ? 1 : -1));
          setProductTypesError(false);
        })
        .catch((res) => {
          setProductTypesError(true);
        });
    }

    new LoopBack(props.auth.user)
      .get('/sizes')
      .then((res) => {
        setSizes(res);
      })
      .catch((res) => {});
  }, [props.object]);

  const onManageStockChange = (e: boolean) => {
    setManageStock(Boolean(e));
    // reset the stock value to null in case e is set to false
    if (props.formRef && props.formRef.current && !e) {
      props.formRef.current.setFieldsValue({ stock: null });
    }
  };

  const onPreOrderChange = (e: boolean) => {
    setPreOrder(Boolean(e));
    // reset the stock value to null in case e is set to false
    if (props.formRef && props.formRef.current && !e) {
      props.formRef.current.setFieldsValue({ enable_pre_order: null });
    }
  };

  const onCustomizationChange = (e: boolean) => {
    setEnableCustomization(Boolean(e));
    // reset the stock value to null in case e is set to false
    if (props.formRef && props.formRef.current && !e) {
      props.formRef.current.setFieldsValue({ enable_contact_for_customization: null });
    }
  };

  const onPersonaliseChange = (e: boolean) => {
    setPersonalise(Boolean(e));
    // reset the stock value to null in case e is set to false
    if (props.formRef && props.formRef.current && !e) {
      props.formRef.current.setFieldsValue({ max_characters: null });
    }
  };

  const onThreadColorChange = (e: boolean) => {
    setChooseThreadColor(Boolean(e));
    // reset the stock value to null in case e is set to false
    if (props.formRef && props.formRef.current && !e) {
      props.formRef.current.setFieldsValue({ thread_colors: [] });
    }
  };

  const verifySlug = (e: any) => {
    if (!props.object) {
      let value = slugify(e.target.value.toLowerCase());

      if (value.charAt(0) !== '/') {
        value = '/' + value;
      }

      if (props.formRef && props.formRef.current) {
        props.formRef.current.setFieldsValue({ slug: value });
      }

      setSlugIsValidating(true);
      updateSlug(value, props.formRef, props.object)
        .then((res) => {
          setSlugExists(res.slugExists);
          setSlugIsValidating(false);
        })
        .catch((err) => {
          setSlugIsValidating(false);
          setSlugError(true);
        });
    }
  };

  const onSizeTypeChange = (e: SelectValue) => {
    e && setSizeType(e.toString());
    if (props.formRef && props.formRef.current) {
      props.formRef.current.setFieldsValue({ sizes: undefined });
    }
  };

  const onTagsChange = (e: any) => {
    // const allTags = settings.products.tags;
    // const tagIndex = allTags.findIndex((tag) => tag.key === e);
    // if (tagIndex > -1) {
    //
    //   const tag = allTags[tagIndex];
    // }
    e && setTags(e);
    if (props.formRef && props.formRef.current) {
      props.formRef.current.setFieldsValue({ tags: undefined });
    }
  };

  const getForm = (lang: string) => {
    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 22, offset: 0 },
        sm: { span: 16, offset: 8 }
      }
    };

    return (
      <>
        {settings.productTypes.enable && (
          <Form.Item
            name='product_type_id'
            label='Type'
            hasFeedback={productTypesError}
            validateStatus={productTypesError ? 'error' : undefined}
            help={
              productTypesError ? 'Unable to retrieve product types from the database' : undefined
            }
            rules={[{ required: true, message: 'Please select a type' }]}
          >
            <Select placeholder='Select a product type'>
              {productTypes &&
                productTypes.map((productType: ProductType, index) => (
                  <Option key={index} value={productType.id}>
                    {productType.name[lang]}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        )}

        {settings.products.colors && (
          <Form.Item
            name='color_id'
            label='Color'
            hasFeedback={colorsError}
            validateStatus={colorsError ? 'error' : undefined}
            help={colorsError ? 'Unable to retrieve colors from the database' : undefined}
            rules={[{ required: true, message: 'Please select a color' }]}
          >
            <Select placeholder='Please select the color'>
              {colors &&
                colors.map((color, index) => (
                  <Option key={index} value={color.id}>
                    {color.name[lang]}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        )}

        {settings.products.colors && (
          <Form.List name={'product_color_variations'}>
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <Form.Item
                      {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                      label={index === 0 ? 'Color variations' : ''}
                      required={false}
                      key={field.key}
                    >
                      <Row>
                        <Col xs={22}>
                          <Form.Item
                            {...field}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: 'Please select a product',
                                type: 'number'
                              }
                            ]}
                            noStyle
                          >
                            <Select placeholder={'Select product...'}>
                              {products.map((product: Product, pindex) => {
                                return (
                                  <Option key={pindex} value={product.id}>
                                    {product.name[lang]}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={2}>
                          {fields.length > 1 ? (
                            <MinusCircleOutlined
                              className='dynamic-delete-button'
                              style={{ margin: '0 8px' }}
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                          ) : null}
                        </Col>
                      </Row>
                    </Form.Item>
                  ))}
                  <Row>
                    <Col xs={24} sm={{ span: 16, offset: 8 }}>
                      <Form.Item>
                        <Button
                          type='primary'
                          onClick={() => {
                            add();
                          }}
                          style={{ width: '60%' }}
                        >
                          <PlusOutlined /> Add Color Variation
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              );
            }}
          </Form.List>
        )}

        <Form.Item
          label='Name'
          name={['name', lang]}
          rules={[{ required: true, message: 'Please enter a name' }]}
        >
          <Input onChange={lang === settings.languages[0].code ? verifySlug : undefined} />
        </Form.Item>

        <Form.Item label='Subtitle' name={['subtitle', lang]} rules={[{ required: false }]}>
          <Input />
        </Form.Item>

        {settings.products.customUrl && (
          <Form.Item
            name='custom_url'
            label='Custom URL'
            valuePropName='checked'
            extra={"A custom url let's you input a full url to a webpage outside your website"}
          >
            <Switch defaultChecked={false} onChange={(e) => updateCustomUrl(e)} />
          </Form.Item>
        )}

        <Form.Item
          label='Url'
          name='slug'
          hasFeedback={slugIsValidating}
          validateStatus={
            slugIsValidating ? 'validating' : slugExists || slugError ? 'error' : undefined
          }
          help={slugError ? 'Unable to check if this url is already in use' : undefined}
          rules={[{ required: true, message: 'Please enter a url' }]}
        >
          <Input
            placeholder={customUrl ? 'https://some-website.com/products/my-product' : ''}
            addonBefore={
              customUrl ? undefined : process.env.REACT_APP_WEB_URL + settings.products.url
            }
            onChange={lang === settings.languages[0].code && !customUrl ? verifySlug : undefined}
          />
        </Form.Item>

        <Form.Item
          extra={'This is the text that will be displayed in the Google search results'}
          label='SEO Description'
          name={['summary', lang]}
          rules={[{ required: true, message: 'Please enter a summary' }]}
        >
          <ReactQuill theme='snow' />
        </Form.Item>

        <Form.Item
          extra={'This description will appear on the product detail page'}
          label='Product Description'
          name={['description', lang]}
          rules={[{ required: true, message: 'Please enter a description' }]}
        >
          <ReactQuill theme='snow' />
        </Form.Item>

        <Form.Item name='active' label='Active' valuePropName='checked'>
          <Switch defaultChecked={true} />
        </Form.Item>

        <Form.Item
          label={
            process.env.REACT_APP_CURRENCY ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: `Base price in ${process.env.REACT_APP_CURRENCY}`
                }}
              />
            ) : (
              'Base price'
            )
          }
          name='price'
          rules={[{ required: true, message: 'Please enter a base price', type: 'number' }]}
        >
          <InputNumber
            style={{ width: '100%' }}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
          />
        </Form.Item>

        <Form.Item required={true} name='sale' label='Sale'
                   extra={'This will reset the creation date of this product to today\'s date and time, making the system think that this is a new product'}
                   rules={[{ required: true, message: 'Please input if the product is on sale' }]}
        >
          <InputNumber decimalSeparator={','} style={{width: '100%'}} step={1}  placeholder={'Enter sale percentage'} addonAfter={'%'} />
        </Form.Item>

        <Form.Item
          label='Size Type'
          name='size_type'
          rules={[{ required: true, message: 'Please select the size type' }]}
        >
          <Select placeholder='Select size type' onChange={onSizeTypeChange}>
            <Option value='none'>None</Option>
            <Option value='physical_garment'>Garment sizes (S, M, L, XL)</Option>
            <Option value='physical_item'>Other dimensions (a x b cm)</Option>
            <Option value='liquid'>Liquid (ml)</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label='Tags'
          name='tags'
          rules={[{ required: false,type: 'array' }]}
        >
          <Select mode={'multiple'} placeholder='Select Tags'>
            {settings.products.tags.map((tag) => {
              return <Option value={tag.key}>{tag.name}</Option>
            })}
          </Select>
        </Form.Item>

        {sizeType === 'physical_garment' && (
          <Form.Item
            name='sizes'
            label='Sizes'
            extra={'Sizes can be managed by going to Products > Sizes from the menu'}
            rules={[
              { required: true, message: 'Please select the supported sizes', type: 'array' }
            ]}
          >
            <Select mode='multiple' placeholder='Select sizes'>
              {sizes
                .filter((size) => size.category === 'garment')
                .map((size) => {
                  return (
                    <Option key={size.id} value={size.id}>
                      {size.metric_name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        )}

        {sizeType === 'physical_item' && (
          <Form.Item
            name='sizes'
            label='Sizes'
            rules={[
              { required: true, message: 'Please select the supported sizes', type: 'array' }
            ]}
          >
            <Select mode='multiple' placeholder='Select sizes'>
              <OptGroup label='Square'>
                {sizes
                  .filter((size) => size.category === 'square')
                  .map((size) => {
                    return (
                      <Option key={size.id} value={size.id}>
                        {size.metric_name}
                      </Option>
                    );
                  })}
              </OptGroup>
              <OptGroup label='Rectangular'>
                {sizes
                  .filter((size) => size.category === 'rectangular')
                  .map((size) => {
                    return (
                      <Option key={size.id} value={size.id}>
                        {size.metric_name}
                      </Option>
                    );
                  })}
              </OptGroup>
              <OptGroup label='Volume'>
                {sizes
                  .filter((size) => size.category === 'volume')
                  .map((size) => {
                    return (
                      <Option key={size.id} value={size.id}>
                        {size.metric_name}
                      </Option>
                    );
                  })}
              </OptGroup>
              <OptGroup label='Weight'>
                {sizes
                  .filter((size) => size.category === 'weight')
                  .map((size) => {
                    return (
                      <Option key={size.id} value={size.id}>
                        {size.metric_name}
                      </Option>
                    );
                  })}
              </OptGroup>
            </Select>
          </Form.Item>
        )}

        {sizeType === 'liquid' && (
          <Form.Item
            name='sizes'
            label='Sizes'
            rules={[
              { required: true, message: 'Please select the supported sizes', type: 'array' }
            ]}
          >
            <Select mode='multiple' placeholder='Select sizes'>
              {sizes
                .filter((size) => size.category === 'liquid')
                .map((size) => {
                  return (
                    <Option key={size.id} value={size.id}>
                      {size.metric_name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          name='product_category_id'
          label='Product Category'
          extra={
            'Product categories can be managed by going to Product > Product Categories from the menu'
          }
          hasFeedback={productCategoriesError}
          validateStatus={productCategoriesError ? 'error' : undefined}
          help={
            productCategoriesError
              ? 'Unable to retrieve product categories from the database'
              : undefined
          }
          rules={[{ required: true, message: 'Please select a category' }]}
        >
          <Select placeholder='Select a product category'>
            {productCategories &&
              productCategories.map((productCategory, index) => (
                <Option key={index} value={productCategory.id}>
                  {productCategory.name[lang]}
                </Option>
              ))}
          </Select>
        </Form.Item>

        {settings.productThemes.enable && (
          <Form.Item
            name='product_theme_id'
            label='Theme'
            hasFeedback={productThemesError}
            validateStatus={productThemesError ? 'error' : undefined}
            help={
              productThemesError ? 'Unable to retrieve product themes from the database' : undefined
            }
            rules={[{ required: true, message: 'Please select a theme' }]}
          >
            <Select placeholder='Select a product theme'>
              {productThemes &&
                productThemes.map((productTheme, index) => (
                  <Option key={index} value={productTheme.id}>
                    {productTheme.name[lang]}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        )}

        {settings.productStyles.enable && (
          <Form.Item
            name='product_style_id'
            label='Style'
            hasFeedback={productStylesError}
            validateStatus={productStylesError ? 'error' : undefined}
            help={
              productStylesError ? 'Unable to retrieve product styles from the database' : undefined
            }
            rules={[{ required: true, message: 'Please select a style' }]}
          >
            <Select placeholder='Select a product style'>
              {productStyles &&
                productStyles.map((productStyle, index) => (
                  <Option key={index} value={productStyle.id}>
                    {productStyle.name[lang]}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        )}

        {settings.productOccasions.enable && (
          <Form.Item
            name='product_occasion_id'
            label='Occasion'
            hasFeedback={productOccasionsError}
            validateStatus={productOccasionsError ? 'error' : undefined}
            help={
              productOccasionsError
                ? 'Unable to retrieve product occasions from the database'
                : undefined
            }
            rules={[{ required: true, message: 'Please select a occasion' }]}
          >
            <Select placeholder='Select a product occasion'>
              {productOccasions &&
                productOccasions.map((productOccasion, index) => (
                  <Option key={index} value={productOccasion.id}>
                    {productOccasion.name[lang]}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        )}

        {settings.products.sizeInstructions && (
          <Form.Item
            label='Size description'
            name={['size_instructions', lang]}
            rules={[{ required: false }]}
          >
            <ReactQuill theme='snow' />
          </Form.Item>
        )}

        {settings.products.careInstructions && (
          <Form.Item
            label='Care instructions'
            name={['care_instructions', lang]}
            rules={[{ required: false }]}
          >
            <ReactQuill theme='snow' />
          </Form.Item>
        )}

        {settings.products.wearInstructions && (
          <Form.Item
            label='Wear instructions'
            name={['wear_instructions', lang]}
            rules={[{ required: false }]}
          >
            <ReactQuill theme='snow' />
          </Form.Item>
        )}

        <Form.Item name='enable_pre_order' label='Is Pre-order?' valuePropName='checked'>
          <Switch onChange={(e) => onPreOrderChange(e)} defaultChecked={false} />
        </Form.Item>


        {preOrder && (
          <Form.Item
            label='Production Time'
            name='pre_order_production_time'
            rules={[{ required: true, message: 'Please a select a production time' }]}
          >
            <Select placeholder='Select Production Time'>
              <Option value={0}>Available Now</Option>
              <Option value={1}>One week</Option>
              <Option value={2}>Two Weeks</Option>
              <Option value={3}>Three Weeks</Option>
            </Select>
          </Form.Item>
        )}

        <Form.Item name='manage_stock' label='Manage Stock?' valuePropName='checked'>
          <Switch defaultChecked={false} onChange={(e) => onManageStockChange(e)} />
        </Form.Item>

        {manageStock && (
          <Form.Item
            label='Amount in stock'
            name='stock'
            rules={[{ required: true, message: 'Please enter the current stock' }]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        )}

        {manageStock && (
          <Form.Item
            label='Number of pieces designed'
            name='total_stock'
            rules={[{ required: false }]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        )}

        {settings.products.personalise && (
          <Form.Item name='personalise' label='Can be personalised?' valuePropName='checked'>
            <Switch defaultChecked={false} onChange={(e) => onPersonaliseChange(e)} />
          </Form.Item>
        )}

        {personalise && (
          <Form.Item
            label='Max characters'
            name='max_characters'
            rules={[{ required: true, message: 'Please enter the max amount characters allowed' }]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        )}

        {settings.products.enableContactMessage && (
          <Form.Item
            name='enable_contact_for_customization'
            label='Enable customization message'
            valuePropName='checked'
          >
            <Switch onChange={(e) => onCustomizationChange(e)} />
          </Form.Item>
        )}

        {settings.products.threadColors && (
          <Form.Item
            name='choose_thread_color'
            label='Choose thread color?'
            valuePropName='checked'
          >
            <Switch onChange={(e) => onThreadColorChange(e)} />
          </Form.Item>
        )}

        {settings.products.threadColors && chooseThreadColor && (
          <Form.Item
            name='thread_colors'
            label='Thread Color Options'
            rules={[
              {
                required: true,
                message: 'Please select the supported thread colors',
                type: 'array'
              }
            ]}
          >
            <Select mode='multiple' placeholder='Select sizes'>
              <Option value={'blue'}>Blue</Option>
              <Option value={'pink'}>Pink</Option>
              <Option value={'purple'}>Purple</Option>
            </Select>
          </Form.Item>
        )}

        {settings.products.relatedProducts && (
          <Form.List name={'related_products'}>
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <Form.Item
                      {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                      label={index === 0 ? 'Related products' : ''}
                      required={false}
                      key={field.key}
                    >
                      <Row>
                        <Col xs={22}>
                          <Form.Item
                            {...field}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: 'Please select a product',
                                type: 'number'
                              }
                            ]}
                            noStyle
                          >
                            <Select placeholder={'Select product...'}>
                              {products.map((product, pindex) => {
                                return (
                                  <Option key={pindex} value={product.id}>
                                    {product.name[lang]}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={2}>
                          {fields.length > 1 ? (
                            <MinusCircleOutlined
                              className='dynamic-delete-button'
                              style={{ margin: '0 8px' }}
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                          ) : null}
                        </Col>
                      </Row>
                    </Form.Item>
                  ))}
                  <Row>
                    <Col xs={24} sm={{ span: 16, offset: 8 }}>
                      <Form.Item>
                        <Button
                          type='primary'
                          onClick={() => {
                            add();
                          }}
                          style={{ width: '60%' }}
                        >
                          <PlusOutlined /> Add Related Product
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              );
            }}
          </Form.List>
        )}

        <Form.List name='pictures'>
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                    label={index === 0 ? 'Pictures' : ''}
                    required={false}
                    key={field.key}
                  >
                    <Row>
                      <Col xs={22}>
                        <Form.Item
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: 'Please select an image'
                            }
                          ]}
                          noStyle
                        >
                          <FormImageSelectorComponent
                            showAddon={true}
                            preloadedFileList={fileList}
                            callback={(fileList: LoopbackFile[]) => setFileList(fileList)}
                            formRef={props.formRef}
                            rootKey={'pictures'}
                            subKeys={[]}
                            formKey={field.name.toString()}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={2}>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className='dynamic-delete-button'
                            style={{ margin: '0 8px' }}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        ) : null}
                      </Col>
                    </Row>
                  </Form.Item>
                ))}
                <Row>
                  {fields.length === 0 && (
                    <Col className={'placeholder-label'} xs={24} sm={8}>
                      <span>Pictures:</span>
                    </Col>
                  )}

                  <Col xs={{span: 24, offset: 0}} sm={{ span: 16, offset: fields.length === 0 ? 0 : 8 }}>
                    <Form.Item>
                      <Button
                        type='primary'
                        onClick={() => {
                          add();
                        }}
                        style={{ width: '60%' }}
                      >
                        <PlusOutlined /> Add Picture
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            );
          }}
        </Form.List>

        {props.object &&
          <Form.Item
            extra={'This will force the product to be shown in the new arrivals list.'}
            name='force_new_arrivals' label='Re-set product creation date to today?' valuePropName='checked'>
            <Switch defaultChecked={false}/>
          </Form.Item>
        }
      </>
    );
  };

  const product = props.object;
  const languages = settings.languages;

  return (
    <>
      <FormHeaderComponent
        type={props.type}
        newTitle={product ? '' : 'Add a new product'}
        editTitle={product ? `Edit ${product.name[languages[0].code]}` : ''}
      />

      <Form
        {...formItemLayout}
        ref={props.formRef}
        initialValues={{
          custom_url: false,
          active: true,
          manage_stock: false,
          choose_thread_color: false,
          personalise: false,
          enable_contact_for_customization: false,
          posted_to_fulfillment_center: false
        }}
        name='basic'
      >
        <Tabs defaultActiveKey={languages[0].code.toLowerCase()}>
          {languages.map((language, index) => {
            return (
              <TabPane forceRender={true} tab={language.name} key={language.code.toLowerCase()}>
                {getForm(language.code.toLowerCase())}
              </TabPane>
            );
          })}
        </Tabs>
      </Form>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(ProductForm);
