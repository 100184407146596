import { APP_SET_SIDEBAR, APP_TOGGLE_SIDEBAR } from '../types';

export const setSidebar = (collapse: boolean) => ({
  type: APP_SET_SIDEBAR,
  payload: collapse
});

export const toggleSidebar = () => ({
  type: APP_TOGGLE_SIDEBAR
});
