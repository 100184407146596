import React, { Component } from 'react';
import TableComponent from './table.component';
import ProductCategoryForm from '../../forms/product-category.form';
import { settings } from '../../../settings';

interface State {
  language: string;
}

class ProductCategoriesComponent extends Component<{}, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      language: settings.languages[0].code.toLowerCase()
    };
  }

  render() {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: true,
        render: (x: { [key: string]: string }) => x[this.state.language]
      },
      {
        title: 'Url',
        dataIndex: 'slug',
        sorter: true,
      }
    ];

    return (
      <TableComponent
        form={ProductCategoryForm}
        url={'/product-categories'}
        filter={{}}
        name={'Product Category'}
        title={'Product Categories'}
        columns={columns}
        searchFields={['name', 'slug']}
        actions={['view', 'add', 'edit', 'delete', 'languages']}
        onLanguageChange={(lang: string) => this.setState({ language: lang })}
      />
    );
  }
}

export default ProductCategoriesComponent;
