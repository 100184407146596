import { Form, Switch } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { AuthState } from '../../redux/states/user';
import 'react-quill/dist/quill.snow.css';

interface Props {
  auth: AuthState;
  field: any;
  formRef: any;
  language: string;
}

const LookbooksModule = (props: Props) => {
  return (
    <>
      <Form.Item
        label='Show menu'
        name={[props.field.name, 'settings', 'show_menu']}
        valuePropName='checked'
        rules={[{ required: false, type: 'boolean' }]}
      >
        <Switch />
      </Form.Item>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(LookbooksModule);
