import React, { useEffect, useState } from 'react';
import { Form, Input, Select } from 'antd';
import FormHeaderComponent from '../components/forms/form-header.component';
import { formItemLayout } from '../components/forms/form-layouts.component';
import { Translation } from '../types';
import { AuthState } from '../redux/states/user';
import { connect } from 'react-redux';
import { setFormData } from '../helpers/form.helper';
import { FormInstance } from 'antd/es/form';
import { settings } from '../../settings';

interface Props {
  auth: AuthState;
  type: string;
  visible: boolean;
  isSaving: boolean;
  object?: Translation | null;
  formRef: React.RefObject<FormInstance>;
}

const TranslationForm = (props: Props) => {
  const [slugError, setSlugError] = useState(false);

  useEffect(() => {
    setSlugError(false);
    setFormData(props, props.object, props.formRef);
  }, [props.object]);

  const translation = props.object;

  return (
    <>
      <FormHeaderComponent
        type={props.type}
        newTitle={translation ? '' : 'Add a new Color'}
        editTitle={translation ? `Edit ${translation.key}` : ''}
      />

      <Form {...formItemLayout} ref={props.formRef} name='basic'>
        <Form.Item
          label='Key'
          name='key'
          rules={[{ required: true, message: 'Please enter a key' }]}
        >
          <Input readOnly={true} />
        </Form.Item>

        {translation && (
          <Form.Item
            label='Value'
            name='value'
            rules={[{ required: true, message: 'Please enter a value' }]}
          >
            <Input.TextArea />
          </Form.Item>
        )}

        {translation && (
          <Form.Item
            label={'Language'}
            name={'language'}
            rules={[{ required: true, message: 'Please select a language' }]}
          >
            <Select placeholder={'Select a language'}>
              {settings.languages.map((language) => {
                return <Select.Option value={language.code}>{language.name}</Select.Option>;
              })}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          label={'Category'}
          name={'namespace'}
          rules={[{ required: true, message: 'Please select a category' }]}
        >
          <Select placeholder={'Select a category'}>
            <Select.Option value={'app'}>Static pages</Select.Option>
            <Select.Option value={'auth'}>Authentication</Select.Option>
            <Select.Option value={'cart'}>Cart</Select.Option>
            <Select.Option value={'product'}>Product</Select.Option>
            <Select.Option value={'events'}>Events</Select.Option>
            <Select.Option value={'pages'}>Dynamic Pages</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(TranslationForm);
