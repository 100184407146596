import { Form, Input } from 'antd';
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import FormImageSelectorComponent from '../../components/forms/form-image-selector.component';
import { LoopbackFile } from '../../types';

export const TwoColoredBlocksModule = (field: {
  name: string;
  formRef: any;
  language: string;
  field: { name: string; key: string };
  isSaving: boolean;
}) => {
  const [fileList, setFileList] = useState<LoopbackFile[]>([]);

  return (
    <>
      <h3>First Block</h3>
      <Form.Item
        label='Title'
        name={[field.name, 'settings', 'first_block', 'title', field.language]}
        rules={[{ required: true }]}
      >
        <Input placeholder='Title of this block' />
      </Form.Item>
      <Form.Item
        label='Description'
        name={[field.name, 'settings', 'first_block', 'description', field.language]}
        rules={[{ required: true }]}
      >
        <ReactQuill theme='snow' />
      </Form.Item>
      <Form.Item
        label='Color'
        name={[field.name, 'settings', 'first_block', 'background_color']}
        rules={[{ required: true }]}
      >
        <Input placeholder='#EEEEEE' />
      </Form.Item>
      <Form.Item
        label='image'
        name={[field.name, 'settings', 'first_block', 'image_url']}
        rules={[{ required: true }]}
      >
        <FormImageSelectorComponent
          showAddon={true}
          preloadedFileList={fileList}
          callback={(fileList: LoopbackFile[]) => setFileList(fileList)}
          formRef={field.formRef}
          rootKey={'sections'}
          subKeys={[field.name, 'settings', 'first_block']}
          formKey={'image_url'}
        />
      </Form.Item>

      <Form.Item
        label='Button text'
        name={[field.name, 'settings', 'first_block', 'button', 'text', field.language]}
        rules={[{ required: true }]}
      >
        <Input placeholder='Book Now' />
      </Form.Item>

      <Form.Item
        label='Button url'
        name={[field.name, 'settings', 'first_block', 'button', 'url']}
        rules={[{ required: true }]}
      >
        <Input addonBefore={process.env.REACT_APP_WEB_URL} />
      </Form.Item>

      <hr />
      <h3>Second Block</h3>
      <Form.Item
        label='Title'
        name={[field.name, 'settings', 'second_block', 'title', field.language]}
        rules={[{ required: true }]}
      >
        <Input placeholder='Title of this block' />
      </Form.Item>
      <Form.Item
        label='Description'
        name={[field.name, 'settings', 'second_block', 'description', field.language]}
        rules={[{ required: true }]}
      >
        <ReactQuill theme='snow' />
      </Form.Item>
      <Form.Item
        label='Color'
        name={[field.name, 'settings', 'second_block', 'background_color']}
        rules={[{ required: true }]}
      >
        <Input placeholder='#EEEEEE' />
      </Form.Item>
      <Form.Item
        label='image'
        name={[field.name, 'settings', 'second_block', 'image_url']}
        rules={[{ required: true }]}
      >
        <FormImageSelectorComponent
          showAddon={true}
          preloadedFileList={fileList}
          callback={(fileList: LoopbackFile[]) => setFileList(fileList)}
          formRef={field.formRef}
          rootKey={'sections'}
          subKeys={[field.name, 'settings', 'second_block']}
          formKey={'image_url'}
        />
      </Form.Item>
      <Form.Item
        label='Button text'
        name={[field.name, 'settings', 'second_block', 'button', 'text', field.language]}
        rules={[{ required: true }]}
      >
        <Input placeholder='Book Now' />
      </Form.Item>

      <Form.Item
        label='Button url'
        name={[field.name, 'settings', 'second_block', 'button', 'url']}
        rules={[{ required: true }]}
      >
        <Input addonBefore={process.env.REACT_APP_WEB_URL} />
      </Form.Item>
    </>
  );
};
