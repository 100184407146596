import { Button, Col, Form, Input, Row, Select, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AuthState } from '../../redux/states/user';
import { LoopbackFile, ProductCategory, ProductTheme, ProductType } from '../../types';
import { LoopBack } from '../../redux/api';
import { settings } from '../../../settings';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import { formItemLayout } from '../../components/forms/form-layouts.component';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import FormImageSelectorComponent from '../../components/forms/form-image-selector.component';

const { Option } = Select;

interface Props {
  object: any;
  auth: AuthState;
  field: any;
  formRef: any;
  language: string;
}

const WebshopModule = (props: Props) => {
  const [productTypes, setProductTypes] = useState<ProductType[]>([]);
  const [productCategories, setProductCategories] = useState<ProductCategory[]>([]);
  const [activatePromo, setActivatePromo] = useState(false);
  const [productThemes, setProductThemes] = useState<ProductTheme[]>([]);
  const [fileList, setFileList] = useState<LoopbackFile[]>([]);

  useEffect(() => {
    new LoopBack(props.auth.user)
      .get('/product-categories')
      .then((res) => {
        setProductCategories(res);
      })
      .catch((err) => {
        setProductCategories([]);
      });

    new LoopBack(props.auth.user)
      .get('/product-types')
      .then((res) => {
        setProductTypes(res);
      })
      .catch((err) => {
        setProductTypes([]);
      });

    new LoopBack(props.auth.user)
      .get('/product-themes')
      .then((res) => {
        setProductThemes(res);
      })
      .catch((err) => {
        setProductThemes([]);
      });

    if (
      props.object &&
      props.object.sections &&
      props.object.sections[props.field.key] &&
      props.object.sections[props.field.key].settings &&
      props.object.sections[props.field.key].settings.promo
    ) {
      setActivatePromo(props.object.sections[props.field.key].settings.promo.insert);
    }
  }, []);

  const subFormItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 19 }
    }
  };

  const onChange = (e: boolean) => {
    setActivatePromo(e);
  };

  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 22, offset: 0 },
      sm: { span: 16, offset: 8 }
    }
  };

  return (
    <>
      <Form.Item
        label='Products per row'
        name={[props.field.name, 'settings', 'products_per_row']}
        rules={[
          { required: true, message: 'Please select the number of products to show per row' }
        ]}
      >
        <Select placeholder='Select num of products per row'>
          <Option value={3}>3</Option>
          <Option value={4}>4</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label='Space between products'
        name={[props.field.name, 'settings', 'spacing']}
        rules={[{ required: true, message: 'Please select the space between products' }]}
      >
        <Select placeholder='- Select spacing -'>
          <Option value={'small'}>Small</Option>
          <Option value={'medium'}>Medium</Option>
          <Option value={'large'}>Large</Option>
        </Select>
      </Form.Item>

      <Form.Item
        extra={'This will show all the available product categories in a horizontal bar'}
        label={'Show category bar'}
        name={[props.field.name, 'settings', 'show_categories']}
        valuePropName='checked'
      >
        <Switch />
      </Form.Item>

      <Form.Item
        label='Restrict to Product Categorie(s)'
        name={[props.field.name, 'settings', 'product_categories']}
        rules={[{ required: false }]}
        extra={'If selected only products of these categories will be shown'}
      >
        <Select mode={'multiple'} placeholder='Please select a category'>
          {productCategories.map((type, index) => {
            return (
              <Option key={index} value={type.id}>
                {type.name[settings.languages[0].code]}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item
        label='Restrict to Product Type(s)'
        name={[props.field.name, 'settings', 'product_types']}
        rules={[{ required: false }]}
        extra={'If selected only products of these types will be shown'}
      >
        <Select mode={'multiple'} placeholder='Please select a type'>
          {productTypes.map((type, index) => {
            return (
              <Option key={index} value={type.id}>
                {type.name[settings.languages[0].code]}
              </Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item
        label='Restrict to Product Theme(s)'
        name={[props.field.name, 'settings', 'product_themes']}
        rules={[{ required: false }]}
        extra={'If selected only products of these themes will be shown'}
      >
        <Select mode={'multiple'} placeholder='Please select a theme'>
          {productThemes.map((theme, index) => {
            return (
              <Option key={index} value={theme.id}>
                {theme.name[settings.languages[0].code]}
              </Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.List name={[props.field.name, 'settings', 'promos']}>
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map((field, index) => (
                <Form.Item
                  {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                  label={index === 0 ? 'Promo block(s)' : ''}
                  required={false}
                  key={field.key}
                >
                  <Row className={'form-sub-section'}>
                    <Col xs={22}>
                      <Form.Item
                        {...subFormItemLayout}
                        label='Content'
                        name={[field.name, props.language, 'content']}
                        rules={[{ required: false }]}
                      >
                        <ReactQuill theme='snow' />
                      </Form.Item>

                      <Form.Item
                        {...subFormItemLayout}
                        label={'Image'}
                        name={[field.name, 'image_url']}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[{ required: false }]}
                      >
                        <FormImageSelectorComponent
                          showAddon={true}
                          preloadedFileList={fileList}
                          callback={(fileList: LoopbackFile[]) => setFileList(fileList)}
                          formRef={props.formRef}
                          rootKey={'sections'}
                          subKeys={[props.field.name, 'settings', 'promos', field.name]}
                          formKey={'image_url'}
                        />
                      </Form.Item>

                      <Form.Item
                        {...subFormItemLayout}
                        label='Button text'
                        name={[field.name, props.language, 'button_text']}
                        rules={[{ required: false }]}
                      >
                        <Input placeholder='Button text' />
                      </Form.Item>
                      <Form.Item
                        {...subFormItemLayout}
                        label='Button url'
                        name={[field.name, 'button_url']}
                        rules={[{ required: false }]}
                      >
                        <Input addonBefore={process.env.REACT_APP_WEB_URL} />
                      </Form.Item>
                    </Col>
                    <Col xs={2}>
                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          className='dynamic-delete-button'
                          style={{ margin: '0 8px' }}
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      ) : null}
                    </Col>
                  </Row>
                </Form.Item>
              ))}
              <Row>
                {fields.length === 0 && (
                  <Col xs={24} sm={8}>
                    <span>Promo block(s) :</span>
                  </Col>
                )}

                <Col xs={24} sm={{ span: 16, offset: fields.length === 0 ? 0 : 8 }}>
                  <Form.Item>
                    <Button
                      type='primary'
                      onClick={() => {
                        add();
                      }}
                      style={{ width: '60%' }}
                    >
                      <PlusOutlined /> Add Promo Block
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          );
        }}
      </Form.List>

      {/*{activatePromo && (*/}
      {/*  <Form.Item label='Promo details' rules={[{ required: false }]}>*/}
      {/*    <Row className={'form-sub-section'}>*/}
      {/*      <Col xs={23}>*/}
      {/*        <Form.Item*/}
      {/*          {...subFormItemLayout}*/}
      {/*          label='Content'*/}
      {/*          name={[props.field.name, 'settings', 'promo', 'content']}*/}
      {/*          rules={[{ required: false }]}*/}
      {/*        >*/}
      {/*          <ReactQuill theme='snow' />*/}
      {/*        </Form.Item>*/}

      {/*        <Form.Item*/}
      {/*          {...subFormItemLayout}*/}
      {/*          label='Button text'*/}
      {/*          name={[props.field.name, 'settings', 'promo', 'button_text']}*/}
      {/*          rules={[{ required: false }]}*/}
      {/*        >*/}
      {/*          <Input placeholder='Button text' />*/}
      {/*        </Form.Item>*/}
      {/*        <Form.Item*/}
      {/*          {...subFormItemLayout}*/}
      {/*          label='Button url'*/}
      {/*          name={[props.field.name, 'settings', 'promo', 'button_url']}*/}
      {/*          rules={[{ required: false }]}*/}
      {/*        >*/}
      {/*          <Input addonBefore={process.env.REACT_APP_WEB_URL} />*/}
      {/*        </Form.Item>*/}
      {/*      </Col>*/}
      {/*    </Row>*/}
      {/*  </Form.Item>*/}
      {/*)}*/}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(WebshopModule);
