import { Button, Col, Form, Input, Row, Select, Switch } from 'antd';
import React, { useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import FormImageSelectorComponent from '../../components/forms/form-image-selector.component';
import { LoopbackFile } from '../../types';
import { formItemLayout } from '../../components/forms/form-layouts.component';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import 'react-quill/dist/quill.snow.css';
import FormImageSelectorNoInputComponent from '../../components/forms/form-image-selector-no-input.component';

const { Option } = Select;

interface Props {
  field: any;
  formRef: any;
  language: string;
  isSaving: boolean;
}

const HeaderModule = (props: Props) => {
  const _quill: any = useRef<ReactQuill>();
  const [showImageModal, setShowImageModal] = useState(false);
  const [fileList, setFileList] = useState<LoopbackFile[]>([]);

  let smallToolbar = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        ['clean']
      ]
    },
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false
    }
  };

  let toolbar: any = useRef({
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        ['link', 'image', 'video'],
        ['clean']
      ],
      handlers: {
        image: () => setShowImageModal(true)
      }
    },
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false
    }
  });

  const doAddImage = (filePath: string) => {
    if (_quill.current) {
      const quill = _quill.current.getEditor();
      const range = quill.getSelection(true);
      quill.insertEmbed(range.index, 'image', filePath);
    }
  };

  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 22, offset: 0 },
      sm: { span: 16, offset: 8 }
    }
  };

  return (
    <>
      <Form.Item
        extra={
          'A black-colored overlay will be automatically applied on top of the background image if you fill in a title,'
        }
        label='Title'
        name={[props.field.name, 'settings', 'title', props.language]}
        rules={[{ required: false }]}
      >
        <ReactQuill modules={smallToolbar} theme='snow' />
      </Form.Item>
      <Form.Item
        label='Sub-title'
        name={[props.field.name, 'settings', 'subtitle', props.language]}
        rules={[{ required: false }]}
      >
        <ReactQuill modules={smallToolbar} theme='snow' />
      </Form.Item>
      <Form.Item
        label='Button text'
        name={[props.field.name, 'settings', 'button', 'text', props.language]}
        rules={[{ required: false }]}
      >
        <Input placeholder='Button text' />
      </Form.Item>
      <Form.Item
        label='Button url'
        name={[props.field.name, 'settings', 'button', 'url']}
        rules={[{ required: false }]}
      >
        <Input addonBefore={process.env.REACT_APP_WEB_URL} />
      </Form.Item>

      <Form.List name={[props.field.name, 'settings', 'images']}>
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map((field, index) => (
                <Form.Item
                  {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                  label={index === 0 ? 'Image(s)' : ''}
                  required={false}
                  key={field.key}
                >
                  <Row>
                    <Col xs={22}>
                      <Form.Item
                        name={field.name}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[{ required: true, message: 'Please select an image' }]}
                      >
                        <FormImageSelectorComponent
                          showAddon={true}
                          preloadedFileList={fileList}
                          callback={(fileList: LoopbackFile[]) => setFileList(fileList)}
                          formRef={props.formRef}
                          rootKey={'sections'}
                          subKeys={[props.field.name, 'settings', 'images']}
                          formKey={field.name.toString()}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={2}>
                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          className='dynamic-delete-button'
                          style={{ margin: '0 8px' }}
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      ) : null}
                    </Col>
                  </Row>
                </Form.Item>
              ))}

              <Row>
                {fields.length === 0 && (
                  <Col className={'placeholder-label'} xs={24} sm={8}>
                    <span>Image(s):</span>
                  </Col>
                )}

                <Col xs={24} sm={{ span: 16, offset: fields.length === 0 ? 0 : 8 }}>
                  <Form.Item>
                    <Button
                      type='primary'
                      onClick={() => {
                        add();
                      }}
                      style={{ width: '60%' }}
                    >
                      <PlusOutlined /> Add Image
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          );
        }}
      </Form.List>
      <Form.Item
        label={'Add parallax effect to image'}
        name={[props.field.name, 'settings', 'parallax']}
        valuePropName='checked'
      >
        <Switch />
      </Form.Item>

      <Form.Item
        label={'Add overlay to image'}
        name={[props.field.name, 'settings', 'overlay']}
        valuePropName='checked'
      >
        <Switch />
      </Form.Item>

      <Form.Item
        label={'Add text animation'}
        name={[props.field.name, 'settings', 'animate_text']}
        valuePropName='checked'
      >
        <Switch />
      </Form.Item>

      <Form.Item
        label='Background image SEO text'
        name={[props.field.name, 'settings', 'background_image', 'alt']}
        rules={[{ required: false }]}
      >
        <Input placeholder='Background SEO text' />
      </Form.Item>
      <Form.Item
        label='Size'
        name={[props.field.name, 'settings', 'size']}
        rules={[{ required: true }]}
      >
        <Select placeholder='Please select a type'>
          <Option value='small'>Small</Option>
          <Option value='medium'>Medium</Option>
          <Option value='large'>Large</Option>
          <Option value='large-with-fluid'>Large with fluid</Option>
          <Option value='extra-large'>Extra Large</Option>
        </Select>
      </Form.Item>

      <FormImageSelectorNoInputComponent
        showModal={showImageModal}
        hideModal={() => setShowImageModal(false)}
        onImageSelected={(file: LoopbackFile) => {
          const url = file.apiPath;
          doAddImage(process.env.REACT_APP_API_URL + url);
          setShowImageModal(false);
        }}
      />
    </>
  );
};

export default HeaderModule;
