import Paper from 'paper';
import paper from 'paper';

export interface SoftwareState {
  paper: typeof Paper;
  action: string;
  tool: string;
  selectedObject: paper.Item | null;
  polygonSides: number;
  lockCanvas: boolean;
  fillColor: string | null;
  strokeColor: string | null;
  strokeWidth: number;
  dasharray: [number, number];
  strokeJoin: 'miter' | 'bevel' | 'round';
  strokeCap: 'round' | 'square' | 'butt';
}
export const SoftwareState: SoftwareState = {
  paper: Paper,
  action: 'selection',
  tool: 'select',
  selectedObject: null,
  polygonSides: 5,
  lockCanvas: false,
  fillColor: '#333333',
  strokeColor: '#333333',
  strokeWidth: 1,
  dasharray: [0, 0],
  strokeJoin: 'miter',
  strokeCap: 'butt'
};
