import React from 'react';
import TableComponent from './table.component';
import SizeForm from '../../forms/size.form';

const SizesComponent = () => {
  const columns = [
    { title: 'Imperial Name', dataIndex: 'imperial_name', sorter: true, responsive: ['lg'] },
    {
      title: 'Imperial dimensions',
      dataIndex: 'imperial_dimensions',
      sorter: true,
      responsive: ['lg']
    },
    { title: 'Metric Name', dataIndex: 'metric_name', sorter: true },
    { title: 'Metric dimensions', dataIndex: 'metric_dimensions', sorter: true },
    { title: 'Category', dataIndex: 'category', sorter: true, responsive: ['xl'] },
    { title: 'Price', dataIndex: 'price', sorter: true, responsive: ['xl'] }
  ];

  return (
    <TableComponent
      form={SizeForm}
      url={'/sizes'}
      filter={{}}
      name={'Size'}
      title={'Sizes'}
      columns={columns}
      searchFields={['name']}
      actions={['view', 'add', 'edit', 'delete']}
    />
  );
};

export default SizesComponent;
