import { Button, Col, Form, Input, Row, Select } from 'antd';
import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { AuthState } from '../../redux/states/user';
import ReactQuill, { Quill } from 'react-quill';
import { formItemLayout } from '../../components/forms/form-layouts.component';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import FormImageSelectorNoInputComponent from '../../components/forms/form-image-selector-no-input.component';
import { LoopbackFile } from '../../types';
import { linkType } from '../../helpers/quill';
import 'react-quill/dist/quill.snow.css';

Quill.register(linkType);

interface Props {
  auth: AuthState;
  field: any;
  formRef: any;
  language: string;
  isSaving: boolean;
}

const  GridModule = (props: Props) => {
  const [showImageModal, setShowImageModal] = useState(false);
  const [activeSection, setActiveSection] = useState(0);

  const quill: any = React.createRef<ReactQuill>();

  const createToolbar = (ind: number) => {
    return {
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
          ['link', 'image', 'video'],
          ['clean']
        ],
        handlers: {
          image: () => {
            setShowImageModal(true);
            setActiveSection(ind);
          }
        }
      },
      clipboard: {
        matchVisual: false
      }
    };
  };

  const doAddImage = (filePath: string, index: number) => {
    if (quill.current) {
      const _quill = quill.current.getEditor();
      const range = _quill.getSelection(true);
      _quill.insertEmbed(range.index, 'image', filePath);
    }
  };


  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 22, offset: 0 },
      sm: { span: 16, offset: 8 }
    }
  };



  const subFormItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 19 }
    }
  };
    return (
      <>
        <Form.Item
          label='Title'
          name={[props.field.name, 'settings', 'title', props.language]}
          rules={[{ required: false }]}
        >
          <Input placeholder='Title of this block' />
        </Form.Item>
        <Form.Item
          label='Title Position'
          name={[props.field.name, 'settings', 'title_position']}
          rules={[{ required: true, message: 'Please select a position' }]}
        >
          <Select placeholder={'Select a position'}>
            <Select.Option value={'top'}>Top</Select.Option>
            <Select.Option value={'column'}>In Column</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label='Sub-title'
          name={[props.field.name, 'settings', 'subtitle', props.language]}
          rules={[{ required: false }]}
        >
          <ReactQuill theme='snow' modules={createToolbar(0)} />
        </Form.Item>
        <Form.Item
          label='Background color'
          name={[props.field.name, 'settings', 'background_color']}
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Button text'
          name={[props.field.name, 'settings', 'button', 'text', props.language]}
          rules={[{ required: false }]}
        >
          <Input placeholder='Button text' />
        </Form.Item>
        <Form.Item
          label='Button url'
          name={[props.field.name, 'settings', 'button', 'url']}
          rules={[{ required: false }]}
        >
          <Input addonBefore={process.env.REACT_APP_WEB_URL} />
        </Form.Item>
        <Form.Item
          label='Grid type'
          name={[props.field.name, 'settings', 'grid_type']}
          rules={[{ required: true, message: 'Please select a grid type' }]}
        >
          <Select placeholder={'Select a grid type'}>
            <Select.Option value={'1_4'}>1 + 4 Boxes</Select.Option>
            <Select.Option value={'4_1'}>4 + 1 Boxes</Select.Option>
          </Select>
        </Form.Item>
        <Form.List name={[props.field.name, 'settings', 'items_first_column']}>
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                    label={index === 0 ? 'Items first column' : ''}
                    required={false}
                    key={field.key}
                  >
                    <Row className={'form-sub-section'}>
                      <Col xs={22}>
                        <Row>
                          <Col xs={24}>
                            <Form.Item
                              {...subFormItemLayout}
                              name={[field.name, 'content']}
                              label='Content'
                              rules={[{ required: true, message: 'Enter the content for this item' }]}
                            >
                              <ReactQuill ref={quill} theme='snow'  modules={createToolbar(0)}  />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={2}>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className='dynamic-delete-button'
                            style={{ margin: '0 8px' }}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        ) : null}
                      </Col>
                    </Row>
                  </Form.Item>
                ))}

                <Row>
                  {fields.length === 0 && (
                    <Col className={'placeholder-label'} xs={24} sm={8}>
                      <span>Items first column:</span>
                    </Col>
                  )}

                  <Col xs={24} sm={{ span: 16, offset: fields.length === 0 ? 0 : 8 }}>
                    <Form.Item>
                      <Button
                        type='primary'
                        onClick={() => {
                          add();
                        }}
                        style={{ width: '60%' }}
                      >
                        <PlusOutlined /> Add Item
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            );
          }}
        </Form.List>
        <Form.List name={[props.field.name, 'settings', 'items_second_column']}>
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                    label={index === 0 ? 'Items second column' : ''}
                    required={false}
                    key={field.key}
                  >
                    <Row className={'form-sub-section'}>
                      <Col xs={22}>
                        <Row>
                          <Col xs={24}>
                            <Form.Item
                              {...subFormItemLayout}
                              name={[field.name, 'content']}
                              label='Content'
                              rules={[{ required: true, message: 'Enter the content for this item' }]}
                            >
                              <ReactQuill theme='snow' modules={createToolbar(0)}  />
                            </Form.Item>
                          </Col>
                          <Col xs={24}>
                            <Form.Item
                              {...subFormItemLayout}
                              name={[field.name, 'button', 'text']}
                              label='Button Text'
                              rules={[{ required: false}]}
                            >
                              <Input/>
                            </Form.Item>
                          </Col>
                          <Col xs={24}>
                            <Form.Item
                              {...subFormItemLayout}
                              name={[field.name, 'button', 'url']}
                              label='Button Text'
                              rules={[{ required: false}]}
                            >
                              <Input addonBefore={process.env.REACT_APP_WEB_URL} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={2}>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className='dynamic-delete-button'
                            style={{ margin: '0 8px' }}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        ) : null}
                      </Col>
                    </Row>
                  </Form.Item>
                ))}

                <Row>
                  {fields.length === 0 && (
                    <Col className={'placeholder-label'} xs={24} sm={8}>
                      <span>Items second column:</span>
                    </Col>
                  )}

                  <Col xs={24} sm={{ span: 16, offset: fields.length === 0 ? 0 : 8 }}>
                    <Form.Item>
                      <Button
                        type='primary'
                        onClick={() => {
                          add();
                        }}
                        style={{ width: '60%' }}
                      >
                        <PlusOutlined /> Add Item
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            );
          }}
        </Form.List>

        <FormImageSelectorNoInputComponent
          showModal={showImageModal}
          hideModal={() => setShowImageModal(false)}
          onImageSelected={(file: LoopbackFile) => {
            const url = file.apiPath;
            doAddImage(process.env.REACT_APP_API_URL + url, activeSection);
            setShowImageModal(false);
          }}
        />
      </>
    );
}

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(GridModule);
