import React, { Component } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import QueueAnim from 'rc-queue-anim';
import Swal from 'sweetalert2';
import { LoopBack } from '../redux/api';
import '../styles/auth.less';

interface Props {
  onModeChange: (value: string) => void;
}

interface State {
  loading: boolean;
}

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 }
};
const tailLayout = {
  wrapperCol: { xs: { offset: 0, span: 16 }, sm: { offset: 6, span: 16 } }
};

class ResetPasswordRequestForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  onFinish = (values: any) => {
    this.setState({ loading: true });
    new LoopBack()
      .post('/admins/reset-mail', values)
      .then((res) => {
        this.setState({ loading: false });
        Swal.fire({
          title: 'Email requested',
          text: `An email has been send to you with a link to set your password`,
          icon: 'success',
          confirmButtonText: 'Ok'
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        Swal.fire({
          title: 'Oops...',
          text: `Something went wrong with requesting your password reset`,
          icon: 'error',
          confirmButtonText: 'Ok'
        });
      });
  };

  render() {
    return (
      <div id={'auth-form'}>
        <Row>
          <Col {...tailLayout.wrapperCol}>
            <img
              alt={`Logo ${process.env.REACT_APP_NAME}`}
              src={require('../../assets/logo-white.png')}
            />
          </Col>
        </Row>
        <Row>
          <Col {...tailLayout.wrapperCol}>
            <div id={'auth-subtitle'}>
              <h1>Reset Password</h1>
            </div>
          </Col>
        </Row>
        <Form {...layout} name='basic' initialValues={{ remember: true }} onFinish={this.onFinish}>
          <QueueAnim type={'bottom'} duration={2500}>
            <div key={1}>
              <Form.Item
                label='Email'
                name='email'
                rules={[{ required: true, message: 'Please input your username!' }]}
              >
                <Input size={'large'} />
              </Form.Item>
            </div>

            <div key={2}>
              <Form.Item {...tailLayout} className={'auth-buttons'}>
                <Button
                  size={'large'}
                  style={{ float: 'left' }}
                  htmlType={'button'}
                  className='login-form-button'
                  onClick={() => this.props.onModeChange('login')}
                >
                  Back
                </Button>
                <Button
                  size={'large'}
                  style={{ marginLeft: 5 }}
                  loading={this.state.loading}
                  type='primary'
                  htmlType='submit'
                >
                  {this.state.loading ? <span>Loading...</span> : <span>Submit</span>}
                </Button>
              </Form.Item>
            </div>
          </QueueAnim>
        </Form>
      </div>
    );
  }
}

export default ResetPasswordRequestForm;
