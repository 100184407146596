import { Quill } from 'react-quill';

const Link = Quill.import('formats/link');

export class linkType extends Link {
  static create(value: string) {
    let node = super.create(value);
    node.removeAttribute('target');
    node.removeAttribute('rel');
    return node;
  }
}
