import { Button, Col, Form, Input, InputNumber, Row, Select, Switch } from 'antd';
import React, { useRef, useState } from 'react';
import ReactQuill from 'react-quill';

interface Props {
  field: any;
  formRef: any;
  language: string;
  isSaving: boolean;
}

const InstagramFeedModule = (props: Props) => {

  const large_toolbar = {
    toolbar: [
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false
    }
  };

  return (
    <>
      <Form.Item
        label='Title'
        name={[props.field.name, 'settings', 'title']}
        rules={[{ required: false }]}
      >
        <Input/>
      </Form.Item>
      <Form.Item
        label='Sub-title'
        name={[props.field.name, 'settings', 'subtitle', props.language]}
        rules={[{ required: false }]}
      >
        <ReactQuill theme='snow' modules={large_toolbar} />
      </Form.Item>
      <Form.Item
        label='Instagram profile id'
        name={[props.field.name, 'settings', 'instagram_profile']}
        rules={[{ required: false }]}
      >
        <Input/>
      </Form.Item>
      <Form.Item
        label='Number of pictures'
        name={[props.field.name, 'settings', 'num_of_images']}
        rules={[{ required: true, message: 'Please input the number of pictures to display' }]}
      >
        <InputNumber min={1}/>
      </Form.Item>
    </>
  );
};

export default InstagramFeedModule;
