import { ApiError } from '../types';
import Swal from 'sweetalert2';

export const handleError = (error: ApiError) => {
  if (error.statusCode === 401) {
    Swal.fire({
      title: 'Oops...',
      html: 'The email and password combination appears to be invalid',
      icon: 'error'
    });
  } else {
    Swal.fire({
      title: 'Oops...',
      html: 'Unable to login',
      icon: 'error',
      footer: error.message
    });
  }
};
