import * as React from 'react';
import QueueAnim from 'rc-queue-anim';

interface Props {
  type: string;
  newTitle: string;
  editTitle?: string;
  subTitle?: JSX.Element | string;
}

class FormHeader extends React.Component<Props> {
  public render = () => (
    <div className={'form-header'}>
      <img alt={'banner'} src={require('../../../assets/banner-form.jpg')} />
      <QueueAnim type={'scale'} duration={2500}>
        <p key={1}>
          {this.props.type === 'edit' ? this.props.editTitle : this.props.newTitle}
          {this.props.subTitle}
        </p>
      </QueueAnim>
    </div>
  );
}

export default FormHeader;
