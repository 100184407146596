import React, { Component } from 'react';
import TableComponent from './table.component';
import ProductTypeForm from '../../forms/product-type.form';
import { settings } from '../../../settings';

interface State {
  language: string;
}

class ProductTypesComponent extends Component<{}, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      language: settings.languages[0].code.toLowerCase()
    };
  }

  render() {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: true,
        render: (x: { [key: string]: string }) => x[this.state.language]
      }
    ];

    return (
      <TableComponent
        form={ProductTypeForm}
        url={'/product-types'}
        filter={{}}
        name={'Product Type'}
        title={'Product Types'}
        columns={columns}
        searchFields={['name']}
        actions={['view', 'add', 'edit', 'delete', 'languages']}
        onLanguageChange={(lang: string) => this.setState({ language: lang })}
      />
    );
  }
}

export default ProductTypesComponent;
