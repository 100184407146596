import React, { Component } from 'react';
import { Form, Input, Select, Tabs } from 'antd';
import FormHeaderComponent from '../components/forms/form-header.component';
import { formItemLayout } from '../components/forms/form-layouts.component';
import { FAQ } from '../types';
import { AuthState } from '../redux/states/user';
import { connect } from 'react-redux';
import { setFormData, updateFormData } from '../helpers/form.helper';
import { FormInstance } from 'antd/es/form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { settings } from '../../settings';

const { Option } = Select;
const { TabPane } = Tabs;

interface Props {
  auth: AuthState;
  type: string;
  visible: boolean;
  isSaving: boolean;
  object?: FAQ | null;
  formRef: React.RefObject<FormInstance>;
}

interface State {
  confirmLoading: boolean;
}

class FAQForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      confirmLoading: false
    };
  }

  componentDidMount(): void {
    setFormData(this.props, this.props.object, this.props.formRef);
  }

  componentWillUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any) {
    updateFormData(this, nextProps);
  }

  getForm = (language: string) => {
    return (
      <>
        <Form.Item
          label='Title'
          name={['title', language]}
          rules={[{ required: true, message: 'Please enter a title' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Content'
          name={['content', language]}
          rules={[{ required: true, message: 'Please enter the content' }]}
        >
          <ReactQuill theme='snow' />
        </Form.Item>

        <Form.Item
          label='Category'
          name={'category'}
          rules={[{ required: true, message: 'Please select a category' }]}
        >
          <Select placeholder='Please select a category'>
            <Option value='general'>General</Option>
            <Option value='order'>Order</Option>
            <Option value='product'>Product</Option>
            <Option value='payment'>Payment</Option>
          </Select>
        </Form.Item>
      </>
    );
  };

  render() {
    const faq = this.props.object;

    return (
      <>
        <FormHeaderComponent
          type={this.props.type}
          newTitle={faq ? '' : 'Add a new FAQ'}
          editTitle={faq ? `Edit ${faq.title[settings.languages[0].code]}` : ''}
        />

        <Form {...formItemLayout} ref={this.props.formRef} name='basic'>
          <Tabs defaultActiveKey={settings.languages[0].code.toLowerCase()}>
            {settings.languages.map((language, index) => {
              return (
                <TabPane forceRender={true} tab={language.name} key={language.code.toLowerCase()}>
                  {this.getForm(language.code.toLowerCase())}
                </TabPane>
              );
            })}
          </Tabs>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(FAQForm);
