import React, { useState } from "react";
import TableComponent from "./table.component";
import FAQForm from "../../forms/faq.form";
import { settings } from "../../../settings";

const FaqsComponent = () => {
  const [language, setLanguage] = useState(settings.languages[0].code.toLowerCase());

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      sorter: true,
      render: (x: { [key: string]: string }) => x[language],
    },
    { title: "Category", dataIndex: "category", sorter: true, responsive: ["sm"] },
    {
      title: "Content",
      dataIndex: "content",
      responsive: ["lg"],
      render: (x: { [key: string]: string }) => <div dangerouslySetInnerHTML={{ __html: x[language] }} />,
    },
  ];

  return (
    <TableComponent
      form={FAQForm}
      url={"/faqs"}
      filter={{}}
      name={"FAQ"}
      title={"Faqs"}
      columns={columns}
      searchFields={["title", "category", "content"]}
      actions={["view", "add", "edit", "delete", "languages"]}
      onLanguageChange={(lang: string) => setLanguage(lang)}
    />
  );
};

export default FaqsComponent;
