import React from 'react';
import TableComponent from './table.component';
import SettingsForm from '../../forms/settings.form';

const SettingsComponent = () => {
  const columns = [
    { title: 'Type', dataIndex: 'type', sorter: true },
    { title: 'Phone', dataIndex: 'phone', responsive: ['md'] },
    { title: 'Email', dataIndex: 'email', responsive: ['sm'] }
  ];

  return (
    <TableComponent
      form={SettingsForm}
      url={'/settings'}
      filter={{}}
      name={'Settings'}
      title={'Settings'}
      columns={columns}
      searchFields={[]}
      actions={['view', 'edit']}
    />
  );
};

export default SettingsComponent;
