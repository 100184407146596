import React, { useEffect, useState } from 'react';
import TableComponent from './table.component';
import { settings } from '../../../settings';
import { LoopBack } from '../../redux/api';
import { AuthState } from '../../redux/states/user';
import { Lookbook } from '../../types';
import { connect } from 'react-redux';
import LookbookSectionForm from '../../forms/lookbook-section.form';

interface Props {
  auth: AuthState;
}

const LookbookSectionsComponent = (props: Props) => {
  const [language, setLanguage] = useState(settings.languages[0].code.toLowerCase());
  const [lookbooks, setLookbooks] = useState<Lookbook[]>([]);

  useEffect(() => {
    new LoopBack(props.auth.user)
      .get('/lookbooks')
      .then((data) => {
        setLookbooks(data);
      })
      .catch(() => {
        setLookbooks([]);
      });
  }, []);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      render: (x: { [key: string]: string }) => x[language]
    },
    {
      title: 'Lookbook',
      dataIndex: 'lookbook_id',
      responsive: ['md'],
      render: (x: number) => {
        const lookbook = lookbooks.filter((book) => book.id === x);
        if (lookbook.length > 0) {
          return lookbook[0].name['en'];
        }
      }
    },
    {
      title: 'Url',
      dataIndex: 'slug',
      render: (x: string) => (
        <a
          rel={'noopener noreferrer'}
          target={'_blank'}
          href={process.env.REACT_APP_WEB_URL + settings.lookbook.apiUrl + x}
        >
          {x}
        </a>
      )
    },
    {
      width: 600,
      title: 'Summary',
      dataIndex: 'seo_description',
      sorter: true,
      responsive: ['xl'],
      render: (x: { [key: string]: string }) => (
        <div dangerouslySetInnerHTML={{ __html: x[language] }} />
      )
    }
  ];

  return (
    <TableComponent
      form={LookbookSectionForm}
      url={'/lookbook-sections'}
      filter={{}}
      name={`${settings.lookbook.label.toLowerCase()} Section`}
      title={`${settings.lookbook.label.toLowerCase()} Sections`}
      columns={columns}
      searchFields={['name']}
      actions={['view', 'add', 'edit', 'delete', 'languages']}
      onLanguageChange={(lang: string) => setLanguage(lang)}
    />
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(LookbookSectionsComponent);
