import { SettingsType } from './core-module/types';

export const settings: SettingsType = {
  courses: { enable: false, rootUrl: '' },
  blog: { enable: false },
  seoDescription: { max: 200, min: 65 },
  eventEnquiries: false,
  pages: {
    enableWaveLayout: false,
    modules: {
      sliderVideos: false,
      waitingListSignUp: false,
      banner: false,
      centeredParagraph: false,
      collapsedListItem: false,
      columnLayout: true,
      donationModule: false,
      giftCards: false,
      header: true,
      instagramFeed: false,
      highlightedProducts: false,
      howItWorks: false,
      imageWall: false,
      grid: false,
      images: false,
      latestProducts: false,
      linkTree: false,
      paragraph: false,
      photoGallery: false,
      slider: true,
      lookbooks: true,
      sliderPartners: false,
      textWithPictureBlock: false,
      twoColoredBlocks: false,
      upcomingEvents: false,
      webshop: false,
      newsletterSignup: false,
    }
  },
  lookbook: {
    apiUrl: '',
    enable: true,
    rootUrl: '/portfolio',
    label: 'Portfolio',
  },
  ingredients: { enable: false },
  home: { enablePaymentLink: false },
  productOccasions: { enable: false },
  productStyles: { enable: false },
  productTypes: { enable: false },
  embroidery: false,
  qrCodes: false,
  purchases: false,
  events: { sessions: { enable: false }, enable: false, rootUrl: '/events' },
  products: {
    tags: [],
    sku: true,
    spreadsheetMode: true,
    customUrl: false,
    threadColors: false,
    enable: false,
    colors: true,
    styles: true,
    occasions: true,
    url: '/the-boutique',
    personalise: false,
    relatedProducts: true,
    sizeInstructions: true,
    wearInstructions: true,
    careInstructions: true,
    enableContactMessage: true
  },
 webshop: {
    enable: false,
 },
  productThemes: {
    enable: false,
    enableImages: true,
    rootUrl: '/lookbook'
  },
  productCategories: {
    enableImages: true,
    rootUrl: '/lookbook'
  },
  orders: {
    showNonPaidOrders: true
  },
  enableGiftCartForVouchers: false,
  reduxStoreKey: 'kayane_designs_cms_v1',
  footer: {
    type: 'default',
    customerServiceLinks: false
  },
  languages: [
    {
      name: 'English',
      code: 'en'
    }
  ],
  settings: {
    mail: {
      templates: ['contact_admin']
    }
  }
};
