import React, { useEffect, useState } from 'react';
import Input, { InputProps } from 'antd/es/input/Input';
import { Button, List, Modal, Spin } from 'antd';
import { LoopbackFile } from '../../types';
import { LoopBack } from '../../redux/api';
import { AuthState } from '../../redux/states/user';
import { connect } from 'react-redux';
import '../../styles/filepicker.less';
import moment from 'moment';

interface Props extends InputProps {
  showAddon: boolean;
  auth: AuthState;
  formRef: any;
  rootKey: string;
  subKeys: (string | number)[];
  formKey: string;
  callback?: any;
  preloadedFileList?: LoopbackFile[];
}

const FormImageSelectorComponent = (props: Props) => {
  const [fileList, setFileList] = useState<LoopbackFile[]>(props.preloadedFileList ? props.preloadedFileList : []);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [showMore, setShowMore] = useState(false);
    const [showAll, setShowAll] = useState(false);


    const loadImages = (_showAll: boolean = false) => {
        setLoading(true);
        new LoopBack(props.auth.user)
            .get(`/containers/images/files`)
            .then((res) => {
                const files = res as LoopbackFile[];
                const apiUrl = process.env.REACT_APP_API_URL;
                const fileList = files
                    .map((file, index) => {
                        file.uid = file.mtime + Math.random();
                        file.type = 'image';
                        file.url = `${apiUrl}/containers/${file.container}/download/${file.name}`;
                        file.apiPath = `/images/${file.name}`;
                        return file;
                    });
                const _fileList = fileList.sort((a: any, b: any) => (a.uid > b.uid ? 1 : -1)).reverse();
                setFileList(_fileList);
                setLoading(false);
                if (props.callback) {
                    props.callback(_fileList)
                }
            })
            .catch(() => {
                setLoading(false);
            });
    }

  useEffect(() => {

    if (props.preloadedFileList && props.preloadedFileList.length > 0) {
      setFileList(props.preloadedFileList);
      setLoading(false);
    } else {
      if (modalVisible && fileList.length === 0) {
        loadImages();
      }
    }
  }, [modalVisible]);

  const updateJsonByPath = async (jsonObject: any, path: (string | number)[], value: any) => {
    if (path.length === 1) {
      if (jsonObject[path[0]]) {
        jsonObject[path[0]][props.formKey] = value;
      } else {
        jsonObject[path[0]] = { [props.formKey]: value };
      }
    } else {
      if (jsonObject[path[0]]) {
        await updateJsonByPath(jsonObject[path[0]], path.slice(1), value);
      } else {
        // create an object with the remaining keys
        let newData = { [props.formKey]: value };
        let reversePath = [...path].slice(1, path.length).reverse();
        reversePath.forEach((key) => (newData = { [key]: newData }));
        jsonObject[path[0]] = newData;
      }
    }
  };

  const setValue = async (value: string | null) => {
    if (props.formRef && props.formRef.current) {
      let data: any = props.formRef.current.getFieldValue(props.rootKey);

      if (props.subKeys.length > 0) {
        // image is part of subform
        await updateJsonByPath(data, props.subKeys, value);
      } else if (Array.isArray(data)) {
        // image is directly set in root of form but is of type array (multiple pictures)
        data[parseInt(props.formKey)] = value;
      } else {
        // image is directly set in root of form
        data = value;
      }

      props.formRef.current.setFieldsValue({ [props.rootKey]: data });
    }
  };

  const onSelectedImage = (file: LoopbackFile) => {
    setValue(file.apiPath);
    setModalVisible(false);
  };

  const onClear = () => {
    setValue(null);
  };

  return (
    <>
      <Input
          style={{width: '80%'}}
        allowClear={true}
        addonBefore={props.showAddon ? process.env.REACT_APP_API_URL : undefined}
        defaultValue={props.defaultValue}
        value={props.value}
        onClick={() => {
          setModalVisible(true);
        }}
        onChange={(e: any) => {
          if (e.currentTarget.value === '') {
            onClear();
          }
        }}
        placeholder='/path/to/image.jpg'
      />

      {props.value &&
          <img width={'20%'} style={{paddingLeft: 10}} src={`${(process.env.REACT_APP_API_URL as string) + props.value}`}/>
      }

      <Modal
        title={'Image Library'}
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
        }}
        onOk={() => {
          setModalVisible(false);
        }}
        width={'70%'}
        footer={[
          <Button
            key='Cancel'
            onClick={() => {
              setModalVisible(false);
            }}
          >
            Cancel
          </Button>
        ]}
      >
        {loading ? (
          <div>
            <Spin spinning={true} /> Loading (this may take a minute)...
          </div>
        ) : (
          <>
            <List
              style={{ marginTop: 20 }}
              grid={{ gutter: 16, column: 8 }}
              dataSource={showMore ? showAll ? fileList : fileList.slice(0,75) : fileList.slice(0, 20)}
              renderItem={(file) => (
                <List.Item>
                  {file.name.includes('.mp4') ? (
                    <div
                      title={file.name}
                      className='filepicker-image grow'
                      onClick={() => onSelectedImage(file)}
                    >
                      <video
                        src={`${process.env.REACT_APP_API_URL}/images/${file.name}`}
                        autoPlay={true}
                        muted={true}
                        loop={true}
                        playsInline={true}
                      />
                    </div>
                  ) : (
                      <>
                    <div
                      title={file.name}
                      className='filepicker-image grow'
                      style={{
                        backgroundImage: `url('${process.env.REACT_APP_API_URL}/images/${file.name}')`
                      }}
                      onClick={() => onSelectedImage(file)}
                    />
                    </>
                  )}

                </List.Item>
              )}
            />

              {!showAll &&
                  <>
                      {!showMore ?
                          <Button type={'primary'} onClick={() => setShowMore(true)}>
                              {'Show More Pictures...'}
                          </Button>
                          :
                          <Button type={'primary'} onClick={() => {
                              setShowAll(true);
                          }}>
                              {'Show All Pictures'}
                          </Button>
                      }
                  </>
              }
          </>
        )}
      </Modal>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(FormImageSelectorComponent);
