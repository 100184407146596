import { Form, Input } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AuthState } from '../../redux/states/user';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface Props {
  auth: AuthState;
  field: any;
  formRef: any;
  language: string;
  isSaving: boolean;
}

class GiftCardsModule extends Component<Props> {
  render() {
    return (
      <>
        <Form.Item
          label='Title'
          name={[this.props.field.name, 'settings', 'title', this.props.language]}
          rules={[{ required: false }]}
        >
          <Input placeholder='Title of this block' />
        </Form.Item>
        <Form.Item
          label='Sub-title'
          name={[this.props.field.name, 'settings', 'description', this.props.language]}
          rules={[{ required: false }]}
        >
          <ReactQuill theme='snow' />
        </Form.Item>
        <Form.Item
          label='Background color'
          name={[this.props.field.name, 'settings', 'background_color']}
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(GiftCardsModule);
