import React, { Component, useEffect, useState } from 'react';
import TableComponent from './table.component';
import ProductForm from '../../forms/product.form';
import { formatPrice } from '../../helpers/price.helper';
import {
  Color,
  Product,
  ProductCategory,
  ProductColor,
  ProductOccasion,
  ProductStyle,
  ProductTheme,
  ProductType
} from '../../types';
import { AuthState } from '../../redux/states/user';
import { connect } from 'react-redux';
import { settings } from '../../../settings';
import StatusIcon from '../app/status-icon';
import { Button, Col, message, Modal, Row, Select, Spin, Badge, Input, List, Avatar } from 'antd';
import { LoopBack } from '../../redux/api';
import { DeleteOutlined, ExportOutlined, RightOutlined } from '@ant-design/icons';
import FileDownload from 'js-file-download';
import Swal from 'sweetalert2';
import TextArea from 'antd/es/input/TextArea';

interface Props {
  auth: AuthState;
}

const ProductsComponent = (props: Props) => {
  const [product, setProduct] = useState<Product | null>(null);
  const [language, setLanguage] = useState(settings.languages[0].code.toLowerCase());
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [productTypes, setProductTypes] = useState<ProductType[]>([]);
  const [productCategories, setProductCategories] = useState<ProductCategory[]>([]);
  const [productOccasions, setProductOccasions] = useState<ProductOccasion[]>([]);
  const [productColors, setProductColors] = useState<ProductColor[]>([]);
  const [activateSpreadSheetMode, setActivateSpreadsheetMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSheet, setLoadingSheet] = useState(false);

  const [hideActionsColumn, setHideActionsColumn] = useState(false);
  const [productStyles, setProductStyles] = useState<ProductStyle[]>([]);
  const [productThemes, setProductThemes] = useState<ProductTheme[]>([]);
  const [productIds, setProductIds] = useState([]);
  const [searchedProducts, setSearchedProducts] = useState<Product[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [lineSheetProducts, setLineSheetProducts] = useState<Product[]>([]);
  const [customText, setCustomText] = useState<string | null>(null);
  const [lineSheetModalVisible, setLineSheetModalVisible] = useState(false);
  const [lineSheetProductStyleFilter, setLineSheetProductStyleFilter] = useState<null | number>(
    null
  );
  const [lineSheetProductCategoryFilter, setLineSheetProductCategoryFilter] = useState<
    null | number
  >(null);
  const [lineSheetProductThemeFilter, setLineSheetProductThemeFilter] = useState<null | number>(
    null
  );
  const [lineSheetProductTypeFilter, setLineSheetProductTypeFilter] = useState<null | number>(null);

  useEffect(() => {
    new LoopBack(props.auth.user)
      .get(`/product-types`)
      .then((res: ProductType[]) => {
        setProductTypes(res.sort((a, b) => (a.name['en'] > b.name['en'] ? 1 : -1)));
      })
      .catch((err) => {
        setProductTypes([]);
      });

    new LoopBack(props.auth.user)
      .get(`/product-categories`)
      .then((res: ProductCategory[]) => {
        setProductCategories(res.sort((a, b) => (a.name['en'] > b.name['en'] ? 1 : -1)));
      })
      .catch((err) => {
        setProductCategories([]);
      });

    new LoopBack(props.auth.user)
      .get(`/product-occasions`)
      .then((res: ProductOccasion[]) => {
        setProductOccasions(res.sort((a, b) => (a.name['en'] > b.name['en'] ? 1 : -1)));
      })
      .catch((err) => {
        setProductOccasions([]);
      });

    new LoopBack(props.auth.user)
      .get(`/product-styles`)
      .then((res: { data: ProductStyle[] }) => {
        setProductStyles(res.data.sort((a, b) => (a.name['en'] > b.name['en'] ? 1 : -1)));
      })
      .catch((err) => {
        setProductStyles([]);
      });

    new LoopBack(props.auth.user)
      .get(`/product-themes`)
      .then((res: ProductTheme[]) => {
        setProductThemes(res.sort((a, b) => (a.name['en'] > b.name['en'] ? 1 : -1)));
      })
      .catch((err) => {
        setProductThemes([]);
      });

    new LoopBack(props.auth.user)
      .get(`/colors`)
      .then((res: Color[]) => {
        setProductColors(res.sort((a, b) => (a.name['en'] > b.name['en'] ? 1 : -1)));
      })
      .catch((err) => {
        setProductColors([]);
      });
  }, []);

  const showImage = (x: Product) => {
    if (x.pictures && x.pictures[0] && process.env.REACT_APP_API_URL) {
      setImageModalVisible(true);
      setProduct(x);
    }
  };

  const onSearchChange = (_searchValue: string) => {
    if (_searchValue && _searchValue.trim() !== '') {
      setSearchValue(_searchValue);
      const lang = 'en';
      new LoopBack()
        .get(`/products`)
        .then((res) => {
          let filtered = res.filter(
            (product: Product) => {

              const includesSearchValue = product.name[lang].toLowerCase().includes(_searchValue);
              const styleIncludesSearchValue = productStyles.some(
                (style) =>
                  style.id === product.product_style_id &&
                  style.name[lang].toLowerCase().includes(_searchValue)
              );
              const typeIncludesSearchValue = productTypes.some(
                (type) =>
                  type.id === product.product_type_id &&
                  type.name[lang].toLowerCase().includes(_searchValue)
              );
              return includesSearchValue || styleIncludesSearchValue || typeIncludesSearchValue;
            }
          );

          setSearchedProducts(filtered);
          // setSearching(false);
        })
        .catch((res) => {
          // setSearching(false);
          setSearchedProducts([]);
          // setValue(undefined);
        });
    } else {
      setSearchedProducts([]);
    }
  };
  // };

  const onProductTypeChange = (productId: number, value: number) => {
    setLoading(true);
    new LoopBack(props.auth.user)
      .patch(`/products/${productId}`, { product_type_id: value })
      .then(() => {
        message.success('Product has been successfully updated');
      })
      .catch(() => {
        message.error('Something went wrong. The product could not be updated');
      });
  };

  const onProductCategoryChange = (productId: number, value: number) => {
    setLoading(true);
    new LoopBack(props.auth.user)
      .patch(`/products/${productId}`, { product_category_id: value })
      .then(() => {
        message.success('Product has been successfully updated');
      })
      .catch(() => {
        message.error('Something went wrong. The product could not be updated');
      });
  };

  const onProductOccasionChange = (productId: number, value: number) => {
    setLoading(true);
    new LoopBack(props.auth.user)
      .patch(`/products/${productId}`, { product_occasion_id: value })
      .then(() => {
        message.success('Product has been successfully updated');
      })
      .catch(() => {
        message.error('Something went wrong. The product could not be updated');
      });
  };

  const onProductColorChange = (productId: number, value: number) => {
    setLoading(true);
    new LoopBack(props.auth.user)
      .patch(`/products/${productId}`, { color_id: value })
      .then(() => {
        message.success('Product has been successfully updated');
      })
      .catch(() => {
        message.error('Something went wrong. The product could not be updated');
      });
  };

  const onProductThemeChange = (productId: number, value: number) => {
    setLoading(true);
    new LoopBack(props.auth.user)
      .patch(`/products/${productId}`, { product_theme_id: value })
      .then(() => {
        message.success('Product has been successfully updated');
      })
      .catch(() => {
        message.error('Something went wrong. The product could not be updated');
      });
  };

  const onProductStyleChange = (productId: number, value: number) => {
    setLoading(true);
    new LoopBack(props.auth.user)
      .patch(`/products/${productId}`, { product_style_id: value })
      .then(() => {
        message.success('Product has been successfully updated');
      })
      .catch(() => {
        message.error('Something went wrong. The product could not be updated');
      });
  };

  const onExportClick = () => {
    setLoading(true);
    new LoopBack(props.auth.user)
      .download(`/products/export`)
      .then((res) => {
        message.success('Product has been successfully exported');
        FileDownload(res, 'all-products.xls');
      })
      .catch(() => {
        message.error('Something went wrong. The products could not be exported');
      });
  };

  const onReloadFinish = () => {
    setLoading(false);
  };

  let columns: any = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      render: (x: { [key: string]: string }, r: Product) => {
        if (activateSpreadSheetMode) {
          return `${x[language]}`;
        }
        return (
          process.env.REACT_APP_API_URL &&
          r.pictures &&
          r.pictures[0] && (
            <Badge.Ribbon
              color={'red'}
              className={r.sale === null || r.sale === 0 ? 'in-active' : ''}
              text={r.sale && r.sale > 0 ? `${r.sale}%` : undefined}
            >
              <Row align={'middle'}>
                <img
                  onClick={() => showImage(r)}
                  style={{ display: 'inline-block', maxWidth: '100%' }}
                  src={process.env.REACT_APP_API_URL + r.pictures[0]}
                />
                <span>{x[language]}</span>
              </Row>
            </Badge.Ribbon>
          )
        );
      }
    },
    {
      title: 'Url',
      dataIndex: 'slug',
      sorter: true,
      responsive: ['xl'],
      render: (x: string) => (
        <a
          rel={'noopener noreferrer'}
          target={'_blank'}
          href={process.env.REACT_APP_WEB_URL + settings.products.url + x}
        >
          {x}
        </a>
      )
    }
  ];

  if (settings.products.sku) {
    columns.push({
      title: 'SKU',
      dataIndex: 'sku',
      sorter: true,
      responsive: ['xl']
    });
  }

  if (activateSpreadSheetMode) {
    if (settings.productStyles.enable) {
      columns.push({
        title: 'Style',
        responsive: ['xxl'],
        render: (product: Product) => {
          if (activateSpreadSheetMode) {
            return (
              <Select
                value={product.product_style_id}
                onChange={(e) => onProductStyleChange(product.id, e)}
              >
                {productStyles &&
                  productStyles.map((style) => {
                    return (
                      <Select.Option key={style.id} value={style.id}>
                        {style.name[language]}
                      </Select.Option>
                    );
                  })}
              </Select>
            );
          }
          return product.product_style ? product.product_style.name[language] : '-';
        }
      });
    }
  }

  columns = columns.concat([
    {
      title: 'Category',
      responsive: ['xxl'],
      render: (product: Product) => {
        if (activateSpreadSheetMode) {
          return (
            <Select
              value={product.product_category_id}
              onChange={(e) => onProductCategoryChange(product.id, e)}
            >
              {productCategories &&
                productCategories.map((category) => {
                  return (
                    <Select.Option key={category.id} value={category.id}>
                      {category.name[language]}
                    </Select.Option>
                  );
                })}
            </Select>
          );
        }
        return product.product_category ? product.product_category.name[language] : '-';
      }
    }
  ]);

  if (settings.productOccasions.enable) {
    columns.push({
      title: 'Occasion',
      responsive: ['xxl'],
      render: (product: Product) => {
        if (activateSpreadSheetMode) {
          return (
            <Select
              value={product.product_occasion_id}
              onChange={(e) => onProductOccasionChange(product.id, e)}
            >
              {productOccasions &&
                productOccasions.map((occasion) => {
                  return (
                    <Select.Option key={occasion.id} value={occasion.id}>
                      {occasion.name[language]}
                    </Select.Option>
                  );
                })}
            </Select>
          );
        }
        return product.product_occasion ? product.product_occasion.name[language] : '-';
      }
    });
  }

  if (activateSpreadSheetMode) {
    if (settings.productThemes.enable) {
      columns.push({
        title: 'Theme',
        responsive: ['xxl'],
        render: (product: Product) => {
          if (activateSpreadSheetMode) {
            return (
              <Select
                value={product.product_theme_id}
                onChange={(e) => onProductThemeChange(product.id, e)}
              >
                {productThemes &&
                  productThemes.map((theme) => {
                    return (
                      <Select.Option key={theme.id} value={theme.id}>
                        {theme.name[language]}
                      </Select.Option>
                    );
                  })}
              </Select>
            );
          }
          return product.product_theme ? product.product_theme.name[language] : '-';
        }
      });
    }
  }

  if (settings.productTypes.enable) {
    columns = columns.concat([
      {
        title: 'Type',
        responsive: ['xxl'],
        render: (product: Product) => {
          if (activateSpreadSheetMode) {
            return (
              <Select
                value={product.product_type_id}
                onChange={(e) => onProductTypeChange(product.id, e)}
              >
                {productTypes &&
                  productTypes.map((type) => {
                    return (
                      <Select.Option key={type.id} value={type.id}>
                        {type.name[language]}
                      </Select.Option>
                    );
                  })}
              </Select>
            );
          }
          return product.product_type ? product.product_type.name[language] : '-';
        }
      }
    ]);
  }

  if (settings.products.colors) {
    columns.push({
      title: 'Color',
      responsive: ['xl'],
      render: (product: Product) => {
        if (activateSpreadSheetMode) {
          return (
            <Select value={product.color_id} onChange={(e) => onProductColorChange(product.id, e)}>
              {productColors &&
                productColors.map((color) => {
                  return (
                    <Select.Option key={color.id} value={color.id}>
                      {color.name[language]}
                    </Select.Option>
                  );
                })}
            </Select>
          );
        }
        return product.color ? product.color.name[language] : '-';
      }
    });
  }

  columns = columns.concat([
    {
      title: 'Stock',
      dataIndex: '',
      responsive: ['xl'],
      render: (x: Product) => (x.manage_stock ? x.stock : '-')
    },
    {
      title: 'Active',
      dataIndex: 'active',
      render: (x: boolean) => new StatusIcon().render(x),
      responsive: ['lg']
    },
    {
      width: 100,
      title: 'Price',
      sorter: true,
      dataIndex: 'price',
      responsive: ['sm'],
      render: (price: number) => <span dangerouslySetInnerHTML={{ __html: formatPrice(price) }} />
    }
  ]);

  const filter = {
    include: [
      { relation: 'color' },
      { relation: 'product_type' },
      { relation: 'product_category' },
      { relation: 'product_occasion' }
    ]
  };

  const onExportLineSheetClick = () => {
    setLoadingSheet(true);

    new LoopBack(props.auth.user)
      .downloadPost(`/products/export-to-linesheet`,{ids: lineSheetProducts.map((p) => p.id), customText: customText})
      .then((res) => {
        message.success('Linesheet has been successfully generated');
        FileDownload(res, 'linesheet.pdf');
        setLoadingSheet(false);

      })
      .catch(() => {
        setLoadingSheet(false);
        message.error('Unable to generate linesheet due to an error.');
      });
  };

  const removeLineSheetProduct = (product: Product) => {
    const pIndex = lineSheetProducts.findIndex((p) => p.id === product.id);
    if (pIndex > -1) {
      const newList = [...lineSheetProducts].filter((p) => p.id !== product.id);
      setLineSheetProducts(newList);
    }
  };

  const addLineSheetProduct = (product: Product) => {
    const pIndex = lineSheetProducts.findIndex((p) => p.id === product.id);
    if (pIndex > -1) {
      Swal.fire({
        title: 'Already Added',
        html: `This product is already added to the list`,
        icon: 'warning'
      });
    } else {
      const currentList = [...lineSheetProducts];
      currentList.push(product);
      setLineSheetProducts(currentList);
    }
  };

  let filteredProducts = [...searchedProducts];

  if (lineSheetProductCategoryFilter) {
    filteredProducts = filteredProducts.filter((p: Product) => p.product_category_id === lineSheetProductCategoryFilter)
  }

  if (lineSheetProductStyleFilter) {
    filteredProducts = filteredProducts.filter((p: Product) => p.product_style_id === lineSheetProductStyleFilter)
  }

  if (lineSheetProductTypeFilter ) {
    filteredProducts = filteredProducts.filter((p: Product) => p.product_type_id === lineSheetProductTypeFilter)
  }

  if (lineSheetProductThemeFilter) {
    filteredProducts = filteredProducts.filter((p: Product) => p.product_theme_id === lineSheetProductThemeFilter)
  }

  return (
    <>
      <TableComponent
        form={ProductForm}
        url={`/products/table`}
        postUrl={'/products'}
        filter={filter}
        name={'Product'}
        title={'Products'}
        columns={columns}
        searchFields={['name', 'slug']}
        actions={['view', 'add', 'edit', 'delete', 'qr', 'languages']}
        onLanguageChange={(lang: string) => setLanguage(lang)}
        loading={loading}
        onReloadFinish={() => onReloadFinish()}
        hideActionsColumn={hideActionsColumn}
        customButtonBar={
          window.outerWidth > 768 && settings.products.spreadsheetMode
            ? [
                <Button style={{ marginRight: 5 }} key={1} type={'primary'} onClick={onExportClick}>
                  <ExportOutlined /> Export
                </Button>,
                <Button
                  style={{ marginRight: 5 }}
                  key={2}
                  type={'primary'}
                  onClick={() => setLineSheetModalVisible(true)}
                >
                  <ExportOutlined /> Export Line sheet..
                </Button>,
                <Button
                  style={{ marginRight: 5 }}
                  key={0}
                  danger={activateSpreadSheetMode}
                  type={activateSpreadSheetMode ? 'primary' : 'default'}
                  onClick={() => {
                    setHideActionsColumn(!hideActionsColumn);
                    setActivateSpreadsheetMode(!activateSpreadSheetMode);
                  }}
                >
                  {activateSpreadSheetMode
                    ? 'Deactivate Spreadsheet mode'
                    : 'Activate Spreadsheet mode'}
                </Button>
              ]
            : []
        }
      />
      <Modal
        title={product ? product.name[language] + ' - ' + product.subtitle[language] : 'Image'}
        visible={imageModalVisible}
        onCancel={() => {
          setImageModalVisible(false);
        }}
        onOk={() => {
          setImageModalVisible(false);
        }}
        width={500}
        footer={[
          <Button
            key='Cancel'
            onClick={() => {
              setImageModalVisible(false);
            }}
          >
            Close
          </Button>
        ]}
      >
        {product && product.pictures && process.env.REACT_APP_API_URL && (
          <img
            style={{ height: 'auto', width: '100%' }}
            src={process.env.REACT_APP_API_URL + product.pictures[0]}
          />
        )}
      </Modal>

      <Modal
        footer={[
          <Button style={{ float: 'left' }} onClick={() => setLineSheetModalVisible(false)}>
            Cancel
          </Button>,
          <Button disabled={loadingSheet} type={'primary'} onClick={() => onExportLineSheetClick()}>
            {loadingSheet ? 'Generating...' : 'Generate'}
          </Button>
        ]}
        width={'80%'}
        closable={false}
        visible={lineSheetModalVisible}
        title={'Export linesheet'}
      >
        <Row className={'linesheet-generator'}>
          <Col xs={12}>
            <Row gutter={[10, 10]}>
              <Col xs={24}>
                <Input
                  onChange={(e) => onSearchChange(e.currentTarget.value)}
                  placeholder={'Search...'}
                />
              </Col>
              <Col xs={12}>
                <Select
                  allowClear={true}
                  onChange={(e) => {
                    setLineSheetProductTypeFilter(e);
                  }}
                  style={{ width: '100%' }}
                  placeholder={'Filter Product type...'}
                >
                  {productTypes &&
                    productTypes.map((type) => {
                      return (
                        <Select.Option key={type.id} value={type.id}>
                          {type.name[language]}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Col>
              <Col xs={12}>
                <Select
                  allowClear={true}
                  onChange={(e) => {
                    setLineSheetProductThemeFilter(e);
                  }}
                  style={{ width: '100%' }} placeholder={'Filter Product theme...'}>
                  {productThemes &&
                    productThemes.map((theme) => {
                      return (
                        <Select.Option key={theme.id} value={theme.id}>
                          {theme.name[language]}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Col>
              <Col xs={12}>
                <Select
                  allowClear={true}
                  onChange={(e) => {
                    setLineSheetProductStyleFilter(e);
                    // setTimeout(() => {
                    //   onSearchChange(searchValue);
                    // },1000)
                  }}
                  style={{ width: '100%' }} placeholder={'Filter Product style...'}>
                  {productStyles &&
                    productStyles.map((style) => {
                      return (
                        <Select.Option key={style.id} value={style.id}>
                          {style.name[language]}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Col>

              <Col xs={12}>
                <Select
                  allowClear={true}
                  onChange={(e) => {
                    setLineSheetProductCategoryFilter(e);
                  }}
                  style={{ width: '100%' }} placeholder={'Filter Product category...'}>
                  {productCategories &&
                    productCategories.map((category) => {
                      return (
                        <Select.Option key={category.id} value={category.id}>
                          {category.name[language]}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Col>
            </Row>

            <hr />

            <Row>
              <Col xs={24}>
                <List
                  itemLayout='horizontal'
                  dataSource={filteredProducts}
                  renderItem={(item: Product) => (
                    <List.Item
                      key={item.id}
                      onClick={() => addLineSheetProduct(item)}
                      extra={<RightOutlined size={40} />}
                    >
                      {item.pictures && (
                        <List.Item.Meta
                          avatar={
                            <Avatar
                              src={(process.env.REACT_APP_API_URL as string) + item.pictures[0]}
                            />
                          }
                          title={item.name['en']}
                          description={
                            <div dangerouslySetInnerHTML={{ __html: item.description['en'] }} />
                          }
                        />
                      )}
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          </Col>

          <Col xs={12} style={{ paddingLeft: 20 }}>
            <div style={{ backgroundColor: '#eee', padding: 20 }}>

              <span>Custom Text (optional):</span>
              <TextArea onChange={(e) => setCustomText(e.currentTarget.value)}/>
              <span style={{fontStyle: 'italic', fontSize: 10, color:'#aaa'}}>The text entered above will be shown at the top right of each page in the PDF</span>

              <hr/>

              <h3>Final List</h3>

              <List
                itemLayout='horizontal'
                dataSource={lineSheetProducts}
                renderItem={(item: Product) => (
                  <List.Item
                    key={item.id}
                    extra={
                      <div
                        onClick={() => removeLineSheetProduct(item)}
                        style={{
                          width: 40,
                          height: 40,
                          paddingTop: 10,
                          textAlign: 'center',
                          backgroundColor: '#aaa',
                          borderRadius: 50
                        }}
                      >
                        <DeleteOutlined size={40} />
                      </div>
                    }
                  >
                    {item.pictures && (
                      <List.Item.Meta
                        avatar={
                          <Avatar
                            src={(process.env.REACT_APP_API_URL as string) + item.pictures[0]}
                          />
                        }
                        title={item.name['en']}
                        description={
                          <div dangerouslySetInnerHTML={{ __html: item.description['en'] }} />
                        }
                      />
                    )}
                  </List.Item>
                )}
              />
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(ProductsComponent);
