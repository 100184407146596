import React, { useState } from 'react';
import TableComponent from './table.component';
import { settings } from '../../../settings';
import LookbooksForm from '../../forms/lookbooks.form';

const categories: any = {
  campaigns: 'Campaigns',
  classics: 'Classics',
  fashion_shows: 'Fashion Shows'
}

const LookbooksComponent = () => {
  const [language, setLanguage] = useState(settings.languages[0].code.toLowerCase());

  const columns = [
    {
      title: 'Year',
      dataIndex: 'year',
      sorter: true
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      render: (x: { [key: string]: string }) => x[language]
    },
    {
      title: 'Category',
      dataIndex: 'category',
      sorter: true,
      render: (x: string) => categories[x]
    },
    {
      title: 'Url',
      dataIndex: 'slug',
      responsive: ['sm'],
      render: (x: string) => (
        <a
          rel={'noopener noreferrer'}
          target={'_blank'}
          href={process.env.REACT_APP_WEB_URL + settings.lookbook.apiUrl + x}
        >
          {x}
        </a>
      )
    },
    {
      width: 600,
      title: 'Summary',
      dataIndex: 'seo_description',
      responsive: ['xl'],
      sorter: true,
      render: (x: { [key: string]: string }) => (
        <div dangerouslySetInnerHTML={{ __html: x[language] }} />
      )
    }
  ];

  return (
    <TableComponent
      form={LookbooksForm}
      url={'/lookbooks'}
      filter={{}}
      name={settings.lookbook.label}
      title={settings.lookbook.label}
      columns={columns}
      searchFields={['name']}
      actions={['view', 'add', 'edit', 'delete', 'languages']}
      onLanguageChange={(lang: string) => setLanguage(lang)}
    />
  );
};

export default LookbooksComponent;
