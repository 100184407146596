import React, { useState } from 'react';
import TableComponent from './table.component';
import StatusIcon from '../app/status-icon';
import VoucherForm from '../../forms/voucher.form';
import { settings } from '../../../settings';

const VouchersComponent = () => {
  const [language, setLanguage] = useState(settings.languages[0].code.toLowerCase());

  const columns = [
    { title: 'Name', dataIndex: 'name', sorter: true },
    { title: 'Code', dataIndex: 'code', sorter: true, responsive: ['sm'] },
    { title: 'Type', dataIndex: 'type', sorter: true, responsive: ['lg'] },
    { title: 'Currency', dataIndex: 'currency', sorter: true, responsive: ['sm'] },
    { title: 'Value', dataIndex: 'value', sorter: true, responsive: ['xl'] },
    { title: 'Max usages', dataIndex: 'max_usages', responsive: ['xxl'] },
    { title: 'Times used', dataIndex: 'times_used', responsive: ['xxl'] },
    {
      title: 'New customers only?',
      dataIndex: 'new_customers_only',
      render: (x: boolean) => new StatusIcon().render(x),
      responsive: ['xl']
    },
    {
      title: 'Part of Gift card',
      dataIndex: 'part_of_gift_card',
      render: (x: boolean) => new StatusIcon().render(x),
      responsive: ['xl']
    },
    {
      title: 'Active',
      dataIndex: 'active',
      render: (x: boolean) => new StatusIcon().render(x),
      responsive: ['lg']
    }
  ];

  return (
    <TableComponent
      form={VoucherForm}
      url={'/vouchers'}
      filter={{}}
      name={'Voucher'}
      title={'Vouchers'}
      columns={columns}
      searchFields={['code']}
      actions={['view', 'add', 'edit', 'delete', 'languages']}
      onLanguageChange={(lang: string) => setLanguage(lang)}
    />
  );
};

export default VouchersComponent;
