import React, { Component } from 'react';
import { Layout, message, Modal, Row, Spin, Upload, Button } from 'antd';
import NavbarComponent from '../navigation/navbar.component';
import SiderComponent from '../navigation/sider.component';
import { UploadFile } from 'antd/es/upload/interface';
import { LoopBack } from '../../redux/api';
import { connect } from 'react-redux';
import { AuthState } from '../../redux/states/user';
import { PlusOutlined } from '@ant-design/icons';

const { Content } = Layout;

interface State {
  existingFiles: any[];
  fileList: any[];
  previewVisible: boolean;
  previewImage: string;
  previewTitle: string;
  loading: boolean;
  showMore: boolean;
  showAll: boolean;
}

interface Props {
  auth: AuthState;
}

class ImagesComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      existingFiles: [],
      fileList: [],
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      loading: true,
      showMore: false,
      showAll: false,
    };
  }

  checkFiles = () => {
    new LoopBack(this.props.auth.user)
      .get('/containers/images/files')
      .then((res: any) => {
        let files: any = [];
        res.forEach((file: any) => {
          const uploadedFile = {
            uid: file.mtime + Math.random(),
            name: file.name,
            url: process.env.REACT_APP_API_URL + `/${file.container}/${file.name}`,
            status: 'done'
          };
          files.push(uploadedFile);
        });
        files = files.sort((a: any, b: any) => (a.uid > b.uid ? 1 : -1)).reverse();
        this.setState({ fileList: files, loading: false });
      })
      .catch((res) => this.setState({ loading: false, fileList: [] }));
  };

  componentDidMount(): void {
    this.checkFiles();
  }

  handleCancel = () => this.setState({ previewVisible: false });

  getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    });
  };

  handleChange = ({ fileList }: any) => {
    let currentList = fileList.length > 0 && this.state.fileList ? this.state.fileList : [];

    if (fileList.length > currentList.length) {
      const newFile = fileList[fileList.length - 1];
      if (newFile) {
        const existsInList = currentList.some((file) => file.name === newFile.name);
        if (!existsInList) {
          currentList.push(fileList[fileList.length - 1]);
        }
      }
    } else {
      currentList = fileList;
    }

    this.setState({ fileList: currentList.sort((a: any, b: any) => (a.uid > b.uid ? 1 : -1)).reverse() });
  };

  handleDelete = (file: UploadFile) => {
    new LoopBack(this.props.auth.user)
      .delete(`/containers/images/files/${file.name}`)
      .then((res) => {})
      .catch((err) => {});
  };

  beforeUpload = (file: File) => {
    const existsInList = this.state.fileList
      ? this.state.fileList.some((_file) => _file.name === file.name)
      : false;

    if (existsInList) {
      message.error(
        'This file already exists. Upload is prevented. Rename the file in case you want to re-upload it.'
      );
      return false;
    }
    return true;
  };

  render() {
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div className='ant-upload-text'>Upload</div>
      </div>
    );
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;

    return (
      <Layout className={'app-container'}>
        <NavbarComponent />
        <Layout className='site-layout'>
          <SiderComponent />
          <Content>
            <div className={'app-container-body'}>
              <div className={'app-container-content'}>
                <div className={'app-container-content-title'}>Manage Images</div>
                <Row>
                  <div className='clearfix' style={{ width: '100%' }}>
                    <Spin style={{ marginRight: 20 }} spinning={this.state.loading} />
                    {!this.state.loading ? (
                      <>
                        <Upload
                          headers={{ Authorization: `Bearer ${this.props.auth.user.token}` }}
                          accept={'.jpg,.png,.JPEG,.JPG,.jpeg,.PNG,.gif,.GIF,.webp,.WEBP,.mp4'}
                          action={process.env.REACT_APP_API_URL + '/containers/images/upload'}
                          listType='picture-card'
                          fileList={this.state.showMore ? fileList : fileList.slice(0,100)}
                          onPreview={this.handlePreview}
                          onChange={this.handleChange}
                          onRemove={this.handleDelete}
                          beforeUpload={this.beforeUpload}
                        >
                          {uploadButton}
                        </Upload>
                        {!this.state.showMore &&
                            <Button type={'primary'} onClick={() => {
                              this.setState({showMore: true})
                            }}>
                              {'Show all Pictures...'}
                            </Button>
                        }
                      </>
                    ) : (
                      <span>Loading images...</span>
                    )}
                    <Modal
                      visible={previewVisible}
                      title={previewTitle}
                      footer={null}
                      onCancel={this.handleCancel}
                    >
                      <img alt='example' style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                  </div>
                </Row>
              </div>
            </div>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(ImagesComponent);
