import React, { Component } from 'react';
import NavbarComponent from '../navigation/navbar.component';
import { Input, Layout } from 'antd';
import SiderComponent from '../navigation/sider.component';
import FooterComponent from '../navigation/footer.component';
import QRCode from 'qrcode.react';

const { Content } = Layout;

interface State {
  input: string;
}

class QRCodesComponent extends Component<{}, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      input: ''
    };
  }

  onInputChange = (e: any) => {
    let value = e.target.value.toLowerCase();
    if (value.charAt(0) !== '/') {
      value = '/' + value;
    }
    this.setState({ input: value });
  };

  render() {
    const host = process.env.REACT_APP_WEB_URL;

    return (
      <Layout className={'app-container'}>
        <NavbarComponent />
        <Layout className='site-layout'>
          <SiderComponent />
          <Content>
            <div className={'app-container-body'}>
              <div className={'app-container-content'}>
                <div className={'app-container-content-title'}>Manage QR Codes</div>

                <Input
                  value={this.state.input}
                  addonBefore={process.env.REACT_APP_WEB_URL}
                  onChange={this.onInputChange}
                />
                <QRCode
                  style={{ marginTop: 50 }}
                  size={window.outerWidth > 600 ? 256 : 128}
                  value={host + this.state.input}
                />
              </div>
            </div>
          </Content>
        </Layout>
        <FooterComponent />
      </Layout>
    );
  }
}

export default QRCodesComponent;
