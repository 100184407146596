import { Button, Col, Form, Input, Row } from 'antd';
import React from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { formItemLayout } from '../../components/forms/form-layouts.component';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface Props {
  field: any;
  isSaving: boolean;
}

export const CollapsedListItemsModule = (props: Props) => {
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 22, offset: 0 },
      sm: { span: 16, offset: 8 }
    }
  };

  const subFormItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 19 }
    }
  };

  return (
    <>
      <Form.Item
        label='Title'
        name={[props.field.name, 'settings', 'title']}
        rules={[{ required: false }]}
      >
        <Input placeholder='Title of this block' />
      </Form.Item>
      <Form.Item
        label='Sub-title'
        name={[props.field.name, 'settings', 'subtitle']}
        rules={[{ required: false }]}
      >
        <Input placeholder='Sub-Title of this block' />
      </Form.Item>
      <Form.List name={[props.field.name, 'settings', 'items']}>
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map((field, index) => (
                <Form.Item
                  {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                  label={index === 0 ? 'Items' : ''}
                  required={false}
                  key={field.key}
                >
                  <Row className={'form-sub-section'}>
                    <Col xs={22}>
                      <Row>
                        <Col xs={24}>
                          <Form.Item
                            {...subFormItemLayout}
                            name={[field.name, 'title']}
                            label='Title'
                            rules={[{ required: true, message: 'Enter a title for this item' }]}
                          >
                            <Input placeholder='Title of this item' />
                          </Form.Item>
                        </Col>
                        <Col xs={24}>
                          <Form.Item
                            {...subFormItemLayout}
                            name={[field.name, 'content']}
                            label='Content'
                            rules={[{ required: true, message: 'Enter the content for this item' }]}
                          >
                            <ReactQuill theme='snow' />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={2}>
                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          className='dynamic-delete-button'
                          style={{ margin: '0 8px' }}
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      ) : null}
                    </Col>
                  </Row>
                </Form.Item>
              ))}
              <Row>
                <Col xs={{ span: 16, offset: 8 }}>
                  <Form.Item>
                    <Button
                      type='primary'
                      onClick={() => {
                        add();
                      }}
                      style={{ width: '60%' }}
                    >
                      <PlusOutlined /> Add List Item
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          );
        }}
      </Form.List>
    </>
  );
};
