import { ApiError, AUTH_ERROR, AUTH_LOGIN, AUTH_LOGOUT, AUTH_REQUEST } from '../types';
import { LoopBack } from '../api';
import { handleError } from '../helpers';
import Swal from 'sweetalert2';

export const userLoginFetch = (user: any) => {
  return (dispatch: any) => {
    dispatch(authRequest());
    new LoopBack()
      .post('/admins/login', user)
      .then((data: any) => {
        const error: ApiError = data.error;
        if (error) {
          handleError(error);
          dispatch(authError());
        } else {
          dispatch(loginUser(data));
        }
      })
      .catch((err) => {
        dispatch(authError());
        if (err.status === 401) {
          Swal.fire({
            title: 'Oops...',
            html: 'The email and password combination appears to be invalid',
            icon: 'error'
          });
        } else {
          Swal.fire({
            title: 'Oops...',
            html: 'Something went wrong while logging in',
            icon: 'error'
          });
        }
      });
  };
};

const authRequest = () => ({
  type: AUTH_REQUEST
});

export const authError = () => ({
  type: AUTH_ERROR
});

const loginUser = (userObj: any) => ({
  type: AUTH_LOGIN,
  payload: userObj
});

export const logoutUser = () => ({
  type: AUTH_LOGOUT
});
