import React, { useState } from 'react';
import TableComponent from './table.component';
import BlogForm from '../../forms/blog.form';
import { settings } from '../../../settings';

const BlogsComponent = () => {
  const [language, setLanguage] = useState(settings.languages[0].code.toLowerCase());

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      width: 150,
      sorter: true,
      render: (x: any) => x[language]
    },
    {
      title: 'Url',
      dataIndex: 'slug',
      responsive: ['xl'],
      width: 150,
      render: (x: string) => (
        <a
          rel={'noopener noreferrer'}
          target={'_blank'}
          href={process.env.REACT_APP_WEB_URL + '/blog' + x}
        >
          {x}
        </a>
      ),
      sorter: true
    },
    {
      title: 'Summary',
      dataIndex: 'summary',
      responsive: ['lg'],
      render: (x: any) => <div dangerouslySetInnerHTML={{ __html: x[language] }} />
    }
  ];

  return (
    <TableComponent
      form={BlogForm}
      url={'/blogs'}
      filter={{}}
      name={'Article'}
      title={'Blog Articles'}
      columns={columns}
      searchFields={['title']}
      actions={['view', 'add', 'edit', 'delete', 'languages']}
      onLanguageChange={(lang: string) => setLanguage(lang)}
    />
  );
};

export default BlogsComponent;
