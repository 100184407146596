import { Form, Input, Select } from 'antd';
import React from 'react';

const { Option } = Select;

export const GenderModule = (field: { name: string; formRef: any }) => {
  return (
    <>
      <Form.Item
        label='Title'
        name={[field.name, 'settings', 'title']}
        rules={[{ required: false }]}
      >
        <Input placeholder='Title of this block' />
      </Form.Item>
      <Form.Item
        label='Sub-title'
        name={[field.name, 'settings', 'subtitle']}
        rules={[{ required: false }]}
      >
        <Input placeholder='Sub-Title of this block' />
      </Form.Item>
      <Form.Item
        label={'Genders'}
        name={[field.name, 'settings', 'genders']}
        rules={[{ required: true, message: 'Please select at least one gender', type: 'array' }]}
      >
        <Select mode={'multiple'} placeholder='Please select a section to add'>
          <Option value='men'>Men</Option>
          <Option value='women'>Women</Option>
          <Option value='children'>Children</Option>
          <Option value='baby'>Baby</Option>
        </Select>
      </Form.Item>
    </>
  );
};
