import * as React from 'react';
import { useEffect } from 'react';
import { Button, Col, Result, Row } from 'antd';
import Layout from 'antd/lib/layout';
import { HomeOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { AuthState } from '../../redux/states/user';

interface Props {
  auth: AuthState;
}

const NotFoundComponent = (props: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Layout.Content style={{ paddingTop: 0 }}>
        <div>
          <Row style={{ height: '100vh' }} justify={'space-around'} align={'middle'}>
            <Col xs={24} style={{ textAlign: 'center' }}>
              <Result
                status='404'
                title={'Oops...'}
                subTitle={'This page could not be found'}
                extra={
                  <a href={props.auth.isLoggedIn ? '/home' : '/'}>
                    <Button icon={<HomeOutlined />} type={'primary'} size={'large'}>
                      Go back home
                    </Button>
                  </a>
                }
              />
            </Col>
          </Row>
        </div>
      </Layout.Content>
    </Layout>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(NotFoundComponent);
