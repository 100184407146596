import {Button, Col, Form, Input, InputNumber, Row, Select, Switch} from 'antd';
import React, {Component, useState} from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { formItemLayout } from '../../components/forms/form-layouts.component';
import FormImageSelectorComponent from '../../components/forms/form-image-selector.component';
import { connect } from 'react-redux';
import { AuthState } from '../../redux/states/user';
import { LoopbackFile } from '../../types';
import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {linkType} from "../../helpers/quill";

Quill.register(linkType);

interface Props {
  auth: AuthState;
  field: any;
  formRef: any;
  language: string;
}

const SliderModule = (props: Props) => {
  const [fileList, setFileList] = useState<LoopbackFile[]>([]);

    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 22, offset: 0 },
        sm: { span: 16, offset: 8 }
      }
    };

    const subFormItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 }
      }
    };

    const quill: any = React.createRef<ReactQuill>();

    const createToolbar = (ind: number) => {
        return {
            toolbar: {
                container: [
                    [{ header: [1, 2, 3, 4, false] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                    [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                    ['link', 'image', 'video'],
                    ['clean']
                ],
            },
            clipboard: {
                matchVisual: false
            }
        };
    };

    return (
      <>
        <Form.Item
            label='Variable Slide Width'
            name={[props.field.name, 'settings', 'variable_width']}
            valuePropName='checked'
            rules={[{ required: false, type: 'boolean' }]}
        >
          <Switch />
        </Form.Item>
          <Form.Item
              label='Main Content'
              name={[props.field.name,'settings', 'content_left']}
              rules={[{ required: false }]}
          >
              <ReactQuill theme='snow' modules={createToolbar(0)} />
          </Form.Item>
          <Form.Item
              label='Main Content Position'
              name={[props.field.name, 'settings', 'content_main_position']}
              rules={[{ required: true }]}
          >
              <Select placeholder='Please select a type'>
                  <Select.Option value='top'>Above Slides</Select.Option>
                  <Select.Option value='left'>Left of Slides</Select.Option>
              </Select>
          </Form.Item>
          <Form.Item
              label='Content Below slides'
              name={[props.field.name,'settings', 'content_right']}
              rules={[{ required: false }]}
          >
              <ReactQuill theme='snow' />
          </Form.Item>
        <Form.Item
            label='Reverse columns'
            name={[props.field.name, 'settings', 'reverse_columns']}
            valuePropName='checked'
            rules={[{ required: false, type: 'boolean' }]}
        >
          <Switch />
        </Form.Item>
          <Form.Item
              label='Images per slide'
              name={[props.field.name, 'settings', 'images_per_slide']}
              rules={[{ required: true }]}
          >
              <InputNumber />
          </Form.Item>
          <Form.Item
              label='Button text'
              name={[props.field.name, 'settings', 'button', 'text', props.language]}
              rules={[{ required: false }]}
          >
              <Input placeholder='Button text' />
          </Form.Item>
          <Form.Item
              label='Button url'
              name={[props.field.name, 'settings', 'button', 'url']}
              rules={[{ required: false }]}
          >
              <Input addonBefore={process.env.REACT_APP_WEB_URL} />
          </Form.Item>
          <Form.Item
              label='Zoom slide on click'
              name={[props.field.name, 'settings', 'zoom_on_click']}
              valuePropName='checked'
              rules={[{ required: false, type: 'boolean' }]}
          >
              <Switch />
          </Form.Item>
        <Form.List name={[props.field.name, 'settings', 'images']}>
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                    label={index === 0 ? 'Slides' : ''}
                    required={false}
                    key={field.key}
                  >
                    <Row className={'form-sub-section'}>
                      <Col xs={22}>
                        <Row>
                          <Col xs={24}>
                            <Form.Item
                              {...subFormItemLayout}
                              label={'Image'}
                              name={[field.name, 'image', 'url']}
                              validateTrigger={['onChange', 'onBlur']}
                              rules={[{ required: true, message: 'Please select an image' }]}
                            >
                              <FormImageSelectorComponent
                                showAddon={true}
                                preloadedFileList={fileList}
                                callback={(fileList: LoopbackFile[]) => setFileList(fileList)}
                                formRef={props.formRef}
                                rootKey={'sections'}
                                subKeys={[
                                  props.field.name,
                                  'settings',
                                  'images',
                                  field.name,
                                  'image'
                                ]}
                                formKey={'url'}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24}>
                            <Form.Item
                              {...subFormItemLayout}
                              label={'Title'}
                              name={[field.name, 'title', props.language]}
                              rules={[{ required: false }]}
                            >
                              <Input placeholder='enter a title' />
                            </Form.Item>
                          </Col>
                          <Col xs={24}>
                            <Form.Item
                              {...subFormItemLayout}
                              label={'Subtitle'}
                              name={[field.name, 'subtitle', props.language]}
                              rules={[{ required: false}]}
                            >
                              <Input placeholder='enter a subtitle...' />
                            </Form.Item>
                          </Col>

                          <Col xs={24}>
                            <Form.Item
                              {...subFormItemLayout}
                              label={'Button text'}
                              name={[field.name, 'button', 'text', props.language]}
                              rules={[
                                { required: false, }
                              ]}
                            >
                              <Input placeholder='enter a subtitle...' />
                            </Form.Item>
                          </Col>

                          <Col xs={24}>
                            <Form.Item
                              {...subFormItemLayout}
                              label={'Button url'}
                              name={[field.name, 'button', 'url']}
                              rules={[{ required: false }]}
                            >
                              <Input
                                addonBefore={process.env.REACT_APP_WEB_URL}
                                placeholder='enter a url'
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={2}>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className='dynamic-delete-button'
                            style={{ margin: '0 8px' }}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        ) : null}
                      </Col>
                    </Row>
                  </Form.Item>
                ))}

                <Row>
                  {fields.length === 0 && (
                    <Col className={'placeholder-label'} xs={24} sm={8}>
                      <span>Slides:</span>
                    </Col>
                  )}

                  <Col xs={24} sm={{ span: 16, offset: fields.length === 0 ? 0 : 8 }}>
                    <Form.Item>
                      <Button
                        type='primary'
                        onClick={() => {
                          add();
                        }}
                        style={{ width: '60%' }}
                      >
                        <PlusOutlined /> Add Slide
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            );
          }}
        </Form.List>
      </>
    );
  
}

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(SliderModule);
