import { APP_SET_SIDEBAR, APP_TOGGLE_SIDEBAR } from '../types';
import { AppState } from '../states/app';

export default function app(state = AppState, action: any) {
  switch (action.type) {
    case APP_SET_SIDEBAR:
      return {
        ...state,
        previousCollapseSide: state.collapseSide,
        collapseSide: action.payload
      };
    case APP_TOGGLE_SIDEBAR:
      return {
        ...state,
        previousCollapseSide: state.collapseSide,
        collapseSide: !state.collapseSide
      };
    default:
      return state;
  }
}
