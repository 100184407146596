import React, { useEffect, useState } from 'react';
import { Form, Input, Select } from 'antd';
import FormHeaderComponent from '../components/forms/form-header.component';
import { formItemLayout } from '../components/forms/form-layouts.component';
import { Color, Country, Course, Event } from '../types';
import { AuthState } from '../redux/states/user';
import { connect } from 'react-redux';
import { setFormData } from '../helpers/form.helper';
import { FormInstance } from 'antd/es/form';
import { Quill } from 'react-quill';
import { LoopBack } from '../redux/api';
import { settings } from '../../settings';
import 'react-quill/dist/quill.snow.css';
import { linkType } from '../helpers/quill';

Quill.register(linkType);

interface Props {
  auth: AuthState;
  type: string;
  visible: boolean;
  isSaving: boolean;
  object?: Color | null;
  formRef: React.RefObject<FormInstance>;
}

const NewsletterForm = (props: Props) => {
  const [countries, setCountries] = useState<Country[]>([]);
  const [events, setEvents] = useState<Event[]>([]);
  const [type, setType] = useState('');
  const [courses, setCourses] = useState<Course[]>([]);

  useEffect(() => {
    setFormData(props, props.object, props.formRef);

    new LoopBack(props.auth.user).get(`/countries`).then((data) => {
      setCountries(data);
    });

    new LoopBack(props.auth.user).get(`/events`).then((data) => {
      setEvents(data);
    });

    new LoopBack(props.auth.user).get(`/courses`).then((data) => {
      setCourses(data);
    });
  }, []);

  return (
    <>
      <FormHeaderComponent type={props.type} newTitle={'Send out newsletter'} editTitle={''} />

      <Form {...formItemLayout} ref={props.formRef} name='basic'>
        <Form.Item
          label='Send newsletter to:'
          name={'type'}
          rules={[{ required: true, message: 'Please enter a name' }]}
        >
          <Select placeholder='Please select a type' onChange={(e) => e && setType(e.toString())}>
            <Select.Option value='all'>Everyone</Select.Option>
            <Select.Option value='with_tag'>People with Specific Tag</Select.Option>
            <Select.Option value='course_participants'>People on waitinglist for a specific course</Select.Option>
          </Select>
        </Form.Item>

        {type === 'with_tag' && (
          <Form.Item
            label='Tag(s)'
            name={'tags'}
            rules={[{ required: true, message: 'Please select tags' }]}
          >
            <Select mode={'multiple'} placeholder='- Select Tags - '>
              <Select.Option key={'test'} value={'TEST'}>
                TEST
              </Select.Option>
              {settings.eventEnquiries && (
                <Select.Option key={'private_event_audience'} value={'private_event_audience'}>
                  Private Event Audience
                </Select.Option>
              )}
              {events.map((event, index) => {
                const name = event.name[settings.languages[0].code];
                return (
                  <Select.Option key={index} value={name}>
                    {name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        )}

        {type === 'course_participants' && (
          <Form.Item
            label='Course'
            name={'course'}
            rules={[{ required: true, message: 'Please select course' }]}
          >
            <Select placeholder='- Select Course - '>
              <Select.Option key={'test'} value={'TEST'}>
                TEST
              </Select.Option>
              {courses.map((course, index) => {
                const name = course.name[settings.languages[0].code];
                return (
                  <Select.Option key={index} value={course.id}>
                    {name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        )}

        {type === 'course_participants' && (
          <Form.Item
            label='Send to'
            name={'send_to'}
            rules={[{ required: true, message: 'Please select an option' }]}
          >
            <Select placeholder='- Select Group - '>
              <Select.Option key={'waiting_list'} value={'waiting_list'}>
                Only currently on the waiting list
              </Select.Option>
              <Select.Option key={'course_buyers'} value={'course_buyers'}>
                Only who bought the course
              </Select.Option>
              <Select.Option key={'all'} value={'all'}>
                Everyone (current waiting list + those who bought)
              </Select.Option>
            </Select>
          </Form.Item>
        )}

        {type !== 'course_participants' && (
        <Form.Item
          label='Restrict to audience of a particular country?'
          name='country'
          rules={[{ required: false }]}
        >
          <Select
            placeholder={'Select a country'}
            showSearch={true}
            optionFilterProp='children'
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {countries.map((country) => {
              return (
                <Select.Option key={country.id} value={country.name}>
                  {country.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        )}

        <Form.Item
          label='Email Subject'
          name={'subject'}
          rules={[{ required: true, message: 'Please enter a subject' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Email preview'
          extra={'This is the text underneath the email subject that someone gets to see when opening his inbox.'}
          name={'preview'}
          rules={[{ required: true, message: 'Please enter a preview text' }]}
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(NewsletterForm);
