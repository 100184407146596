import React, { useEffect, useRef, useState } from 'react';
import { Alert, Col, Modal, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { LoopBack } from '../../redux/api';
import Swal from 'sweetalert2';
import { AuthState } from '../../redux/states/user';
import { connect } from 'react-redux';
import { settings } from '../../../settings';

interface Props {
  auth: AuthState;
  visible: boolean;
  hide: (refreshData?: boolean) => void;
  type: string;
  data?: any;
  form: any;
  name: string;
  url: string;
  onSuccess?: () => void;
  description?: string;
  postUrl?: string;
}

const FormModal = (props: Props) => {
  const formRef = useRef<FormInstance>();
  const [isSaving, setIsSaving] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [error, setError] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    // small hack to clear any custom errors like slug
    setVisible(props.visible);
  }, [props.visible]);

  const handleOk = async () => {
    if (formRef && formRef.current) {
      let vals: any = formRef.current.getFieldsValue();
      formRef.current
        .validateFields()
        .then((res) => {
          setError(false);
          setIsSaving(true);

          if (props.type === 'new') {
            handleNew(vals);
          } else if (props.type === 'edit' && props.data) {
            vals.id = props.data.id;
            handleEdit(vals);
          }
        })
        .catch((res) => {
          setIsSaving(false);
          setError(true);
        });
    } else {
      Swal.fire({ title: 'Oops...', html: `Unable to save ${props.name}`, icon: 'error' });
      setIsSaving(false);
    }
  };

  const handleNew = (vals: any[]) => {
    const loopBack = new LoopBack(props.auth.user);
    const url = props.postUrl ? props.postUrl : props.url;
    loopBack
      .post(url, vals)
      .then((res) => {
        setIsSaving(false);
        Swal.fire({
          title: 'Saved',
          html: props.description ? props.description : `${props.name} has been successfully added`,
          icon: 'success'
        });
        setError(false);
        props.hide();
        props.onSuccess && props.onSuccess();
      })
      .catch((error) => {
        setIsSaving(false);
        Swal.fire({ title: 'Oops...', html: 'Something went wrong', icon: 'error' });
      });
  };

  const handleEdit = (vals: any[]) => {
    const loopBack = new LoopBack(props.auth.user);
    const url = props.postUrl ? props.postUrl : props.url;
    console.log(vals);
    loopBack
      .patch(`${url}/${props.data.id}`, vals)
      .then((res) => {
        Swal.fire({
          title: 'Saved',
          html: props.description ? props.description : `${props.name} has been successfully saved`,
          icon: 'success'
        });
        setError(false);
        setIsSaving(false);
        props.hide();
        props.onSuccess && props.onSuccess();
      })
      .catch((error) => {
        Swal.fire({ title: 'Oops...', html: `Something went wrong`, icon: 'error' });
        setIsSaving(false);
      });
  };

  const handleCancel = () => {
    if (formRef && formRef.current) {
      formRef.current.resetFields();
      setError(false);
    }
    props.hide(false);
  };

  const Form = props.form;

  return (
    <Modal
      okText={isSaving ? 'Saving...' : 'Save'}
      width={window.outerWidth < 900 ? '90%' : '60%'}
      title={`${props.type} ${props.name}`}
      visible={props.visible}
      onOk={handleOk}
      okButtonProps={{ loading: isSaving }}
      confirmLoading={isSaving}
      onCancel={handleCancel}
      cancelButtonProps={{ className: 'ant-btn-cancel' }}
    >
      {visible && (
        <Form
          type={props.type}
          object={props.data}
          isSaving={isSaving}
          visible={props.visible}
          formRef={formRef}
          dataIsSet={initialized}
        />
      )}

      {error && (
        <Row>
          <Col xs={24} sm={{ span: 16, offset: 8 }}>
            <Alert
              message='Oops...'
              description={`Not all required fields have been filled. Please fill all fields${
                settings.languages.length > 1 ? ' (for all languages) ' : ''
              } and try again`}
              type='warning'
            />
          </Col>
        </Row>
      )}
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(FormModal);
