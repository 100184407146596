import React, { useEffect } from 'react';
import TableComponent from './table.component';
import { Admin } from '../../types';
import AdminForm from '../../forms/admin.form';
import {
  AppstoreOutlined,
  BarcodeOutlined,
  BgColorsOutlined,
  BookOutlined,
  CalendarOutlined,
  DollarOutlined,
  EditOutlined,
  FileOutlined,
  FlagOutlined,
  FontSizeOutlined,
  GiftOutlined,
  GlobalOutlined,
  InfoCircleOutlined,
  MedicineBoxOutlined,
  PieChartOutlined,
  PoundOutlined,
  QrcodeOutlined,
  QuestionCircleOutlined,
  RadiusSettingOutlined,
  SafetyOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  SkinOutlined,
  StarOutlined,
  TagsOutlined,
  UserOutlined,
  YoutubeOutlined
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import StatusIcon from '../app/status-icon';
import { settings } from '../../../settings';
import { AuthState } from '../../redux/states/user';
import { AppState } from '../../redux/states/app';
import { setSidebar } from '../../redux/actions/app';
import { connect } from 'react-redux';

interface Props {
  auth: AuthState;
  app: AppState;
  setSidebar: typeof setSidebar;
}

const AdminsComponent = (props: Props) => {
  useEffect(() => {
    props.setSidebar(true);
    return () => {
      props.setSidebar(props.app.collapseSide);
    };
  }, []);

  let columns = [
    { title: 'First Name', dataIndex: 'first_name', sorter: true },
    { title: 'Email', dataIndex: 'email', sorter: true, responsive: ['md'] },
    {
      title: (
        <Tooltip title={'Dashboard Analytics'}>
          <PieChartOutlined />
        </Tooltip>
      ),
      className: 'table-cell-centered-icon',
      responsive: ['xxl'],
      render: (x: Admin) => new StatusIcon().render(x.roles.includes('dashboard_analytics'))
    },
    {
      title: (
        <Tooltip title={'Admins'}>
          <SafetyOutlined />
        </Tooltip>
      ),
      className: 'table-cell-centered-icon',
      responsive: ['xxl'],
      render: (x: Admin) => new StatusIcon().render(x.roles.includes('admins'))
    }
  ];
  if (settings.products.enable) {
    columns = columns.concat([
      {
        title: (
          <Tooltip title={'Products'}>
            <TagsOutlined />
          </Tooltip>
        ),
        className: 'table-cell-centered-icon',
        responsive: ['xxl'],
        render: (x: Admin) => new StatusIcon().render(x.roles.includes('products'))
      },
      {
        title: (
          <Tooltip title={'Product Types'}>
            <SkinOutlined />
          </Tooltip>
        ),
        className: 'table-cell-centered-icon',
        responsive: ['xxl'],
        render: (x: Admin) => new StatusIcon().render(x.roles.includes('product_types'))
      },
      {
        title: (
          <Tooltip title={'Product Categories'}>
            <AppstoreOutlined />
          </Tooltip>
        ),
        className: 'table-cell-centered-icon',
        responsive: ['xxl'],
        render: (x: Admin) => new StatusIcon().render(x.roles.includes('product_categories'))
      },
      {
        title: (
          <Tooltip title={'Product Sizes'}>
            <FontSizeOutlined />
          </Tooltip>
        ),
        className: 'table-cell-centered-icon',
        responsive: ['xxl'],
        render: (x: Admin) => new StatusIcon().render(x.roles.includes('sizes'))
      }
    ]);
    if (settings.productThemes.enable) {
      columns.push({
        title: (
          <Tooltip title={'Product Themes'}>
            <AppstoreOutlined />
          </Tooltip>
        ),
        className: 'table-cell-centered-icon',
        responsive: ['xxl'],
        render: (x: Admin) => new StatusIcon().render(x.roles.includes('product_themes'))
      });
    }
  }

  if (settings.products.styles) {
    columns.push({
      title: (
        <Tooltip title={'Styles'}>
          <AppstoreOutlined />
        </Tooltip>
      ),
      className: 'table-cell-centered-icon',
      responsive: ['xxl'],
      render: (x: Admin) => new StatusIcon().render(x.roles.includes('product_styles'))
    });
  }

  if (settings.products.occasions) {
    columns.push({
      title: (
        <Tooltip title={'Occasions'}>
          <AppstoreOutlined />
        </Tooltip>
      ),
      className: 'table-cell-centered-icon',
      responsive: ['xxl'],
      render: (x: Admin) => new StatusIcon().render(x.roles.includes('product_occasions'))
    });
  }

  if (settings.products.colors) {
    columns.push({
      title: (
        <Tooltip title={'Colors'}>
          <BgColorsOutlined />
        </Tooltip>
      ),
      className: 'table-cell-centered-icon',
      responsive: ['xxl'],
      render: (x: Admin) => new StatusIcon().render(x.roles.includes('colors'))
    });
  }

  if (settings.lookbook.enable) {
    columns.push({
      title: (
        <Tooltip title={'Lookbooks'}>
          <BookOutlined />
        </Tooltip>
      ),
      className: 'table-cell-centered-icon',
      responsive: ['xxl'],
      render: (x: Admin) => new StatusIcon().render(x.roles.includes('lookbooks'))
    });
  }

  if (settings.ingredients.enable) {
    columns = columns.concat([
      {
        title: (
          <Tooltip title={'Medicines'}>
            <MedicineBoxOutlined />
          </Tooltip>
        ),
        className: 'table-cell-centered-icon',
        responsive: ['xxl'],
        render: (x: Admin) => new StatusIcon().render(x.roles.includes('medicines'))
      }
    ]);
    columns = columns.concat([
      {
        title: (
          <Tooltip title={'Ingredients'}>
            <StarOutlined />
          </Tooltip>
        ),
        className: 'table-cell-centered-icon',
        responsive: ['xxl'],
        render: (x: Admin) => new StatusIcon().render(x.roles.includes('ingredients'))
      }
    ]);
  }

  if (settings.events.enable) {
    columns = columns.concat([
      {
        title: (
          <Tooltip title={'Events'}>
            <CalendarOutlined />
          </Tooltip>
        ),
        className: 'table-cell-centered-icon',
        responsive: ['xxl'],
        render: (x: Admin) => new StatusIcon().render(x.roles.includes('events'))
      }
    ]);
  }

  if (settings.courses.enable) {
    columns = columns.concat([
      {
        title: (
          <Tooltip title={'Courses'}>
            <YoutubeOutlined />
          </Tooltip>
        ),
        className: 'table-cell-centered-icon',
        responsive: ['xxl'],
        render: (x: Admin) => new StatusIcon().render(x.roles.includes('courses'))
      }
    ]);
  }

  if (settings.eventEnquiries) {
    columns = columns.concat([
      {
        title: (
          <Tooltip title={'Event Enquiries'}>
            <InfoCircleOutlined />
          </Tooltip>
        ),
        className: 'table-cell-centered-icon',
        responsive: ['xxl'],
        render: (x: Admin) => new StatusIcon().render(x.roles.includes('event_enquiries'))
      }
    ]);
  }

  columns = columns.concat([
    {
      title: (
        <Tooltip title={'FAQs'}>
          <QuestionCircleOutlined />
        </Tooltip>
      ),
      className: 'table-cell-centered-icon',
      responsive: ['xxl'],
      render: (x: Admin) => new StatusIcon().render(x.roles.includes('faqs'))
    }
  ]);

  if (settings.blog.enable) {
    columns.push({
      title: (
        <Tooltip title={'Blog Articles'}>
          <EditOutlined />
        </Tooltip>
      ),
      className: 'table-cell-centered-icon',
      responsive: ['xxl'],
      render: (x: Admin) => new StatusIcon().render(x.roles.includes('blog_articles'))
    });
  }

  columns = columns.concat([
    {
      title: (
        <Tooltip title={'Customers'}>
          <UserOutlined />
        </Tooltip>
      ),
      className: 'table-cell-centered-icon',
      responsive: ['xxl'],
      render: (x: Admin) => new StatusIcon().render(x.roles.includes('customers'))
    },
    {
      title: (
        <Tooltip title={'Orders'}>
          <ShoppingCartOutlined />
        </Tooltip>
      ),
      className: 'table-cell-centered-icon',
      responsive: ['xxl'],
      render: (x: Admin) => new StatusIcon().render(x.roles.includes('orders'))
    },
    {
      title: (
        <Tooltip title={'Vouchers'}>
          <DollarOutlined />
        </Tooltip>
      ),
      className: 'table-cell-centered-icon',
      responsive: ['xxl'],
      render: (x: Admin) => new StatusIcon().render(x.roles.includes('vouchers'))
    },
    {
      title: (
        <Tooltip title={'Currencies'}>
          <PoundOutlined />
        </Tooltip>
      ),
      className: 'table-cell-centered-icon',
      responsive: ['xxl'],
      render: (x: Admin) => new StatusIcon().render(x.roles.includes('currencies'))
    }
  ]);

  if (settings.enableGiftCartForVouchers) {
    columns.push({
      title: (
        <Tooltip title={'Gift Cards'}>
          <GiftOutlined />
        </Tooltip>
      ),
      className: 'table-cell-centered-icon',
      responsive: ['xxl'],
      render: (x: Admin) => new StatusIcon().render(x.roles.includes('gift_cards'))
    });
  }

  if (settings.embroidery) {
    columns.push({
      title: (
        <Tooltip title={'Embroidery Positions'}>
          <RadiusSettingOutlined />
        </Tooltip>
      ),
      className: 'table-cell-centered-icon',
      responsive: ['xxl'],
      render: (x: Admin) => new StatusIcon().render(x.roles.includes('embroidery_positions'))
    });
    columns.push({
      title: (
        <Tooltip title={'Software'}>
          <RadiusSettingOutlined />
        </Tooltip>
      ),
      className: 'table-cell-centered-icon',
      responsive: ['xxl'],
      render: (x: Admin) => new StatusIcon().render(x.roles.includes('software'))
    });
  }
  columns.push({
    title: (
      <Tooltip title={'Pages'}>
        <FileOutlined />
      </Tooltip>
    ),
    className: 'table-cell-centered-icon',
    responsive: ['xxl'],
    render: (x: Admin) => new StatusIcon().render(x.roles.includes('pages'))
  });
  if (settings.qrCodes) {
    columns.push({
      title: (
        <Tooltip title={'QR Codes'}>
          <QrcodeOutlined />
        </Tooltip>
      ),
      className: 'table-cell-centered-icon',
      responsive: ['xxl'],
      render: (x: Admin) => new StatusIcon().render(x.roles.includes('qr_codes'))
    });
  }
  columns.push({
    title: (
      <Tooltip title={'Countries'}>
        <GlobalOutlined />
      </Tooltip>
    ),
    className: 'table-cell-centered-icon',
    responsive: ['xxl'],
    render: (x: Admin) => new StatusIcon().render(x.roles.includes('countries'))
  });
  columns.push({
    title: (
      <Tooltip title={'Translations'}>
        <FlagOutlined />
      </Tooltip>
    ),
    className: 'table-cell-centered-icon',
    responsive: ['xxl'],
    render: (x: Admin) => new StatusIcon().render(x.roles.includes('translations'))
  });
  if (settings.purchases) {
    columns.push({
      title: (
        <Tooltip title={'Purchases'}>
          <BarcodeOutlined />
        </Tooltip>
      ),
      className: 'table-cell-centered-icon',
      responsive: ['xxl'],
      render: (x: Admin) => new StatusIcon().render(x.roles.includes('purchases'))
    });
  }
  columns.push({
    title: (
      <Tooltip title={'Settings'}>
        <SettingOutlined />
      </Tooltip>
    ),
    className: 'table-cell-centered-icon',
    responsive: ['xxl'],
    render: (x: Admin) => new StatusIcon().render(x.roles.includes('settings'))
  });

  return (
    <div id={'admins-table'}>
      <TableComponent
        form={AdminForm}
        url={'/admins'}
        filter={{}}
        name={'Admin'}
        title={'Admins'}
        columns={columns}
        searchFields={['first_name', 'last_name', 'email']}
        actions={['view', 'add', 'edit', 'delete']}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  app: state.app
});

const mapDispatchToProps = (dispatch: any) => ({
  setSidebar: (e: boolean) => dispatch(setSidebar(e))
});

export default connect<any, any, any>(mapStateToProps, mapDispatchToProps)(AdminsComponent);
