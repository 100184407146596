import React, { Component } from 'react';
import { Button, Col, Form, Input, Row, Tabs } from 'antd';
import FormHeaderComponent from '../components/forms/form-header.component';
import { formItemLayout } from '../components/forms/form-layouts.component';
import { LoopbackFile, ProductTheme } from '../types';
import { AuthState } from '../redux/states/user';
import { connect } from 'react-redux';
import { setFormData, updateFormData } from '../helpers/form.helper';
import { FormInstance } from 'antd/es/form';
import { settings } from '../../settings';
import FormImageSelectorComponent from '../components/forms/form-image-selector.component';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { slugify, updateSlug } from '../helpers/slugify.helper';
import { linkType } from '../helpers/quill';

const { TabPane } = Tabs;
Quill.register(linkType);

const toolbar = {
  toolbar: [
    [{ header: [1, 2, 3, 4, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
    ['link'],
    ['clean']
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false
  }
};

interface Props {
  auth: AuthState;
  type: string;
  visible: boolean;
  isSaving: boolean;
  object?: ProductTheme | null;
  formRef: React.RefObject<FormInstance>;
}

interface State {
  confirmLoading: boolean;
  slugIsValidating: boolean;
  slugExists: boolean;
  slugError: boolean;
  themeTitle: string;
  fileList: LoopbackFile[]
}

class ProductThemeForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      confirmLoading: false,
      slugExists: false,
      slugIsValidating: false,
      slugError: false,
      themeTitle: '',
      fileList: []
    };
  }

  componentDidMount(): void {
    setFormData(this.props, this.props.object, this.props.formRef);
  }

  verifySlug = (e: any) => {
    if (!this.props.object) {
      let value = slugify(e.target.value.toLowerCase());
      if (value.charAt(0) !== '/') {
        value = '/' + value;
      }

      if (this.props.formRef && this.props.formRef.current) {
        this.props.formRef.current.setFieldsValue({ slug: value });
      }

      this.setState({ slugIsValidating: true, themeTitle: value });
      updateSlug(value, this.props.formRef, this.props.object)
        .then((res) => {
          this.setState({ slugExists: res.slugExists, slugIsValidating: false });
        })
        .catch((err) => {
          this.setState({ slugIsValidating: false, slugError: true });
        });
    }
  };

  componentWillUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any) {
    updateFormData(this, nextProps);
  }

  getForm = (lang: string) => {
    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 16, offset: 8 },
        sm: { span: 16, offset: 8 }
      }
    };

    return (
      <>
        <Form.Item
          label='Name'
          name={['name', lang]}
          rules={[{ required: true, message: 'Please enter a name' }]}
        >
          <Input onChange={lang === settings.languages[0].code ? this.verifySlug : undefined} />
        </Form.Item>
        <Form.Item
          label='Url'
          name='slug'
          hasFeedback={this.state.slugIsValidating}
          validateStatus={
            this.state.slugIsValidating
              ? 'validating'
              : this.state.slugExists || this.state.slugError
              ? 'error'
              : undefined
          }
          help={this.state.slugError ? 'Unable to check if this url is already in use' : undefined}
          rules={[{ required: true, message: 'Please enter a url' }]}
        >
          <Input
            addonBefore={process.env.REACT_APP_WEB_URL + settings.productThemes.rootUrl}
            onChange={lang === settings.languages[0].code ? this.verifySlug : undefined}
          />
        </Form.Item>
        <Form.Item
          extra={'This text will be shown in the Google Search results'}
          label='Summary'
          name={['summary', lang]}
          rules={[{ required: true }]}
        >
          <ReactQuill theme='snow' modules={toolbar} />
        </Form.Item>
        {settings.productThemes.enableImages && (
          <Form.Item label='Description' name={['description', lang]} rules={[{ required: false }]}>
            <ReactQuill theme='snow' modules={toolbar} />
          </Form.Item>
        )}
        {settings.productThemes.enableImages && (
          <Form.Item label='Main image' name='image' rules={[{ required: false }]}>
            <FormImageSelectorComponent
              showAddon={true}
              preloadedFileList={this.state.fileList}
              callback={(fileList: LoopbackFile[]) => this.setState({fileList})}
              formRef={this.props.formRef}
              rootKey={'image'}
              subKeys={[]}
              formKey={'image'}
            />
          </Form.Item>
        )}
        {settings.productThemes.enableImages && (
          <Form.List name='pictures'>
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <Form.Item
                      {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                      label={index === 0 ? 'Pictures' : ''}
                      required={false}
                      key={field.key}
                    >
                      <Row>
                        <Col xs={22}>
                          <Form.Item
                            {...field}
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: 'Please select an image'
                              }
                            ]}
                            noStyle
                          >
                            <FormImageSelectorComponent
                              showAddon={true}
                              preloadedFileList={this.state.fileList}
                              callback={(fileList: LoopbackFile[]) => this.setState({fileList})}
                              formRef={this.props.formRef}
                              rootKey={'pictures'}
                              subKeys={[]}
                              formKey={field.name.toString()}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={2}>
                          {fields.length > 1 ? (
                            <MinusCircleOutlined
                              className='dynamic-delete-button'
                              style={{ margin: '0 8px' }}
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                          ) : null}
                        </Col>
                      </Row>
                    </Form.Item>
                  ))}
                  <Row>
                    {fields.length === 0 && (
                      <Col className={'placeholder-label'} xs={24} sm={8}>
                        <span>Pictures:</span>
                      </Col>
                    )}

                    <Col xs={{ span: 16, offset: fields.length === 0 ? 0 : 8 }}>
                      <Form.Item>
                        <Button
                          type='primary'
                          onClick={() => {
                            add();
                          }}
                          style={{ width: '60%' }}
                        >
                          <PlusOutlined /> Add Picture
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              );
            }}
          </Form.List>
        )}
      </>
    );
  };

  render() {
    const productTheme = this.props.object;
    const languages = settings.languages;
    return (
      <>
        <FormHeaderComponent
          type={this.props.type}
          newTitle={productTheme ? '' : 'Add a new Product Theme'}
          editTitle={productTheme ? `Edit ${productTheme.name[languages[0].code]}` : ''}
        />

        <Form {...formItemLayout} ref={this.props.formRef} name='basic'>
          <Tabs defaultActiveKey={languages[0].code.toLowerCase()}>
            {languages.map((language, index) => {
              return (
                <TabPane forceRender={true} tab={language.name} key={language.code.toLowerCase()}>
                  {this.getForm(language.code.toLowerCase())}
                </TabPane>
              );
            })}
          </Tabs>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(ProductThemeForm);
