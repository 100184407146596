import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Switch } from 'antd';
import FormHeaderComponent from '../components/forms/form-header.component';
import { formItemLayout } from '../components/forms/form-layouts.component';
import { Country, Customer } from '../types';
import { AuthState } from '../redux/states/user';
import { connect } from 'react-redux';
import { setFormData } from '../helpers/form.helper';
import { FormInstance } from 'antd/es/form';
import { LoopBack } from '../redux/api';

interface Props {
  auth: AuthState;
  type: string;
  visible: boolean;
  isSaving: boolean;
  object?: Customer | null;
  formRef: React.RefObject<FormInstance>;
}

const CustomerForm = (props: Props) => {
  const [countries, setCountries] = useState<Country[]>([]);

  useEffect(() => {
    setFormData(props, props.object, props.formRef);

    new LoopBack(props.auth.user)
      .get(`/countries`)
      .then((res) => {
        setCountries(res);
      })
      .catch((err) => {});
  }, [props.object]);

  const customer = props.object;

  return (
    <>
      <FormHeaderComponent
        type={props.type}
        newTitle={customer ? '' : 'Add a new Customer'}
        editTitle={customer ? `Edit ${customer.first_name} ${customer.last_name}` : ''}
      />

      <Form {...formItemLayout} ref={props.formRef} name='basic'>
        <Form.Item
          label='First Name'
          name='first_name'
          rules={[{ required: true, message: 'Please enter the first name' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Last Name'
          name='last_name'
          rules={[{ required: true, message: 'Please enter the last name' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Email'
          name='email'
          rules={[{ required: true, message: 'Please enter an email' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Street name'
          name='street_name'
          rules={[{ required: true, message: 'Please enter the street name' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Door number'
          name='door_number'
          rules={[{ required: true, message: 'Please enter a door number' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Postal code'
          name='postal_code'
          rules={[{ required: true, message: 'Please enter a postal code' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='City'
          name='city'
          rules={[{ required: true, message: 'Please enter a city' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Country'
          name='country'
          rules={[{ required: true, message: 'Please select a country' }]}
        >
          <Select
            placeholder={'Select a country'}
            showSearch={true}
            optionFilterProp='children'
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {countries.map((country) => {
              return (
                <Select.Option key={country.id} value={country.name}>
                  {country.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label='Company' name='company' rules={[{ required: false }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label='Subscribe to newsletter'
          name={'subscribed'}
          valuePropName='checked'
          rules={[{ required: false, type: 'boolean' }]}
        >
          <Switch />
        </Form.Item>
      </Form>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(CustomerForm);
