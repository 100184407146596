import React, { Component } from 'react';
import TableComponent from './table.component';
import { CartItem, Customer, Order } from '../../types';
import moment from 'moment';
import OrderForm from '../../forms/order.form';
import { Form, Input, Modal } from 'antd';
import FormHeaderComponent from '../forms/form-header.component';
import { formItemLayout } from '../forms/form-layouts.component';
import Swal from 'sweetalert2';
import { FormInstance } from 'antd/es/form';
import { LoopBack } from '../../redux/api';
import { connect } from 'react-redux';
import { AuthState } from '../../redux/states/user';
import { ArrowRightOutlined } from '@ant-design/icons';
import { settings } from '../../../settings';

interface Props {
  auth: AuthState;
}

interface State {
  showModal: boolean;
  saving: boolean;
  order: Order | null;
  trackingNumber: string;
}

class OrdersComponent extends Component<Props, State> {
  formRef = React.createRef<FormInstance>();

  constructor(props: any) {
    super(props);

    this.state = {
      showModal: false,
      saving: false,
      order: null,
      trackingNumber: ''
    };
  }

  openModal = (e: any, x: Order) => {
    e.preventDefault();

    this.setState({ showModal: true, order: x, trackingNumber: x.tracking_number }, () => {
      if (this.formRef && this.formRef.current) {
        this.formRef.current.setFieldsValue({
          delivery_status: x.delivery_status,
          tracking_number: x.tracking_number
        });
      }
    });
  };

  handleOk = () => {
    this.setState({ saving: true });

    const order = this.state.order;
    if (this.formRef && this.formRef.current && order) {
      let vals: any = this.formRef.current.getFieldsValue();
      this.formRef.current
        .validateFields()
        .then((res) => {
          const loopBack = new LoopBack(this.props.auth.user);

          loopBack
            .patch(`/orders/${order.id}`, { tracking_number: vals.tracking_number })
            .then((res) => {
              Swal.fire({
                title: 'Saved',
                html: `Delivery details for order #${order.order_number} has been successfully saved. Refresh the page to see the updated status...`,
                icon: 'success'
              });
              // this.tableRef.current.fetch();
              this.setState({ saving: false, showModal: false, order: null });
            })
            .catch((error) => {
              Swal.fire({ title: 'Oops...', html: `Something went wrong`, icon: 'error' });
              this.setState({ saving: false });
            });
        })
        .catch((res) => {
          this.setState({ saving: false });
        });
    } else {
      Swal.fire({ title: 'Oops...', html: `Unable to save shipping details`, icon: 'error' });
      this.setState({ saving: false });
    }
  };

  updateTrackingNumber = (e: any) => {
    this.setState({ trackingNumber: e.target.value });
    if (this.formRef && this.formRef.current) {
      this.formRef.current.setFieldsValue({
        url_preview: e.target.value
      });
    }
  };

  getItems = (items: CartItem[]) => {
    return items.length > 1 ? (
      <ul>
        {items.map((item: CartItem, index: number) => {
          return item.product ? (
            <li key={index}>
              {item.product.name[settings.languages[0].code]} ({item.quantity}x)
            </li>
          ) : (
            <li key={index}>
              {item.event.name[settings.languages[0].code]} ({item.quantity}x)
            </li>
          );
        })}
      </ul>
    ) : items[0].product ? (
      <div>
        {items[0].product.name[settings.languages[0].code]} ({items[0].quantity}x)
      </div>
    ) : (
      <div>
        {items[0].event.name[settings.languages[0].code]} ({items[0].quantity}x)
      </div>
    );
  };

  getGiftCards = (items: CartItem[]) => {
    return items[0].giftCard.name[settings.languages[0].code];
  };

  render() {
    const columns: any = [
      {
        title: 'First name',
        dataIndex: 'customer',
        render: (x: Customer) => x.first_name + ' ' + x.last_name,
        sorter: true
      },
      {
        title: 'Email',
        dataIndex: 'customer',
        render: (x: Customer) => x.email,
        responsive: ['xxl'],
        sorter: true
      },
      {
        title: 'Products',
        dataIndex: '',
        render: (x: any) => {
          const items = x.products.length > 0 ? x.products : JSON.parse(x.events);
          if (items.length > 0) {
            return this.getItems(items);
          }
          if (x.gift_cards.length > 0) {
            return this.getGiftCards(x.gift_cards);
          }
        },
        responsive: ['lg']
      },
      {
        title: 'Order number',
        dataIndex: 'order_number',
        responsive: ['lg'],
        sorter: true
      },
      {
        title: 'Date',
        dataIndex: 'order_date',
        render: (x: string) => moment(x).format('dd DD MMM HH:mm'),
        responsive: ['lg'],
        sorter: true
      },
      {
        title: 'Payment status',
        dataIndex: 'payment_status',
        render: (status: string) => {
          const bgColor = status === 'paid' ? 'bg-success' : 'bg-alert';

          return {
            props: { className: bgColor },
            children: (
              <div
                style={{
                  backgroundColor: bgColor,
                  color: '#ffffff'
                }}
              >
                {status}
              </div>
            )
          };
        },
        responsive: ['xxl'],
        sorter: true
      }
    ];

    if (settings.products.enable) {
      columns.push({
        title: 'Delivery status',
        dataIndex: '',
        render: (x: Order) => {
          const bgColor = x.delivery_status === 'delivered' ? 'bg-success' : 'bg-warning';

          return {
            props: { className: bgColor },
            children: (
              <div
                style={{
                  backgroundColor: bgColor,
                  color: '#ffffff'
                }}
              >
                <a className='ant-dropdown-link' onClick={(e) => this.openModal(e, x)}>
                  {x.delivery_status === 'delivered' ? 'mail sent' : x.delivery_status}
                </a>
              </div>
            )
          };
        },
        sorter: true
      });
    }

    let filter = {};
    if (!settings.orders.showNonPaidOrders) {
      filter = { where: { payment_status: 'paid' }, order: ['created DESC'] };
    }

    return (
      <>
        <TableComponent
          form={OrderForm}
          url={'/orders'}
          filter={filter}
          name={'Order'}
          title={'Orders'}
          columns={columns}
          searchFields={['order_number', 'email']}
          actions={['view', 'add', 'edit', 'delete', 'pdf']}
        />
        <Modal
          okText={'Save'}
          width={window.outerWidth < 900 ? '90%' : '60%'}
          visible={this.state.showModal}
          title={'Edit Order'}
          onOk={this.handleOk}
          confirmLoading={false}
          onCancel={() => this.setState({ showModal: false })}
          cancelButtonProps={{ className: 'ant-btn-cancel' }}
        >
          {this.state.order && (
            <>
              <FormHeaderComponent
                type={'edit'}
                newTitle={'-'}
                editTitle={`Update tracking number`}
              />
              <Form
                {...formItemLayout}
                initialValues={{
                  tracking_number: this.state.order.tracking_number,
                  url_preview: this.state.order.tracking_number
                }}
                ref={this.formRef}
                name='basic'
              >
                <Form.Item
                  label={'Tracking number'}
                  name={'tracking_number'}
                  extra={
                    'Double check the tracking number. Each time you change the number, a new mail will be sent'
                  }
                  rules={[{ required: false }]}
                >
                  <Input
                    placeholder={'Enter a tracking number'}
                    onChange={(e) => this.updateTrackingNumber(e)}
                    onPaste={(e) => this.updateTrackingNumber(e)}
                  />
                </Form.Item>

                <Form.Item
                  label={'Link preview'}
                  name={'url_preview'}
                  extra={
                    <a
                      target={'_blank'}
                      rel={'noopener noreferrer'}
                      href={`https://www.royalmail.com/portal/rm/track?trackNumber=${this.state.trackingNumber}`}
                    >
                      Preview link <ArrowRightOutlined />
                    </a>
                  }
                  rules={[{ required: false }]}
                >
                  <Input
                    style={{ marginBottom: 10 }}
                    placeholder={'Enter a tracking number first...'}
                    addonBefore={'https://www.royalmail.com/portal/rm/track?trackNumber='}
                  />
                </Form.Item>
              </Form>
            </>
          )}
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(OrdersComponent);
